export const acceptNumber = (e) => {
  const { key, ctrlKey } = e;
  const onlyNumbers = /^[0-9]+$/;
  const arrowKeys = ["ArrowRight", "ArrowLeft"];

  if (
    !ctrlKey &&
    key !== "Enter" &&
    key !== "Backspace" &&
    !onlyNumbers.test(key) &&
    !arrowKeys.includes(key)
  ) {
    e.preventDefault();
  }
};

export const getFirstLetters = (name) => {
  const words = name.split(" ");
  const firstLetters = words.map((word) => word.charAt(0).toUpperCase());
  return firstLetters.join("");
};
