import React, { useState } from "react";

import { AdminMain, DashLayoutStyle } from "../styles/components";
import notification from "../assets/icon/notification.svg";
import profile from "../assets/icon/avatar.jpeg";
import { AdminSearch } from "../styles/components";
import { ReactComponent as ArrowDown } from "../assets/icon/angle-down.svg";
import SideNav from "../components/Sidenav";
import NotificationImg from "../assets/img/notificationImg.svg";
import checkMark from "../assets/icon/check-mark.svg";

const DashboardLayout = ({ children }) => {
  const [notificationModal, setNotificationModal] = useState(false);

  return (
    <DashLayoutStyle>
      <SideNav />
      <AdminMain>
        <AdminSearch>
          <div>
          </div>
          <div>
            <img src={profile} alt="display profile" />
          </div>
          {notificationModal && (
            <div className="notification">
              <div>
                <p>Notifications</p>
              </div>
              <div className="messages">
                <div>
                  <span>Mark all as read</span>
                  <span>2 Unread</span>
                </div>
                <div>
                  <div>
                    <div>
                      <img src={NotificationImg} alt="NotificationImg" />
                      <div>
                        <span>
                          <b>Anu Toluwani</b> is a new Admin
                        </span>

                        <span>Dec 25, 2023 at 11:00am</span>
                      </div>
                    </div>
                    <img className="check" src={checkMark} alt="checkMark" />
                  </div>
                  <div>
                    <div>
                      <img src={NotificationImg} alt="NotificationImg" />
                      <div>
                        <span>
                          <b>Anu Toluwani</b> is a new Admin
                        </span>{" "}
                        <span>Dec 25, 2023 at 11:00am</span>
                      </div>
                    </div>
                    <img className="check" src={checkMark} alt="checkMark" />
                  </div>
                  <div>
                    <div>
                      <img src={NotificationImg} alt="NotificationImg" />
                      <div>
                        <span>
                          <b>Anu Toluwani</b> is a new Admin
                        </span>{" "}
                        <span>Dec 25, 2023 at 11:00am</span>
                      </div>
                    </div>
                    <img className="check" src={checkMark} alt="checkMark" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </AdminSearch>
        {children}
      </AdminMain>
    </DashLayoutStyle>
  );
};

export default DashboardLayout;
