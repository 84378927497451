const theme = {
  color: {
    btnBg: "#191919",
    white: "#ffffff",
    darkBg: "#2C2C2C",
    pink: "#FFC2EF",
    primary: "#2C2C2C",
    secoundary: "#FFC2EF",
    button: "#191919",
  },
  media: {
    xxl: "1440px",
    xl: "1200px",
    lg: "1024px",
    md: "768px",
    sm: "600px",
  },
};

export default theme;
