import { createSlice } from '@reduxjs/toolkit'
import {
  roles,
  createRole,
  updateRoles,
  updateAdminEmail,
  updateAdminPassword
} from '../../api/roles'

const initialState = {
  loading: false,
  data: null,
  modalStatus: false,
  modalStatus1: false,
  error: null,
  success: false,
  searchedRoles: '',
  singleRole: null,
  passwordError: null,
  updateError: null,
  emailError: null,
  errorStatus: null
}

const rolesSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    editRole: (state, action) => {
      const payload = action.payload
      state.modalStatus = payload
    },
    editRole1: (state, action) => {
      const payload = action.payload
      state.modalStatus1 = payload
    },
    sortBySearch: (state, action) => {
      const payload = action.payload
      state.searchedRoles = payload
    },
    update: (state, action) => {
      const payload = action.payload
      const existingData = state.data
      const newData = [...existingData, payload]
      state.data = newData
    },
    getRole: (state, action) => {
      const payload = action.payload
      const filter = state.data.filter((rol) => rol.id === payload)
      state.singleRole = filter
    },
  },
  extraReducers: {
    [roles.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [roles.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    [roles.rejected]: (state, action) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [createRole.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [createRole.fulfilled]: (state, action) => {
      state.loading = false
      state.roles = action.payload
      state.errorStatus = 'null'
    },
    [createRole.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [updateRoles.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [updateRoles.fulfilled]: (state) => {
      state.loading = false
      state.updateError = 'updateRoles'
    },
    [updateRoles.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [updateAdminEmail.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [updateAdminEmail.fulfilled]: (state) => {
      state.loading = false
      state.emailError = 'updateAdminEmail'
    },
    [updateAdminEmail.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
      state.emailError = 'failedAdminEmail'
    },
    [updateAdminPassword.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [updateAdminPassword.fulfilled]: (state) => {
      state.loading = false
      state.passwordError = 'updateAdminPassword'
    },
    [updateAdminPassword.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
      state.passwordError = 'failedAdminPassword'
    },
  },
})
export default rolesSlice.reducer

export const { editRole, editRole1, update, sortBySearch, getRole } = rolesSlice.actions
