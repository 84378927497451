import React, { useEffect, useState } from 'react'
import {
  AddHairStyled,
  HairStyleForm,
  InputFlex,
  StyledRadioWrapper,
} from './Style'
import { Spin } from 'antd'
import { Button, InputStyle } from '../../styles/components'
import addCircle from '../../assets/icon/add-circle.svg'
import plusSqaure from '../../assets/icon/plus-square.svg'
import { ReactComponent as Mark } from '../../assets/icon/mark.svg'
import { ReactComponent as Two } from '../../assets/icon/two.svg'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import downIcon from '../../assets/icon/down.svg'
import deleteIcon from '../../assets/icon/trash-2.svg'
import { createHairstylePreference } from '../../api/hairstyle'

const HairPreference = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const {
    hairstyleCat,
    hairstyleName,
    hairstyles,
    error,
    loading,
  } = useSelector((state) => state.hairstyle)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [addPreference, setAddPreference] = useState(false)

  const [activeItemId, setActiveItemId] = useState(null)

  const [inputList, setInputList] = useState(false)

  // const [list, setList] = useState();

  const [preferences, setPreferences] = useState([
    {
      title: '',
      subTitle: '',
      options: [{ optionName: '', optionPrice: '', isDefault: false }],
      selectedDefaultIndex: -1,
      validations: { isRequired: true },
    },
  ])


  const handleDropAddedPreference = (id) => {
    setActiveItemId(id)
    setInputList((prevState) => !prevState)
  }
  const handleAddPreference = () => {
    const hasEmptyOptions = preferences.some(
      (preference) =>
        preference.options.some(
          (option) => option.optionName === '' || option.optionPrice === ''
        )
    );

    if (hasEmptyOptions) {
      toast.error('You cannot leave an empty option name and option price. please fill up or delete option')
      return;
    }

    setAddPreference(true)
    setPreferences((prevPreferences) => [
      ...prevPreferences,
      {
        title: '',
        subTitle: '',
        options: [{ optionName: '', optionPrice: '', isDefault: false }],
        validations: { isRequired: true },
      },
    ])
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    const hasEmptyOptions = preferences.some(
      (preference) =>
        preference.options.some(
          (option) => option.optionName === '' || option.optionPrice === ''
        )
    );

    if (hasEmptyOptions) {
      toast.error('You cannot leave an empty option name and option price. please fill up or delete option')
      return;
    }
    const id = hairstyles?.id
    dispatch(createHairstylePreference({ preferences, id }))
  }

  const handleDeletePreference = (preferenceIndex) => {
    setPreferences((prevPreferences) => {
      return prevPreferences.filter(
        (preference, index) => index !== preferenceIndex,
      )
    })
  }

  const handleDeletePreferenceOption = (preferenceIndex, optionIndex) => {
    setPreferences((prevPreferences) => {
      const updatedPreferences = prevPreferences.map(preference => ({
        ...preference,
        options: preference.options.map(option => ({ ...option }))
      }));
  
      updatedPreferences[preferenceIndex].options = updatedPreferences[preferenceIndex].options.filter((_, idx) => idx !== optionIndex);
  
      return updatedPreferences;
    });
  };
  

  const handleRadioChange = (preferenceIndex, optionIndex) => {
    setPreferences((prevPreferences) => {
      const updatedPreferences = [...prevPreferences]
      const optionPrice =
        updatedPreferences[preferenceIndex].options[optionIndex].optionPrice

      updatedPreferences[preferenceIndex].selectedDefaultIndex =
        optionPrice === 0 ? optionIndex : -1

      updatedPreferences[preferenceIndex].options = updatedPreferences[
        preferenceIndex
      ].options?.map((opt, idx) => ({
        ...opt,
        isDefault: idx === optionIndex && optionPrice === 0,
      }))

      return updatedPreferences
    })
  }

  const handleAddOption = (preferenceIndex) => {
    setPreferences((prevPreferences) => {
      return prevPreferences?.map((preference, index) => {
        if (index === preferenceIndex) {
          const newOption = {
            optionName: '',
            optionPrice: '',
            isDefault: false,
          }
          return {
            ...preference,
            options: [...preference.options, newOption],
          }
        }
        return preference
      })
    })
  }
  if (error === 'createHairstylePreference') return navigate('/hairstyle')
  return (
    <>
      <AddHairStyled>
        <div className="select">
          <div>
            <Mark />
            <p> Hairstyle Information</p>
          </div>
          <div>
            <Two /> <p>Hairstyle Preference</p>
          </div>
        </div>
        <div className="heading">
          <h3>Add Hairstyle Preferences</h3>
        </div>
        <HairStyleForm onSubmit={handleSubmit}>
          <InputFlex className={addPreference && 'add-preference'}>
            <InputStyle>
              <label htmlFor="">Hair Category</label>
              <input
                type="text"
                name="category"
                value={hairstyleCat}
                disabled={addPreference}
                placeholder="Wigs"
              />
            </InputStyle>
            <InputStyle>
              <label htmlFor="">HairStyle</label>
              <input
                type="text"
                name="hairstyle"
                value={hairstyleName}
                disabled={addPreference}
                placeholder="Hairstyle"
              />
            </InputStyle>
          </InputFlex>

          {addPreference &&
            preferences.slice(0, -1)?.map((preference, i) => (
              <div key={i} className="preference-list">
                <div>
                  <div onClick={() => handleDropAddedPreference(i)}>
                    <p>{preference.title}</p>
                    <div>
                      <img src={downIcon} alt="down Icon" />
                      <img
                        onClick={() => handleDeletePreference(i)}
                        src={deleteIcon}
                        alt="delete Icon"
                      />
                    </div>
                  </div>
                  {inputList && (
                    <>
                      {i === activeItemId && (
                        <>
                          <InputStyle>
                            <label htmlFor="">Preference Title </label>
                            <input
                              type="text"
                              name="preference"
                              value={preference.title}
                              placeholder="Title"
                            />
                          </InputStyle>

                          {preference.options?.map((option, optionIndex) => (
                            <div key={optionIndex}>
                              <InputFlex>
                                <InputStyle>
                                  <label htmlFor="">Name </label>
                                  <input
                                    type="text"
                                    name="name"
                                    value={option.optionName}
                                    placeholder="Option Name"
                                  />
                                </InputStyle>
                                <InputStyle>
                                  <label htmlFor="">Price </label>
                                  <input
                                    type="number"
                                    placeholder="Option Price"
                                    value={option.optionPrice}
                                  />
                                </InputStyle>
                              </InputFlex>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}

          {preferences.map((preference, preferenceIndex) => (
            <>
              {preferenceIndex === preferences.length - 1 && (
                <>
                  <InputStyle>
                    <label htmlFor="">Preference Title </label>
                    <input
                      type="text"
                      name="preference"
                      value={preference.title}
                      onChange={(e) =>
                        setPreferences((prevPreferences) => {
                          const updatedPreferences = [...prevPreferences]
                          updatedPreferences[preferenceIndex].title =
                            e.target.value
                          return updatedPreferences
                        })
                      }
                      placeholder="Hair Length"
                    />
                    <p>
                      Create different options and prices for the Preference
                      title <span>“HAIR LENGTH’’</span>
                    </p>
                  </InputStyle>
                  <InputStyle>
                    <label htmlFor="">Preference Title Description </label>
                    <input
                      type="text"
                      placeholder="SubTitle"
                      value={preference.subTitle}
                      onChange={(e) =>
                        setPreferences((prevPreferences) => {
                          const updatedPreferences = [...prevPreferences]
                          updatedPreferences[preferenceIndex].subTitle =
                            e.target.value
                          return updatedPreferences
                        })
                      }
                    />
                    {/* <p>
                      Create different options and prices for the Preference
                      title <span>“HAIR LENGTH’’</span>
                    </p> */}
                  </InputStyle>

                  {preference.options?.map((option, optionIndex) => {
                    return (
                      <div key={optionIndex}>
                        <InputFlex>
                          <InputStyle>
                            <label htmlFor="">Option Name </label>
                            <input
                              type="text"
                              name="name"
                              value={option.optionName}
                              placeholder="Option Name"
                              onChange={(e) =>
                                setPreferences((prevPreferences) => {
                                  const updatedPreferences = [
                                    ...prevPreferences,
                                  ]
                                  updatedPreferences[preferenceIndex].options[
                                    optionIndex
                                  ].optionName = e.target.value
                                  return updatedPreferences
                                })
                              }
                            />
                          </InputStyle>
                          <InputStyle>
                            <label htmlFor="">Option Price </label>
                            <input
                              type="number"
                              placeholder="Option Price"
                              value={option.optionPrice}
                              onChange={(e) => {
                                const newPrice = parseFloat(e.target.value);
                                setPreferences((prevPreferences) => {
                                  const updatedPreferences = prevPreferences.map((preference, idx) => {
                                    if (idx === preferenceIndex) {
                                      return {
                                        ...preference,
                                        options: preference.options.map((opt, optIdx) => {
                                          if (optIdx === optionIndex) {
                                            return { ...opt, optionPrice: newPrice };
                                          }
                                          return opt;
                                        })
                                      };
                                    }
                                    return preference;
                                  });
                                  return updatedPreferences;
                                });
                              }}
                              
                            />
                          </InputStyle>
                          <img
                            onClick={() =>
                              handleDeletePreferenceOption(preferenceIndex , optionIndex)
                            }
                            src={deleteIcon}
                            alt="delete Icon"
                          />
                        </InputFlex>
                        <StyledRadioWrapper>
                          <input
                            type="radio"
                            checked={
                              optionIndex === preference.selectedDefaultIndex
                            }
                            onChange={() =>
                              handleRadioChange(preferenceIndex, optionIndex)
                            }
                          />
                          <span>
                            Set as Default Option (works only with option price
                            of 0)
                          </span>
                        </StyledRadioWrapper>
                      </div>
                    )
                  })}

                  <div className="options-preference">
                    <div
                      className="option"
                      onClick={() => handleAddOption(preferenceIndex)}
                    >
                      <img src={addCircle} alt="add Circle" />
                      <p>Add New Option</p>
                    </div>
                  </div>
                </>
              )}
            </>
          ))}
          <div className="options-preference">
            <div className="preference">
              <div
                onClick={handleAddPreference}
                className={`${
                  preferences[0]?.title === '' ||
                  preferences[0]?.options[0]?.optionName === '' ||
                  preferences[0]?.options[0]?.optionPrice === ''
                    ? 'disabled preference'
                    : ''
                }`}
              >
                <img src={plusSqaure} alt="plusSqaure" />
                <p>Add More Preference</p>
              </div>
            </div>
          </div>
          <div className="btn">
            <Button
              padding="8px 40px"
              hover="#494949"
              rounded="5px"
              disabled={loading}
            >
              {loading ? <Spin /> : 'Submit'}
            </Button>
          </div>
        </HairStyleForm>
      </AddHairStyled>
    </>
  )
}

export default HairPreference
