import React, { useEffect, useState } from "react";
import HairList from "./components/HairListTable";
import HairListGrid from "./components/HairListGrid";
import { CustomModal, HairListModal, HairListStyle } from "./Style";
import { useNavigate } from "react-router-dom";
import closeIcon from "../../assets/icon/Close Square.svg";
import { Button, DeleteModal, SearchWrapper } from "../../styles/components";
import { publishHairstyle, deleteHairstyle } from "../../api/hairstyle";
import { useDispatch, useSelector } from "react-redux";
import checkMark from "../../assets/icon/check-mark.svg";
import EmptyCategory from "../category/component/emptyCat";
import { hairstyle } from "../../api/hairstyle";
import searchIcon from "../../assets/icon/search.svg";
import menuPink from "../../assets/icon/format-list-bulleted (3).svg";
import menuPink1 from "../../assets/icon/format-list-bulleted (1).svg";
import formatLists from "../../assets/icon/format-list-bulleted.svg";
import formatList from "../../assets/icon/format-list-bulleted (4).svg";
import { sortBySearch } from "../../redux/reducers/hairstyleReducer";
import { toast } from "react-hot-toast";
import { TablePagination } from "@mui/material";

const HairStyle = () => {
  const { publish, error, data, hairstyles, searchedHairstyle, hairstylePre } =
    useSelector((state) => state.hairstyle);

  const [isLoading, setIsLoading] = useState(true);
  const [searchCat, setSearchCat] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(Number(8));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      await dispatch(hairstyle());
      setIsLoading(false);
    }
    fetchData();
  }, [dispatch]);

  const handleOpen = () => {
    setIsModalOpen(true);
    // setIsDeleteOpen(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const filteredHairstyle = data?.filter((cat) => {
    return (
      cat?.name?.toLowerCase().indexOf(searchedHairstyle?.toLowerCase()) !== -1
    );
  });

  const latestData =
    filteredHairstyle &&
    filteredHairstyle
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) };
      })
      .sort((a, b) => b?.date - a?.date);

  const case1 =
    searchCat === ""
      ? latestData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : latestData;

  useEffect(() => {
    if (error === "deleteHairstyle") {
      dispatch(hairstyle());
      setTimeout(() => {
        toast.success("Successfully Deleted Hairstyle");
      }, 1000);
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (error === "null") {
      dispatch(hairstyle());
      setTimeout(() => {
        toast.success("Successfully Added Category");
      }, 1000);
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (publish === "publishHairstyle") {
      dispatch(hairstyle());
      setTimeout(() => {
        toast.success("Successfully Published Hairstyle");
      }, 1000);
    }
  }, [publish, dispatch]);

  const handleSearchTeam = (e) => {
    setSearchCat(e.target.value);
    dispatch(sortBySearch(e.target.value));
  };

  useEffect(() => {
    dispatch(sortBySearch(""));
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [gridView, setGridView] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const handleDeleteOpen = () => {
    setIsModalOpen(false);
    setIsDeleteOpen(true);
  };

  const remove = () => {
    dispatch(deleteHairstyle(hairstyles[0]?.id))
      .then((result) => {
        // Redirect upon successful completion
        setIsDeleteOpen(false);
      })
      .catch((error) => {
        // Handle errors if needed
        console.error("Error:", error);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDelete = () => {
    setIsDeleteOpen(false);
  };

  const handlePublishHair = () => {
    dispatch(publishHairstyle(hairstyles[0]?.id))
      .then((result) => {
        // Redirect upon successful completion
        setIsModalOpen(false);
      })
      .catch((error) => {
        // Handle errors if needed
        console.error("Error:", error);
      });
  };

  const handleEdit = () => {
    const id = hairstyles[0]?.id;

    navigate(`/hairstyle/edit/${id}`);
  };

  if (data?.length < 1) return <EmptyCategory hairstyle />;
  return (
    <HairListStyle>
      <div className="initialSort">
        <SearchWrapper>
          <div className="search-input">
            <img src={searchIcon} alt="search Icon" />
            <input
              type="text"
              value={searchCat}
              onChange={handleSearchTeam}
              placeholder="Search..."
            />
          </div>
          <div className="tableHeader">
            <div className="pagination">
              <span>Show</span>
              <TablePagination
                rowsPerPageOptions={[2, 4, 6, 8, 10, 12, 14, 16, 18]}
                component="div"
                rowsPerPage={rowsPerPage}
                labelRowsPerPage=""
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <p>entries</p>
            </div>
            <div className={`grid-view ${gridView && "active"}`}>
              {gridView ? (
                <img
                  src={formatList}
                  alt="menu icon"
                  onClick={() => setGridView(true)}
                  className="grid-img"
                />
              ) : (
                <img
                  src={menuPink1}
                  alt="menu icon"
                  onClick={() => setGridView(true)}
                  className="grid-img"
                />
              )}
            </div>
            <div className={`menu grid-view ${!gridView && "active"}`}>
              {gridView ? (
                <img
                  src={menuPink}
                  alt="menu icon"
                  onClick={() => setGridView(false)}
                  className="list-img"
                />
              ) : (
                <img
                  src={formatLists}
                  alt="menu icon"
                  onClick={() => setGridView(false)}
                  className="list-img"
                />
              )}
            </div>
          </div>
        </SearchWrapper>
      </div>
      {gridView ? (
        <HairListGrid
          isLoading={isLoading}
          rowsPerPage={rowsPerPage}
          page={page}
          case1={case1}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          latestData={latestData}
          filteredHairstyle={filteredHairstyle}
          handleOpen={handleOpen}
        />
      ) : (
        <HairList
          isLoading={isLoading}
          rowsPerPage={rowsPerPage}
          page={page}
          case1={case1}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          latestData={latestData}
          filteredHairstyle={filteredHairstyle}
          handleOpen={handleOpen}
        />
      )}

      <DeleteModal
        centered
        closable={false}
        visible={isDeleteOpen}
        onCancel={handleDelete}
        footer={null}
        width={496}
        sx={{ padding: 0 }}
      >
        <div className="text">
          <h3>Delete this hairstyle?</h3>
          <p>Are you sure you want to delete this hairstyle?</p>
        </div>
        <div className="line"></div>
        <div className="btn">
          <Button
            rounded="10px"
            bg="white"
            color="#000"
            padding="15px 24px"
            font="16px"
            onClick={handleDelete}
          >
            Cancel
          </Button>
          <Button
            rounded="10px"
            bg="#191919"
            color="#fff"
            padding="15px 24px"
            font="16px"
            weight="600"
            onClick={remove}
            hover={"#272727"}
          >
            Yes, Delete
          </Button>
        </div>

        <div className="cancel" onClick={handleDelete}>
          <img src={closeIcon} alt="closeIcon" />
        </div>
      </DeleteModal>

      <CustomModal
        centered
        closable={false}
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={1120}
      >
        {hairstyles && (
          <HairListModal>
            <div className="img">
              <img src={hairstyles[0]?.thumbnail?.url} alt="hairStyleImg" />
              {hairstyles[0]?.status === "draft" && (
                <div className="status">Draft</div>
              )}
              <div>
                <img src={hairstyles[0]?.thumbnail?.url} alt="hairStyleImg" />
                <img src={hairstyles[0]?.thumbnail?.url} alt="hairStyleImg" />
                <img src={hairstyles[0]?.thumbnail?.url} alt="hairStyleImg" />
              </div>
            </div>
            <div className="text">
              <div className="information">
                <h1>Information</h1>
                <ul>
                  <li>
                    <span>Price</span>
                    <span>£ {hairstyles[0]?.price}</span>
                  </li>
                  <li>
                    <span>Category</span>
                    <span>{hairstyles[0]?.category}</span>
                  </li>
                  <li>
                    <span>Hairstyle</span>
                    <span>{hairstyles[0]?.name}</span>
                  </li>
                  <li>
                    <span>Status</span>
                    <span>{hairstyles[0]?.status}</span>
                  </li>
                  <li>
                    <span>Duration</span>
                    <span>
                      {hairstyles[0]?.averageTime}
                      {hairstyles[0]?.averageTime > 1 ? "hours" : "hour"}
                    </span>
                  </li>
                </ul>
              </div>
              {/* <div>
              <h4>Description</h4>
              <p>
                Look beautiful with these ombre crotchet installed locs. This
                style is an easy-to-style look that also protects your hair.Loc
                extensions are made with 100% high quality kanekalon synthetic
                fiber hair, natural looking and lightweight - style should last
                6-8 weeks (depending upon lifestyle routines)
              </p>
            </div> */}
              <h1>Hairstyle Preference</h1>
              {hairstylePre?.map((preference) => {
                return (
                  <div className="hairstyle">
                    <div>
                      <div>
                        <h5>{preference?.title}</h5>
                        <span>{preference?.subTitle}</span>
                      </div>
                      {preference?.options?.map((option) => {
                        return (
                          <ul>
                            <li>
                              <span>{option?.optionName}</span>
                              <div>
                                <span>£ {option?.optionPrice}</span>
                                <img src={checkMark} alt="checkMark" />
                              </div>
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              <div className="btn">
                <div>
                  <Button
                    padding="8px 16px"
                    rounded="5px"
                    bg="white"
                    color="black"
                    weight="600"
                    border="1px solid #1D1F25"
                    onClick={handleDeleteOpen}
                  >
                    Delete
                  </Button>
                </div>
                <div>
                  <Button
                    padding="8px 16px"
                    rounded="5px"
                    weight="600"
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>
                </div>
                {hairstyles[0]?.status === "draft" && (
                  <div onClick={handlePublishHair}>
                    <Button padding="8px 16px" rounded="5px" weight="600">
                      Publish
                    </Button>
                  </div>
                )}
              </div>
              <div className="cancel" onClick={handleCancel}>
                <img src={closeIcon} alt="closeIcon" />
              </div>
            </div>
          </HairListModal>
        )}
      </CustomModal>
    </HairListStyle>
  );
};

export default HairStyle;
