import React, { useState, useEffect } from "react";
import dots from "../../../assets/icon/dots-horizontal (1).svg";
import html2pdf from "html2pdf.js";
import { toast } from "react-hot-toast";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import NiyoNotificationModal from "../../../components/modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import Actions from "../../../constants/actions";
import {
  bookings,
  getBookingStylist,
  updatePaymentStatus,
} from "../../../api/booking";
import { TableRow } from "@mui/material";
import { hairstyle } from "../../../api/hairstyle";
import closeIcon from "../../../assets/icon/Close Square.svg";
import EmptyCategory from "../../category/component/emptyCat";
import {
  BookingTableStyle,
  BookingsListStyle,
  ViewBookingModal,
  BookingModal,
} from "../Style";
import { Button, CustomModal } from "../../../styles/components";
import { getFirstLetters } from "../../../helpers/validate";

import { ReactComponent as PrintIcon } from "../../../assets/icon/print-icon.svg";
import {
  viewBooks,
  enableFilter,
  disableErrors,
} from "../../../redux/reducers/bookingReducer";
import { getHairstyle } from "../../../redux/reducers/hairstyleReducer";
import dayjs from "dayjs";

const columns = [
  "Date",
  "Booking ID",
  "Client Name",
  "Email Address",
  "Phone Number",
  "Amount",
  "Status",
  "Action",
];

const BookingsList = ({
  setPage,
  page,
  handleChangeRowsPerPage,
  rowsPerPage,
  searchBook,
}) => {
  const {
    searchedBooking,
    data,
    load,
    stylistBooking,
    modalStatus,
    paymentStatus,
    booking,
    filter,
    error,
    bookingTransaction,
  } = useSelector((state) => state.booking);

  const { hairstyles } = useSelector((state) => state.hairstyle);

  const dispatch = useDispatch();

  const [uniqueDat, setUniqueDat] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [checkStylist, setcheckStylist] = useState(null);
  const [allBookings, setAllBookings] = useState();

  console.log(bookingTransaction, "bookingTransaction")
  useEffect(() => {
    const userRole = localStorage.getItem("userRole")
      ? localStorage.getItem("userRole")
      : null;
    setcheckStylist(userRole);
    if (userRole === "hairstylist") {
      setUniqueDat(stylistBooking);
    } else {
      setUniqueDat(data);
    }
  }, [data, stylistBooking]);

  useEffect(() => {
    async function fetchData() {
      await dispatch(hairstyle());
      setIsLoading(false);
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    function fetchData() {
      dispatch(bookings());
      dispatch(getBookingStylist());
      setIsLoading(false);
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    function fetchData() {
      dispatch(enableFilter(""));
      dispatch(disableErrors());
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (error === "assignBooking") {
      setTimeout(() => {
        toast.success("Successfully Assigned Bookin");
      }, 1000);
    }
    if (error === "invalid booking status") {
      toast.error(`${error}`);
    }
    if (error === "booking was not confirmed") {
      toast.error(`${error}`);
    }
  }, [error]);

  useEffect(() => {
    if (filter === "totalBookings") {
      setAllBookings(uniqueDat?.completedBookings);
    }
    if (filter === "pendingBookings") {
      setAllBookings(uniqueDat?.pendingBookings);
    }
    if (filter === "otherBookings") {
      setAllBookings(uniqueDat?.otherBookings);
    }
    if (filter === "") {
      if (checkStylist !== "hairstylist") {
        setAllBookings(
          uniqueDat?.pendingBookings.concat(
            uniqueDat?.completedBookings,
            uniqueDat?.otherBookings
          ) || []
        );
      } else {
        setAllBookings(stylistBooking);
      }
    }
  }, [
    uniqueDat?.completedBookings,
    uniqueDat?.otherBookings,
    uniqueDat?.pendingBookings,
    filter,
    checkStylist,
    stylistBooking,
  ]);

  const filteredBooking = allBookings?.filter((cat) => {
    return (
      cat?.user?.firstName
        ?.toLowerCase()
        .indexOf(searchedBooking?.toLowerCase()) !== -1
    );
  });

  const latestData =
    filteredBooking &&
    filteredBooking
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) };
      })
      .sort((a, b) => b?.date - a?.date);

  const case1 =
    searchBook === ""
      ? latestData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : latestData;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleBook = (id) => {
    dispatch(getHairstyle(id));
  };

  const handleCancel = () => {
    dispatch(viewBooks(false));
  };

  useEffect(() => {
    if (paymentStatus) {
      toast.success("Successfully Confirmed Payment");
      dispatch(bookings());
      dispatch(viewBooks(false));
    }
  }, [dispatch, paymentStatus]);

  const generatePDF = () => {
    const element = document.getElementById("modal-content");

    html2pdf(element);
  };

  const formatTime = (timeString) => {
    if (booking) {
      const [hours, minutes] = timeString?.split(":")?.map(Number);
      let formattedTime;

      if (hours >= 12) {
        formattedTime = format(new Date(0, 0, 0, hours, minutes), "h:mm a");
      } else {
        formattedTime = format(new Date(0, 0, 0, hours, minutes), "h:mm a");
      }

      return formattedTime;
    }
    return "---";
  };

  const handlePayment = () => {
    const hairId = hairstyles[0]?.id;
    const bookingId = booking?.id;
    dispatch(updatePaymentStatus({ hairId, bookingId }));
  };
  if (allBookings?.length < 1) return <EmptyCategory booking />;
  return (
    <BookingsListStyle>
      <BookingTableStyle>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="center"
                  style={{
                    top: 57,
                    maxWidth: column.maxWidth,
                  }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {allBookings?.length === 0 && <div>There Is No booking</div>}

            {filteredBooking?.length === 0 ? (
              <div className="no-hairstyle">
                There are currently no booking available
              </div>
            ) : (
              case1?.map((row) => {
                return (
                  <>
                    {isLoading ? (
                      <TableRow hover>
                        <TableCell>
                          <div className="table-header-skeleton  skeleton"></div>
                        </TableCell>
                        <TableCell>
                          <div className="table-header-skeleton  skeleton"></div>
                        </TableCell>
                        <TableCell>
                          <div className="table-header-skeleton  skeleton"></div>
                        </TableCell>
                        <TableCell>
                          <div className="table-header-skeleton  skeleton"></div>
                        </TableCell>
                        <TableCell>
                          <div className="table-header-skeleton  skeleton"></div>
                        </TableCell>
                        <TableCell>
                          <div className="table-header-skeleton  skeleton"></div>
                        </TableCell>
                        <TableCell>
                          <div className="table-header-skeleton  skeleton"></div>
                        </TableCell>
                        <TableCell>
                          <div className="table-header-skeleton  skeleton"></div>
                        </TableCell>
                        <TableCell>
                          <div className="table-header-skeleton  skeleton"></div>
                        </TableCell>
                        <TableCell className="menu-dot">
                          <div className="table-header-skeleton  skeleton"></div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow hover>
                        <TableCell
                          style={{
                            maxWidth: "100px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            textWrap: "break",
                            wordBreak: "break",
                          }}
                        >
                          {dayjs(row?.createdAt).format("DD MMM YY")}
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: "100px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            textWrap: "break",
                            wordBreak: "break",
                          }}
                        >
                          {row?.id}
                        </TableCell>

                        <TableCell
                          style={{
                            maxWidth: "100px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            textWrap: "break",
                            wordBreak: "break",
                          }}
                        >
                          {row?.user?.firstName} {row?.user?.lastName}
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: "100px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            textWrap: "break",
                            wordBreak: "break",
                          }}
                        >
                          {row?.user?.email}
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: "100px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            textWrap: "break",
                            wordBreak: "break",
                          }}
                        >
                          {row?.user?.phoneNumber?.number}
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: "100px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            textWrap: "break",
                            wordBreak: "break",
                          }}
                        >
                          £{row?.totalAmount}
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: "100px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            textWrap: "break",
                            wordBreak: "break",
                          }}
                          className={
                            row?.status === "Payment Completed"
                              ? "completed"
                              : row?.status === "Awaiting Payment"
                              ? "awaiting"
                              : row?.status === "Cancelled Booking"
                              ? "cancelled"
                              : row?.status === "Requested Refund"
                              ? "refund"
                              : row?.status === "Part Payment Completed"
                              ? "part_paid"
                              : row?.status === "Pending"
                              ? "pending"
                              : "completed"
                          }
                        >
                          <span>{row?.status}</span>
                        </TableCell>
                        <TableCell className="menu-dot">
                          <NiyoNotificationModal
                            Content={Actions}
                            id={row?.id}
                            url={"bookings"}
                          >
                            <img
                              src={dots}
                              alt="dot"
                              onClick={() => handleBook(row?.hairStyle?.hairId)}
                            />
                          </NiyoNotificationModal>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                );
              })
            )}
          </TableBody>
        </Table>
      </BookingTableStyle>

      <CustomModal
        centered
        closable={false}
        open={modalStatus}
        onCancel={handleCancel}
        footer={null}
        width={1120}
      >
        <ViewBookingModal id="modal-content">
          <div className="view-heading">
            <h1>Booking Details</h1>
            <div className="btn flex">
              {booking?.status === "Part Payment Completed" && (
                <Button
                  onClick={handlePayment}
                  padding="8px 24px"
                  rounded="5px"
                  bg="#1D1F25"
                  disabled={load}
                  color="#FFFDFA"
                >
                  <span>{load ? "processing..." : "Payment Completed"}</span>
                </Button>
              )}
              <Button
                onClick={generatePDF}
                padding="5px 24px"
                rounded="5px"
                bg="#fff"
                color="#1D1F25"
                flex={true}
                border="1px solid #1D1F25"
              >
                <span>Save</span>
                <PrintIcon />
              </Button>
            </div>
          </div>
          <BookingModal>
            <div className="img">
              <img src={hairstyles[0]?.thumbnail?.url} alt="hairStyleImg" />
              {hairstyles[0]?.status === "draft" && (
                <div className="status">Draft</div>
              )}
              <div>
                <img src={hairstyles[0]?.thumbnail?.url} alt="hairStyleImg" />
                <img src={hairstyles[0]?.thumbnail?.url} alt="hairStyleImg" />
                <img src={hairstyles[0]?.thumbnail?.url} alt="hairStyleImg" />
              </div>
            </div>
            <div>
              <div className="view-info">
                <div className="client">
                  <div className="profile">
                    <p>
                      {getFirstLetters(
                        `${booking?.user?.firstName} ${booking?.user?.lastName}`
                      )}
                    </p>
                  </div>
                  <div className="detail">
                    <p>Client</p>
                    <p>
                      {booking?.user?.firstName} {booking?.user?.lastName}
                    </p>
                    <p>{booking?.user?.email}</p>
                    <p>{booking?.user?.phoneNumber?.number}</p>
                  </div>
                </div>
                <div className="payment">
                  <p>Payment Information</p>
                  <p>Master Card **** **** ****</p>
                  <p>
                    Name: {booking?.user?.firstName} {booking?.user?.lastName}
                  </p>
                  <p>Phone Number: {booking?.user?.phoneNumber?.number}</p>
                </div>
              </div>
              <div className="view-detail">
                <div>
                  <div className="flex">
                    <p>Date</p>
                    <p className="Payment">
                      {`${dayjs(booking?.preference?.schedule?.fullDate).format(
                        "DD MMM YY"
                      )} ${formatTime(booking?.preference?.schedule?.time)}`}
                    </p>
                  </div>
                  <div className="flex">
                    <p>Hairstyle Selected</p>
                    <p>{booking?.hairStyle?.name}</p>
                  </div>
                  <div className="flex">
                    <p>Payment Type</p>
                    <p>Debit Card</p>
                  </div>
                  <div className="flex">
                    <p>Booking ID</p>
                    <p>{booking?.id}</p>
                  </div>
                  {booking?.assignedTo && (
                    <div className="flex">
                      <p>Assigned to:</p>
                      <p>
                        {booking?.assignedTo?.firstName}{" "}
                        {booking?.assignedTo?.lastName}
                      </p>
                    </div>
                  )}
                  <div className="flex">
                    <p className="Payment">Payment</p>
                    <p>{booking?.status}</p>
                  </div>
                </div>
                <div className="units">
                  <div>
                    <div className="flex">
                      <p className="item">Items</p>
                      <p>Unit Price</p>
                    </div>
                    <div className="flex">
                      <p>Hairstyle Price</p>
                      <p>£{booking?.hairStyle?.price}</p>
                    </div>
                  </div>
                  <div className="flex">
                    <span>Preference</span>
                  </div>
                  {booking?.preference.preferences.map((preference, i) => {
                    const selectedOptions = preference.options.filter(
                      (option) => option.isSelected
                    );
                    if (selectedOptions.length > 0) {
                      return (
                        <div key={i} className="flex">
                          <p>
                            <span>
                              {preference.title.replace("Select ", "")}
                            </span>{" "}
                            :{" "}
                            {selectedOptions.map((selectedOption, j) => (
                              <span key={j}>{selectedOption.optionName}</span>
                            ))}
                          </p>
                          {selectedOptions.map((selectedOption, j) => (
                            <p key={j}>{selectedOption.optionPrice}</p>
                          ))}
                        </div>
                      );
                    }

                    return null;
                  })}
                  {bookingTransaction?.paymentPlan === "Part Payment" && (
                    <>
                      <div className="flex total">
                        <p>Down Payment Balance</p>
                        <p>£{bookingTransaction?.downPaymentBal}</p>
                      </div>
                      <div className="flex total">
                        <p>Total Amount Paid</p>
                        <p>£{bookingTransaction?.amount}</p>
                      </div>
                    </>
                  )}
                  <div className="flex total">
                    <p>Grand Total</p>
                    <p>£{booking?.totalAmount}</p>
                  </div>
                </div>
              </div>
            </div>
          </BookingModal>
          <div className="cancel" onClick={handleCancel}>
            <img src={closeIcon} alt="closeIcon" />
          </div>
        </ViewBookingModal>
      </CustomModal>

      <div className="footer">
        {isLoading ? (
          <div className="pagination-skeleton skeleton"></div>
        ) : (
          <TablePagination
            rowsPerPageOptions={[2, 4, 6]}
            component="div"
            count={latestData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </div>
    </BookingsListStyle>
  );
};

export default BookingsList;
