import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'



export const recentBooking = createAsyncThunk(
    'dashboard/fetchBooking',
    async (obj,{ rejectWithValue }) => {
      try {
       
        const { data: {data} } = await client.get(
          `${BASE_URL}/api/v1/dashboard/recent/bookings`
        )
        return data
      } catch (error) {
        // return custom error message from API if any
        throw rejectWithValue(error.message)
      }
    }
  )

export const getNumbers = createAsyncThunk(
    'dashboard/fetchNumbers',
    async (obj,{ rejectWithValue }) => {
      try {
       
        const { data: {data} } = await client.get(
          `${BASE_URL}/api/v1/dashboard/analytics-in-numbers`
        )
        return data
      } catch (error) {
        // return custom error message from API if any
        throw rejectWithValue(error.message)
      }
    }
  )

export const getCharts = createAsyncThunk(
    'dashboard/fetchCharts',
    async (obj,{ rejectWithValue }) => {
      try {
       
        const { data: {data} } = await client.get(
          `${BASE_URL}/api/v1/dashboard/analytics-in-charts`
        )
        return data
      } catch (error) {
        // return custom error message from API if any
        throw rejectWithValue(error.message)
      }
    }
  )

export const recentBookingStylist = createAsyncThunk(
    'dashboard/fetchStylistBooking',
    async (obj,{ rejectWithValue }) => {
      try {
       
        const { data: {data} } = await client.get(
          `${BASE_URL}/api/v1/dashboard/recent/bookings/hairstylist`
        )
        return data
      } catch (error) {
        // return custom error message from API if any
        throw rejectWithValue(error.message)
      }
    }
  )

export const getNumbersStylist = createAsyncThunk(
    'dashboard/fetchStylistNumbers',
    async (obj,{ rejectWithValue }) => {
      try {
       
        const { data: {data} } = await client.get(
          `${BASE_URL}/api/v1/dashboard/analytics-in-numbers/hairstylist`
        )
        return data
      } catch (error) {
        // return custom error message from API if any
        throw rejectWithValue(error.message)
      }
    }
  )

export const getChartsStylist = createAsyncThunk(
    'dashboard/fetchStylistCharts',
    async (obj,{ rejectWithValue }) => {
      try {
       
        const { data: {data} } = await client.get(
          `${BASE_URL}/api/v1/dashboard/analytics-in-charts/hairstylist`
        )
        return data
      } catch (error) {
        // return custom error message from API if any
        throw rejectWithValue(error.message)
      }
    }
  )