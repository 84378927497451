import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { edit, getCat } from '../redux/reducers/categoryReducer'
import { editRole, getRole } from '../redux/reducers/rolesReducer'
import { editRefer, getRefer } from '../redux/reducers/referralReducer'
import { editTransaction, getTran } from '../redux/reducers/transactionReducer'
import { viewBooks } from '../redux/reducers/bookingReducer'
import {
  getHairPreference,
  getHairstyle,
} from '../redux/reducers/hairstyleReducer'
import { Button, DeleteModal, DropDownStyle } from '../styles/components'
import { deleteCategory } from '../api/category'
import {
  getBooking,
  getBookingTransaction,
  assignBooking,
} from '../api/booking'
import { deleteHairstyle, hairstylePreference } from '../api/hairstyle'
import closeIcon from '../assets/icon/Close Square.svg'
import AssignModal from '../features/bookings/components/AssignModal'

const Actions = ({ hide, id, nav, url, handleOpen }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userRole = localStorage.getItem('userRole')
    ? localStorage.getItem('userRole')
    : null

  const [userId, setUserId] = useState()
  const [isAssignOpen, setIsAssignOpen] = useState(false)

  const handleSubmit = () => {
    setIsAssignOpen(false)

    dispatch(assignBooking({ id, userId }))
  }

  const trigger = () => {
    if (url === 'hairstyle') {
      navigate(`/hairstyle/edit/${id}`)
      dispatch(hairstylePreference(id))
    }

    hide()
    if (url === 'transaction') {
      dispatch(editTransaction(true))
      dispatch(getTran(id))
    }
    if (url === 'category') {
      dispatch(edit(true))
      dispatch(getCat(id))
    }
    if (url === 'role') {
      dispatch(editRole(true))
      dispatch(getRole(id))
    }
    if (url === 'bookings') {
      dispatch(viewBooks(true))
      dispatch(getBooking(id))
      dispatch(getBookingTransaction(id))
    }
    if (url === 'referral') {
      dispatch(editRefer(true))
      dispatch(getRefer(id))
    }
  }

  const remove = () => {
    if (url === 'category') {
      dispatch(deleteCategory(id))
      .then((result) => {
        setIsModalOpen(false)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
    }
    if (url === 'hairstyle') {
      dispatch(deleteHairstyle(id))
    }
    hide()
  }

  const viewHairstyle = () => {
    dispatch(getHairstyle(id))
    dispatch(getHairPreference(id))
    dispatch(hairstylePreference(id))
    handleOpen(true)
    hide()
  }

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleDeleteOpen = () => {
    setIsModalOpen(true)
    hide()
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <DropDownStyle>
      {url === 'hairstyle' && <p onClick={() => viewHairstyle()}>View {url}</p>}
      <div></div>
      <p onClick={() => trigger()}>
        {url === 'bookings' || url === 'transaction' ? 'View' : 'Edit'} {url}
      </p>
      <div></div>
      {userRole !== 'hairstylist' && (
        <>
          {url === 'bookings' ? (
            <p onClick={() => setIsAssignOpen(true)}>Assign {url}</p>
          ) : url !== 'referral' && url !== 'transaction' && url !== 'role' ? (
            <p className="delete" onClick={() => handleDeleteOpen()}>
              Delete {url}
            </p>
          ) : (
            ''
          )}
        </>
      )}

      <DeleteModal
        centered
        closable={false}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={496}
        sx={{ padding: 0 }}
      >
        <div className="text">
          <h3>Delete this {url}?</h3>
          <p>Are you sure you want to delete this {url}?</p>
        </div>
        <div className="line"></div>
        <div className="btn">
          <Button
            rounded="10px"
            bg="white"
            color="#000"
            padding="15px 24px"
            font="16px"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            rounded="10px"
            bg="#191919"
            color="#fff"
            padding="15px 24px"
            font="16px"
            weight="600"
            onClick={remove}
            hover={'#272727'}
          >
            Yes, Delete
          </Button>
        </div>

        <div className="cancel" onClick={handleCancel}>
          <img src={closeIcon} alt="closeIcon" />
        </div>
      </DeleteModal>

      {isAssignOpen && (
        <AssignModal
          isAssignOpen={isAssignOpen}
          setIsAssignOpen={setIsAssignOpen}
          setUserId={setUserId}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
        />
      )}
    </DropDownStyle>
  )
}

export default Actions
