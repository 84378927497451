import { createSlice } from '@reduxjs/toolkit'
import { bookings, getBooking, getBookingTransaction, users, assignBooking, getBookingStylist, updatePaymentStatus } from '../../api/booking'

const initialState = {
  loading: false,
  load: false,
  paymentStatus: false,
  data: null,
  user: null,
  usersRefer: null,
  modalStatus: false,
  error: null,
  filter: null,
  success: false,
  searchedBooking: '',
  booking: null,
  stylistBooking: null,
  bookingTransaction: null,
}

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    enableFilter: (state, action) => {
      const payload = action.payload
      state.filter = payload
    },
    disableErrors: (state) => {
      state.error = null
    },
    viewBooks: (state, action) => {
      const payload = action.payload
      state.modalStatus = payload
    },
    sortBySearch: (state, action) => {
      const payload = action.payload
      state.searchedBooking = payload
    },
    setPaymentStatus: (state) => {
      state.paymentStatus = false
    },
  },
  extraReducers: {
    [bookings.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [bookings.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    [bookings.rejected]: (state, action) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [users.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [users.fulfilled]: (state, action) => {
      state.loading = false
      state.user = action.payload
      state.error = 'users'
    },
    [users.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [getBooking.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [getBooking.fulfilled]: (state, action) => {
      state.loading = false
      state.booking = action.payload
      state.error = 'getBooking'
    },
    [getBooking.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [getBookingStylist.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [getBookingStylist.fulfilled]: (state, action) => {
      state.loading = false
      state.stylistBooking = action.payload
      state.error = 'getBookingStylist'
    },
    [getBookingStylist.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [getBookingTransaction.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [getBookingTransaction.fulfilled]: (state, action) => {
      state.loading = false
      state.bookingTransaction = action.payload
      state.error = 'getBookingTransaction'
    },
    [getBookingTransaction.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [assignBooking.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [assignBooking.fulfilled]: (state, action) => {
      state.loading = false
      state.error = 'assignBooking'
    },
    [assignBooking.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [updatePaymentStatus.pending]: (state, action) => {
      state.load = true
      state.error = null
    },
    [updatePaymentStatus.fulfilled]: (state, action) => {
      state.load = false
      state.paymentStatus = true
    },
    [updatePaymentStatus.rejected]: (state, action) => {
      state.load = false
      state.error = action.payload
    },
  },
})
export default bookingSlice.reducer

export const { viewBooks, sortBySearch, getRefer, enableFilter, disableErrors, setPaymentStatus } = bookingSlice.actions
