import { useCallback, useEffect, useState, useRef } from 'react'
import closeIcon from '../../assets/icon/Close Square.svg'
import { useDispatch, useSelector } from 'react-redux'
// import { useDropzone } from 'react-dropzone'
import EmptyCategory from '../category/component/emptyCat'
import { EmailContent } from '../../constants/updateAdminEmail'
import { PasswordContent } from '../../constants/updateAdminPassword'
import NiyoNotificationModal from '../../components/modal'
import dropDown from '../../assets/icon/down.svg'
import { CategoryModal } from '../category/styles/index'
import addCamera from '../../assets/icon/camera.svg'
import searchIcon from '../../assets/icon/search.svg'
import addProfile from '../../assets/img/add-profile.svg'
import menuPink1 from '../../assets/icon/format-list-bulleted (1).svg'
import menuPink from '../../assets/icon/format-list-bulleted (3).svg'
import formatList from '../../assets/icon/format-list-bulleted (4).svg'
import formatLists from '../../assets/icon/format-list-bulleted.svg'
import { TablePagination } from '@mui/material'
import RolesListGrid from './components/RolesListGrid'
import RolesListTable from './components/RolesListTable'
import { RolesListStyle } from './Style'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { roles, updateRoles } from '../../api/roles'
import { editRole, sortBySearch } from '../../redux/reducers/rolesReducer'
import { Spin } from 'antd'
import {
  SearchWrapper,
  InputStyle,
  InputField,
  CustomModal,
  Button,
} from '../../styles/components'

const Roles = () => {
  const {
    searchedRoles,
    data,
    error,
    updateError,
    loading,
    modalStatus,
    singleRole,
  } = useSelector((state) => state.roles)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [searchRole, setSearchRole] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(6)
  const [files, setFiles] = useState(null)

  const [isLoading, setIsLoading] = useState(true)

  const [uploadedImage, setUploadedImage] = useState(null)

  useEffect(() => {
    async function fetchData() {
      await dispatch(roles())
      setIsLoading(false)
    }
    fetchData()
  }, [dispatch])

  const filteredRoles = data?.filter((rol) => {
    return (
      rol?.firstName?.toLowerCase().indexOf(searchedRoles?.toLowerCase()) !== -1
    )
  })

  const latestData =
    filteredRoles &&
    filteredRoles
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) }
      })
      .sort((a, b) => b?.date - a?.date)

  const case1 =
    searchRole === ''
      ? latestData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : latestData

  const handleSearchTeam = (e) => {
    setSearchRole(e.target.value)
    dispatch(sortBySearch(e.target.value))
  }
  useEffect(() => {
    if (error !== null && typeof error !== 'string') {
      error &&
        error?.map((err) => {
          return toast.error(`${err?.name}${err?.message}`)
        })
    }
  }, [error])

  useEffect(() => {
    if (updateError === 'updateRoles') {
      dispatch(roles())
    }
  }, [updateError, dispatch])

  useEffect(() => {
    dispatch(sortBySearch(''))
  }, [dispatch])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const [rolesData, setRolesData] = useState({
    email: '',
    role: '',
    firstName: '',
    lastName: '',
    password: '',
  })


  useEffect(() => {
    if (singleRole) {
      setRolesData((prevState) => ({
        ...prevState,
        firstName: singleRole[0]?.firstName,
      }))
      setRolesData((prevState) => ({
        ...prevState,
        lastName: singleRole[0]?.lastName,
      }))
      setRolesData((prevState) => ({
        ...prevState,
        role: singleRole[0]?.role,
      }))
      setFiles(singleRole[0]?.photo?.url)
    }
  }, [dispatch, singleRole])

  const handleForm = (e) => {
    const { value, name } = e.target
    setRolesData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const [gridView, setGridView] = useState(true)
  const [avatar, setAvatar] = useState(null)

  const handleAvatarClick = () => {
    document.getElementById('avatarInput').click()
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file && file.size <= 1024 * 1024) {
      // Max size of 1MB
      setFiles(file)
      const reader = new FileReader()

      reader.onload = () => {
        setAvatar(reader.result)
      }
      setUploadedImage(true)
      reader.readAsDataURL(file)
    } else {
      toast.error('file exceeds limits of 1mb')
    }
  }
  const handleCancel = () => {
    dispatch(editRole(false))
  }

  const handleSubmit = () => {
    let form_data = new FormData()
    const id = singleRole && singleRole[0]?.id
    if (uploadedImage) {
      form_data.append('photo', files)
      if (
        rolesData?.firstName &&
        singleRole[0]?.firstName !== rolesData?.firstName
      ) {
        form_data.append('firstName', rolesData?.firstName)
        dispatch(updateRoles({ id, form_data }))
      }
      if (
        rolesData?.lastName &&
        singleRole[0]?.lastName !== rolesData?.lastName
      ) {
        form_data.append('lastName', rolesData?.lastName)
        dispatch(updateRoles({ id, form_data }))
      }
      if (rolesData?.role && singleRole[0]?.role !== rolesData?.role) {
        form_data.append('role', rolesData?.role)
        dispatch(updateRoles({ id, form_data }))
      }
      if (uploadedImage) {
        dispatch(updateRoles({ id, form_data }))
      }
    } else {
      if (
        rolesData?.firstName &&
        singleRole[0]?.firstName !== rolesData?.firstName
      ) {
        dispatch(updateRoles({ id, firstName: rolesData?.firstName }))
      }
      if (
        rolesData?.lastName &&
        singleRole[0]?.lastName !== rolesData?.lastName
      ) {
        dispatch(updateRoles({ id, lastName: rolesData?.lastName }))
      }
      if (rolesData?.role && singleRole[0]?.role !== rolesData?.role) {
        dispatch(updateRoles({ id, role: rolesData?.role }))
      }
      if (rolesData?.role && rolesData?.lastName && rolesData?.firstName) {
        dispatch(editRole(false))
      }
    }
  }

  if (data?.length < 1) return <EmptyCategory roles />

  return (
    <RolesListStyle>
      <div className="initialSort">
        <SearchWrapper>
          <div className="search-input">
            <img src={searchIcon} alt="search Icon" />
            <input
              type="text"
              value={searchRole}
              onChange={handleSearchTeam}
              placeholder="Search..."
            />
          </div>
          <div className="tableHeader">
            <div className="pagination">
              <span>Show</span>
              <TablePagination
                rowsPerPageOptions={[2, 4, 6, 8, 10, 12, 14, 16, 18]}
                component="div"
                rowsPerPage={rowsPerPage}
                labelRowsPerPage=""
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <p>entries</p>
            </div>
            <div className={`grid-view ${gridView && 'active'}`}>
              {gridView ? (
                <img
                  src={formatList}
                  alt="menu icon"
                  onClick={() => setGridView(true)}
                  className="grid-img"
                />
              ) : (
                <img
                  src={menuPink1}
                  alt="menu icon"
                  onClick={() => setGridView(true)}
                  className="grid-img"
                />
              )}
            </div>
            <div className={`menu grid-view ${!gridView && 'active'}`}>
              {gridView ? (
                <img
                  src={menuPink}
                  alt="menu icon"
                  onClick={() => setGridView(false)}
                  className="list-img"
                />
              ) : (
                <img
                  src={formatLists}
                  alt="menu icon"
                  onClick={() => setGridView(false)}
                  className="list-img"
                />
              )}
            </div>
          </div>
        </SearchWrapper>
      </div>
      {gridView ? (
        <RolesListGrid
          isLoading={isLoading}
          rowsPerPage={rowsPerPage}
          page={page}
          case1={case1}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          latestData={latestData}
          filteredRoles={filteredRoles}
        />
      ) : (
        <RolesListTable
          isLoading={isLoading}
          case1={case1}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          latestData={latestData}
          filteredRoles={filteredRoles}
        />
      )}
      <CustomModal
        centered
        closable={false}
        open={modalStatus}
        onCancel={handleCancel}
        footer={null}
        width={863}
      >
        <CategoryModal>
          <div className="category-form edit">
            <div className="flex">
              <div>
                <h3>Edit Member</h3>
                <p>Edit details of this Admin</p>
              </div>

              <div className="img">
                <label htmlFor="fileInput">
                  {avatar ? (
                    <img
                      src={avatar}
                      alt="Avatar"
                      style={{
                        borderRadius: '50%',
                        width: '100px',
                        height: '100px',
                      }}
                      onClick={handleAvatarClick}
                    />
                  ) : (
                    <label
                      htmlFor="avatarInput"
                      style={{
                        display: 'block',
                        width: '70px',
                        height: '70px',
                        background: '#ccc',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        overflow: 'hidden',
                      }}
                    >
                      <img
                        src={files} // Put the path of your default image here
                        alt="Default Avatar"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                      <input
                        type="file"
                        id="avatarInput"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        multiple={false}
                      />
                    </label>
                  )}
                </label>
              </div>
            </div>

            <div className="input-flex">
              <div>
                <div>
                  <label>First Name</label>
                  <InputField
                    name="firstName"
                    type="text"
                    value={rolesData.firstName}
                    onChange={handleForm}
                    placeholder="Lisa"
                  />
                </div>
                <div>
                  <label>Last Name</label>
                  <InputField
                    name="lastName"
                    type="text"
                    value={rolesData.lastName}
                    onChange={handleForm}
                    placeholder="Simon"
                  />
                </div>
              </div>
              <div>
                <div className="program-select">
                  <InputStyle>
                    <label>Role</label>
                    <div className="select program">
                      <select
                        name="role"
                        id=""
                        value={rolesData.role}
                        onChange={handleForm}
                      >
                        <option value="">Select option </option>
                        <option value="hairstylist">Hairstylist</option>
                        <option value="manager">Manager</option>
                        <option value="Admin">Admin</option>
                        <option value="super-admin">Super Admin</option>
                      </select>
                      <img src={dropDown} alt="dropDown" />
                    </div>
                  </InputStyle>
                </div>
                <div style={{ pointer: 'cursor' }}>
                  <label>Email</label>
                  <NiyoNotificationModal
                    Content={EmailContent}
                    email={singleRole}
                    id={singleRole}
                  >
                    <InputField
                      // type="text"
                      style={{ cursor: 'pointer' }}
                      value="Change Email"
                    />
                  </NiyoNotificationModal>
                </div>

                <div>
                  <label>Password</label>
                  <NiyoNotificationModal
                    Content={PasswordContent}
                    // Title={PasswordTitle}
                    id={singleRole}
                  >
                    <InputField
                      type="text"
                      style={{ cursor: 'pointer' }}
                      value="Change password"
                      placeholder="MyPassword123"
                    />
                  </NiyoNotificationModal>
                </div>
              </div>
            </div>
          </div>
          <div className="category-form edit">
            {/* <div className="permission">
              <p>Permissions</p>
            </div> */}

            <div className="btn">
              <Button
                padding={'8px 40px'}
                rounded={'5px'}
                onClick={handleSubmit}
              >
                {loading ? <Spin /> : 'Save Changes'}
              </Button>
            </div>
          </div>
          <div className="cancel" onClick={handleCancel}>
            <img src={closeIcon} alt="closeIcon" />
          </div>
        </CategoryModal>
      </CustomModal>
    </RolesListStyle>
  )
}

export default Roles
