import React, { useMemo, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import jwt_decode from 'jwt-decode'

import { ReactComponent as NiyoLogo } from '../assets/icon/niyoLogo.svg'

import logoutIcon from '../assets/icon/logout.svg'
import wallet from "../assets/icon/wallet-2.svg";
import hairstyle from "../assets/icon/hairstyle.svg";
import gift from "../assets/icon/gift.svg";
import category from "../assets/icon/category-2.svg";
import calendar from "../assets/icon/calendar.svg";
import dashboard from "../assets/icon/dashboard.svg";
import profile from "../assets/icon/profile-2user.svg";
import { SideNavStyle } from '../styles/components'
import { logout } from '../redux/reducers/authReducer'
import { useDispatch } from 'react-redux'

const SideNav = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const userRole = localStorage.getItem('userRole')
  ? localStorage.getItem('userRole')
  : null

  const sideNavLink = [
  {
    text: "Dashboard",
    icon: dashboard,
    link: "/dashboard",
    permission: userRole !== 'hairstylist' ? true : true,
  },
  {
    text: "Calendar",
    icon: calendar,
    link: "/calendar",
    permission: userRole !== 'hairstylist' ? true : true,
  },
  {
    text: "Hairstyles",
    icon: hairstyle,
    link: "/hairstyle",
    permission: userRole !== 'hairstylist' ? true : false,
  },
  {
    text: "Categories",
    icon: category,
    link: "/category",
    permission: userRole !== 'hairstylist' ? true : false,
  },
  {
    text: "Referrals",
    icon: gift,
    link: "/referral",
    permission: userRole !== 'hairstylist' ? true : false,
  },
  {
    text: "Bookings",
    icon: calendar,
    link: "/bookings",
    permission: userRole !== 'hairstylist' ? true : true,
  },
  {
    text: "Transactions",
    icon: wallet,
    link: "/transactions",
    permission: userRole !== 'hairstylist' ? true : false,
  },
  {
    text: "Roles",
    icon: profile,
    link: "/roles",
    permission: userRole !== 'hairstylist' ? true : false,
  },
];

  const expiry = useMemo(() => {
    const token = localStorage.getItem('userToken')
      ? localStorage.getItem('userToken')
      : null
    const decodedToken = token && jwt_decode(token)
    const currentDate = new Date()

    return decodedToken?.exp * 1000 < currentDate.getTime()
  }, [])

  useEffect(() => {
    if (expiry) {
      dispatch(logout())
    }
  }, [expiry, dispatch])

  return (
    <SideNavStyle>
      <div className="img">
        <NiyoLogo />
      </div>
      <ul>
        {sideNavLink.map((nav) => {
          const isLinkActive =
            nav.link === pathname.slice(0, 14) ||
            nav.link === pathname.slice(0, 10) ||
            nav.link === pathname.slice(0, 9) ||
            nav.link === pathname.slice(0, 6)
          return (
            <React.Fragment key={nav.text}>
              {nav?.permission && (
                <Link to={nav.link} key={nav.text}>
                  <li className={isLinkActive ? 'active' : ''}>
                    <img
                      className={isLinkActive ? 'active-img' : ''}
                      src={nav.icon}
                      alt="icon"
                    />
                    <p>{nav.text}</p>
                  </li>
                </Link>
              )}
            </React.Fragment>
          )
        })}
        <Link to="/">
          <li onClick={() => dispatch(logout())}>
            <img src={logoutIcon} alt="icon" />
            <p>Logout</p>
          </li>
        </Link>
      </ul>
    </SideNavStyle>
  )
}

export default SideNav
