import React from "react";
import { Button, HeadingStyle } from "../../../../styles/components";
import emailImg from "../../../../assets/img/email.svg";
import { MailStyle } from "../Style";
import { useDispatch } from "react-redux";
import { nextProcess } from "../../../../redux/reducers/forgotReducer";

const CheckMail = () => {
  const dispatch = useDispatch();
  const handleCheck = () => {
    dispatch(nextProcess("updatePassword"));
  };
  return (
    <MailStyle>
      <HeadingStyle>
        <h4>Check your email</h4>
      </HeadingStyle>
      <div>
        <div>
          <img src={emailImg} alt="email Img" />
        </div>
        <p>
          An account activation link has being sent to your registered email
          address
        </p>
        <Button onClick={handleCheck}>Open email app</Button>
        <span>
          Didn’t receive an email? <span>Click to resend</span>
        </span>
      </div>
    </MailStyle>
  );
};

export default CheckMail;
