import dayjs from 'dayjs'; 

export const sortBookinglabel = (dashboardMetrics) => {
  if (dashboardMetrics) {
    return ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']
  }

  return ['--', '--', '--', '--', '--']
}

export const sortBookingData = (dashboardMetrics) => {
  const dataList = {
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    data: [],
  };

  if (dashboardMetrics?.length > 0) {
    const data = Array(7).fill(0);

    dashboardMetrics.forEach((item) => {
      const dayOfWeek = dayjs(`${item._id.year}-${item._id.month}-${item._id.day}`).day();

      data[dayOfWeek] += item.count;
    });

    return [
      {
        ...dataList,
        data: data,
        label: 'sold',
        lineTension: 0.4,
        borderColor: '#15C15D',
      },
    ];
  }

  return [
    {
      ...dataList,
      data: [0, 0, 0, 0, 0, 0, 0],
      label: 'sold',
      lineTension: 0.4,
      borderColor: '#15C15D',
    },
  ];
};



export const sortRevenuelabel = (dashboardMetrics) => {
  if (dashboardMetrics) {
    return [
      ['Jan'],
      ['Feb'],
      ['Mar'],
      ['Apr'],
      ['May'],
      ['Jun'],
      ['Jul'],
      ['Aug'],
      ['Sept'],
      ['Oct'],
      ['Nov'],
      ['Dec'],
    ]
  }
  return ['--', '--', '--', '--', '--']
}



export const sortRevenueData = (dashboardMetrics) => {
  const calcDay = (arr, cut) => {
    const res = []
    return res
  }
  const dataList = {
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    data: [],
  }
  if (dashboardMetrics?.length > 0) {
    const sold = dashboardMetrics?.salesPerformance?.map(
      (val) => +val.inProgress,
    )
    const inProgress = dashboardMetrics?.salesPerformance?.map(
      (val) => +val.inProgress,
    )

    return [
      {
        ...dataList,
        data: calcDay(sold, 2),
        label: 'sold',
        lineTension: 0.4,
        borderColor: '#15C15D',
      },
      {
        ...dataList,
        data: calcDay(inProgress, 2),
        label: 'in progress',
        lineTension: 0.5,
        borderColor: '#1676F3',
      },
    ]
  }
  return [
    {
      ...dataList,
      // data: [900000, 400000, 800000, 200000, 200000, 1000000, 200000, 200000, 200000, 200000, 200000, 200000],
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: 'sold',
      lineTension: 0.4,
      borderColor: '#15C15D',
    },
    {
      ...dataList,
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: 'in progress',
      lineTension: 0.5,
      borderColor: '#EF4444',
    },
  ]
}
