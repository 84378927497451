import React from "react";
import Referral from "../../features/referrals/index";
import ReferralLayout from "../../layout/ReferralLayout";

const ReferralsPage = () => {
  return (
    <ReferralLayout>
      <Referral />
    </ReferralLayout>
  );
};

export default ReferralsPage;
