import React, { useEffect } from "react";
import HairStyleEmpty from "../../hairstyle/components/EmptyHair";
import referralImg from "../../../assets/img/giftImg.svg";
import { useNavigate } from 'react-router-dom'


const EmptyReferral = () => {
  const navigate = useNavigate()
  const handleOpen = () => {
    navigate('/referral');
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <HairStyleEmpty
        img={referralImg}
        text={"You have no user referred for this Programs yet"}
        btn={"Go to Program"}
        modal={handleOpen}
      />
      
    </div>
  );
};

export default EmptyReferral;
