import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'


export const roles = createAsyncThunk(
  'roles/fetchRoles',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/admin`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const createRole = createAsyncThunk(
  'roles/createroles',
  async (form_data , { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data:{ data } } = await client.post(
          `${BASE_URL}/api/v1/admin`,form_data, config
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateRoles = createAsyncThunk(
  'roles/updateRole',
  async ( { id, firstName, lastName, role, form_data } , { rejectWithValue }) => {
    try {
      if (firstName) {
        const { data:{ data } } = await client.put(
          `${BASE_URL}/api/v1/admin/${id}/update-admin`,
          { firstName }
      )
      return data
      }
      if (lastName) {
        const { data:{ data } } = await client.put(
          `${BASE_URL}/api/v1/admin/${id}/update-admin`,
          { lastName }
      )
      return data
      }
      if (role) {
        const { data:{ data } } = await client.put(
          `${BASE_URL}/api/v1/admin/${id}/update-admin`,
          { role }
      )
      return data
      }
      if (form_data) {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const { data:{ data } } = await client.put(
          `${BASE_URL}/api/v1/admin/${id}/update-admin`,
          form_data,
           config
      )
      return data
      }
        
    }catch (error) {
      // return custom error message from API if any
      if (error && error.data.message) {
        return rejectWithValue(error.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateAdminEmail = createAsyncThunk(
  'admin/updateAdminEmail',
  async ( { id, email } , { rejectWithValue }) => {
    try {
        const { data:{ data } } = await client.put(
          `${BASE_URL}/api/v1/admin/${id}/email`,
          { email }
      )
      return data
        
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateAdminPassword = createAsyncThunk(
  'admin/updateAdminPassword',
  async ( { id, password } , { rejectWithValue }) => {
    try {
        const { data:{ data } } = await client.put(
          `${BASE_URL}/api/v1/admin/${id}/password`,
          { password}
      )
      return data
        
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


