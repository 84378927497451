import React, { useState, useEffect } from 'react'
import {
  BookingClient,
  BookingDetails,
  BookingInfoBox,
  BookingStatus,
  BookingUnit,
  BookingsStyle,
  OpenBookingModal,
  ViewBookingModal,
} from '../features/bookings/Style'
import {
  BookingPreHeading,
  bookingDetailStatus,
  bookingDetailUnits,
  bookingPreOptions,
  customTime,
} from '../data'
import BookingsBox from '../components/BookingsBox'
import { Checkbox, Spin } from 'antd'
import closeIcon from '../assets/icon/Close Square.svg'
import {
  Button,
  CardBody,
  CustomModal,
  InputField,
  InputStyle,
} from '../styles/components'
import dropDown from '../assets/icon/down.svg'
import { useSelector } from 'react-redux'
import { acceptNumber, getFirstLetters } from '../helpers/validate'
import CheckMark from '../helpers/CheckMark'
import AssignModal from '../features/bookings/components/AssignModal'
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const BookingsLayout = ({ children }) => {
  const { data, stylistBooking, loading } = useSelector(
    (state) => state.booking,
  )
  const { date, day, year, month } = useSelector((state) => state.hairstyle)

  const [checkStylist, setcheckStylist] = useState(null)

  useEffect(() => {
    const userRole = localStorage.getItem('userRole')
      ? localStorage.getItem('userRole')
      : null
    setcheckStylist(userRole)
  }, [])
  const totalPaymentCount =
    checkStylist === 'hairstylist'
      ? stylistBooking?.completedBookings?.length
      : data?.completedBookings?.length

  const pendingPaymentCount =
    checkStylist === 'hairstylist'
      ? stylistBooking?.pendingBookings?.length
      : data?.pendingBookings?.length

  const canceledPaymentCount =
    checkStylist === 'hairstylist'
      ? stylistBooking?.otherBookings?.length
      : data?.otherBookings?.length

  const bookingsInfo = [
    {
      type: 'Completed Bookings',
      number: `${
        loading || totalPaymentCount === undefined ? '-' : totalPaymentCount
      }`,
      filterType: 'totalBookings',
    },
    {
      type: 'Awaiting Bookings',
      number: `${
        loading || pendingPaymentCount === undefined ? '-' : pendingPaymentCount
      }`,
      filterType: 'pendingBookings',
    },
    {
      type: 'Pending Bookings',
      number: `${
        loading || canceledPaymentCount === undefined
          ? '-'
          : canceledPaymentCount
      }`,
      filterType: 'otherBookings',
    },
  ]
  const [isAssignOpen, setIsAssignOpen] = useState(false)

  const [newBookingModal, setNewBookingModal] = useState(false)

  const [newBookingProcess, setNewBookingProcess] = useState('new booking')

  const initialAssignField = {
    people: '',
    message: '',
  }

  const [selectedPrefrence, setSelectedPrefrence] = useState([])

  const handleOptionClick = (sectionIndex, optionText) => {
    const newSelectedPrefrence = [...selectedPrefrence]
    newSelectedPrefrence[sectionIndex] = optionText
    setSelectedPrefrence(newSelectedPrefrence)
  }
  const [setAssignBooking] = useState(initialAssignField)

  const handleForm = (e) => {
    const { value, name } = e.target
    setAssignBooking((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const closeNewBooking = () => {
    setNewBookingModal(false)
    setNewBookingProcess('new booking')
  }

  const handleCancel = () => {
    setIsAssignOpen(false)
  }

  const todayDate = new Date()

  let todayDay = todayDate.getDate()
  let todayMonth = todayDate.getMonth() + 1
  let todayYear = todayDate.getFullYear()

  let currentDate = `${todayDay}-${todayMonth}-${todayYear}`

  const refurbishedDate = day && day.startsWith('0') ? day.slice(1) : day

  return (
    <BookingsStyle>
      <div className="booking-heading">
        <div className="heading">
          <h2>Bookings</h2>
          <p>
            Dashboard / <span>Bookings</span>
          </p>
        </div>

      </div>
      {checkStylist !== 'hairstylist' && (
        <BookingInfoBox>
          {bookingsInfo.map((box, i) => (
            <BookingsBox box={box} key={i} />
          ))}
        </BookingInfoBox>
      )}

      {children}

      {/* Assign bookings */}
      <AssignModal
        isAssignOpen={isAssignOpen}
        setIsAssignOpen={setIsAssignOpen}
        handleCancel={handleCancel}
      />

      {/* new bookings */}
      <CustomModal
        centered
        closable={false}
        open={newBookingModal}
        onCancel={closeNewBooking}
        footer={null}
        width={
          newBookingProcess === 'new booking'
            ? 659
            : newBookingProcess === 'preference' ||
              newBookingProcess === 'booking'
            ? 730
            : newBookingProcess === 'summary'
            ? 1200
            : 659
        }
      >
        <OpenBookingModal>
          {newBookingProcess === 'new booking' ? (
            <>
              <div>
                <h1>New Booking</h1>
                <p>Create a new booking for your client</p>
              </div>

              <div className="input-flex">
                <div>
                  <div>
                    <label>Email Address</label>
                    <InputField
                      name="email"
                      type="text"
                      onChange={handleForm}
                      placeholder="Lisasimon@gmail.com"
                    />
                  </div>
                  <div>
                    <label>Full Name (optional)</label>
                    <InputField
                      name="fullName"
                      type="text"
                      onChange={handleForm}
                      placeholder="Lisa simon"
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <label>Phone Number</label>
                    <InputField
                      name="number"
                      type="text"
                      onChange={handleForm}
                      onKeyDown={acceptNumber}
                      placeholder="+445234988821"
                    />
                  </div>
                  <div className="program-select">
                    <InputStyle>
                      <label>Hairstyle</label>
                      <div className="select program">
                        <select name="role" id="" onChange={handleForm}>
                          <option value="Passion">Passion twists</option>
                          <option value="Police">Police Cap</option>
                          <option value="Admin">Admin</option>
                          <option value="Super Admin">Super Admin</option>
                        </select>
                        <img src={dropDown} alt="dropDown" />
                      </div>
                    </InputStyle>
                  </div>
                </div>
              </div>

              <div className="btn">
                <Button
                  padding={'8px 40px'}
                  rounded={'5px'}
                  onClick={() => setNewBookingProcess('preference')}
                >
                  {loading ? <Spin /> : 'Next'}
                </Button>
              </div>
            </>
          ) : newBookingProcess === 'preference' ? (
            <div className="preference">
              <div>
                <h3>Preferences</h3>
                <p>Select preferences for your client</p>
              </div>
              {bookingPreOptions.map((item, bookingIndex) => (
                <div key={bookingIndex} className="select">
                  <div className="heading">
                    <h5>{BookingPreHeading[bookingIndex].title}</h5>
                    <p>{BookingPreHeading[bookingIndex].description}</p>
                  </div>
                  <div className="options">
                    {item.map((option, optionIndex) => (
                      <div
                        key={optionIndex}
                        className={`flex ${
                          selectedPrefrence[bookingIndex] === option.text
                            ? 'selected'
                            : ''
                        }`}
                        onClick={() =>
                          handleOptionClick(bookingIndex, option.text)
                        }
                      >
                        <p>{option.text}</p>
                        <CheckMark
                          selected={
                            selectedPrefrence[bookingIndex] === option.text
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <div className="btn">
                <Button
                  padding={'8px 40px'}
                  rounded={'5px'}
                  onClick={() => setNewBookingProcess('booking')}
                >
                  {loading ? <Spin /> : 'Next'}
                </Button>
              </div>
            </div>
          ) : newBookingProcess === 'booking' ? (
            <div className="booking">
              <div>
                <h1>New Booking</h1>
                <p>Create a new booking for your client</p>
              </div>
              <div className="calender">
                <CardBody>
                  <div className="dateWrapper">
                    <div className="dateBody">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar dayOfWeekFormatter={(day) => `${day}`} />
                      </LocalizationProvider>
                    </div>
                    <div className="dateBody1">
                      <div className="dateData">
                        {day !== null && month !== null && year !== null ? (
                          <div className="acceptedDate">
                            <div>
                              {refurbishedDate}
                              {refurbishedDate === '1'
                                ? 'st'
                                : refurbishedDate === '2'
                                ? 'nd'
                                : refurbishedDate === '3'
                                ? 'rd'
                                : 'th'}
                            </div>
                            <div>{month}</div>
                            <div>{year}</div>
                          </div>
                        ) : (
                          currentDate
                        )}
                      </div>
                      {customTime.map((time, i) => {
                        return (
                          <div className="dateContent">
                            <div> {time}</div>
                            <Checkbox
                              // onChange={handleChange1}
                              checked={time === date}
                              value={time}
                            ></Checkbox>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </CardBody>
              </div>
              <div className="btn">
                <Button
                  padding={'8px 40px'}
                  rounded={'5px'}
                  onClick={() => setNewBookingProcess('summary')}
                >
                  {loading ? <Spin /> : 'Book'}
                </Button>
              </div>
            </div>
          ) : newBookingProcess === 'summary' ? (
            <div className="summary">
              <ViewBookingModal newBooking={true}>
                <div className="view-heading flex">
                  <h1>Booking Details</h1>
                  <div className="btn flex">
                    <Button
                      onClick={closeNewBooking}
                      padding="8px 24px"
                      rounded="5px"
                      bg="#1D1F25"
                      color="#FFFDFA"
                    >
                      <span>Submit</span>
                    </Button>
                  </div>
                </div>
                <div className="view-info">
                  <BookingDetails>
                    <BookingClient>
                      <div className="profile">
                        <p>{getFirstLetters('Homer Simpson')}</p>
                      </div>
                      <div className="detail">
                        <p>Client</p>
                        <p>Susan James</p>
                        <p>susanjames@gmail.com</p>
                        <p>+448133245611</p>
                      </div>
                      <p>View Profile</p>
                    </BookingClient>
                    <BookingStatus>
                      {bookingDetailStatus.map((item, i) => (
                        <div key={i} className="flex">
                          <p>{item.type}</p>
                          <p className={`${item.text}`}>{item.text}</p>
                        </div>
                      ))}
                    </BookingStatus>
                  </BookingDetails>
                  <BookingUnit>
                    <div>
                      <div className="flex">
                        <p className="item">Items</p>
                        <p>Unit Price</p>
                      </div>
                      <div className="flex">
                        <p>Hairstyle Price</p>
                        <p>£125.00</p>
                      </div>
                    </div>
                    <div className="flex">
                      <span>Preferences</span>
                    </div>
                    {bookingDetailUnits.map((item, i) => (
                      <div key={i} className="flex">
                        <p>
                          <span>{item.type}</span> : <span>{item.info}</span>
                        </p>
                        <p className={`${item.text}`}>{item.text}</p>
                      </div>
                    ))}
                    <div className="flex sub-total">
                      <p>Subtotal:</p>
                      <p>£25.00</p>
                    </div>
                    <div className="flex sub-total">
                      <p>Tax:</p>
                      <p>£25.00</p>
                    </div>
                    <div className="flex total">
                      <p>Grand Total</p>
                      <p>£1450.00</p>
                    </div>
                  </BookingUnit>
                </div>
              </ViewBookingModal>
            </div>
          ) : (
            ''
          )}
          <div className="cancel" onClick={closeNewBooking}>
            <img src={closeIcon} alt="closeIcon" />
          </div>
        </OpenBookingModal>
      </CustomModal>
    </BookingsStyle>
  )
}

export default BookingsLayout
