import React from "react";
import { HairTableStyle } from "../Style";
import dots from "../../../assets/icon/dots-horizontal (1).svg";
import { useSelector } from "react-redux";
import NiyoNotificationModal from "../../../components/modal";
import Table from "@mui/material/Table";
import { EmptyWrapper } from "../../../styles/components";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Actions from "../../../constants/actions";

const columns = [
  'Name of Hairstyle',
  'Date Added',
  'Category',
  'Price',
  'Durations',
  'Status',
  'Actions',
]

const HairListTable = ({
  handleOpen,
  isLoading,
  rowsPerPage,
  page,
  case1,
  handleChangePage,
  handleChangeRowsPerPage,
  latestData,
  filteredHairstyle,
}) => {
  const { data } = useSelector((state) => state.hairstyle);

  return (
    <>
      <HairTableStyle>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.length === 0 && <div>There Is No Hairstyle</div>}

            {filteredHairstyle?.length === 0 ? (
              <EmptyWrapper>
                There are currently no hairstyle available
              </EmptyWrapper>
            ) : (
              case1?.map((row) => {
                  return (
                    <TableRow hover>
                      <TableCell>
                        <div>
                          <img src={row?.thumbnail?.url} alt="hairStyleImg" />
                          <span style={{
                            width: '120px'
                          }}>{row?.name}</span>
                          {row?.status === 'draft' && (
                            <span
                              style={{
                                borderRadius: '20px',
                                background: 'rgba(0, 0, 0, 0.2)',
                                color: '#000',
                                fontFamily: 'rebound',
                                fontSize: '10px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '140%',
                                padding: '6px 8px',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                              }}
                            >
                              draft
                            </span>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>{row?.createdAt.slice(0, 10)}</TableCell>
                      <TableCell>{row?.category}</TableCell>
                      <TableCell>£{row?.price}</TableCell>
                      <TableCell>{row?.averageTime}{row?.averageTime === "1" ? 'hr' : 'hrs'}</TableCell>
                      <TableCell>{row?.status}</TableCell>
                      <TableCell className="menu-dot">
                        <NiyoNotificationModal
                          Content={Actions}
                          id={row?.id}
                          url={'hairstyle'}
                          handleOpen={handleOpen}
                        >
                          <img src={dots} alt="dot" />
                        </NiyoNotificationModal>
                      </TableCell>
                    </TableRow>
                  )
                })
            )}
          </TableBody>
        </Table>
      </HairTableStyle>

      <div className="footer">
        {isLoading ? (
          <div className="pagination-skeleton skeleton"></div>
        ) : (
          <TablePagination
            rowsPerPageOptions={[2, 4, 6]}
            component="div"
            count={latestData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </div>
    </>
  )
}

export default HairListTable
