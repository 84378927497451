import { createSlice } from '@reduxjs/toolkit'
import {
    calendar,
    singleCalendar,
  updateCalendar,
  deleteCalendar,
} from '../../api/calendar'

const initialState = {
  loading: false,
  data: null,
  modalStatus: false,
  error: null,
  success: false,
  category: null,
  singleCalendarData: null,
}

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    edit: (state, action) => {
      const payload = action.payload
      state.modalStatus = payload
    },
    getCat: (state, action) => {
      const payload = action.payload
      const filter = state.data.filter((cat) => cat.id === payload)
      state.category = filter
    },
  },
  extraReducers: {
    [calendar.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [calendar.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    [calendar.rejected]: (state, action) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [singleCalendar.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [singleCalendar.fulfilled]: (state, action) => {
      state.loading = false
        state.singleCalendarData = action.payload
        state.error = 'success'
    },
    [singleCalendar.rejected]: (state, action) => {
      state.loading = false
      
    },
    [updateCalendar.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [updateCalendar.fulfilled]: (state, action) => {
      state.loading = false
      state.error = 'updateCalendar'
    },
    [updateCalendar.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [deleteCalendar.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [deleteCalendar.fulfilled]: (state, action) => {
      state.loading = false
      state.error = 'deleteCalendar'
    },
    [deleteCalendar.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})
export default calendarSlice.reducer

export const { edit, getCat } = calendarSlice.actions
