import React, { useEffect, useState } from "react";
import ForgotForm from "./component/ForgotForm";
import CheckMail from "./component/CheckMail";
import UpdatePassword from "../../../features/auth/forgotPassword/component/UpdatePassword";
import { useSelector } from "react-redux";

const ForgotPassword = () => {
  const currentProcess = useSelector((state) => state.forgotProcess);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      {currentProcess === "forgotPassword" ? (
        <ForgotForm />
      ) : currentProcess === "checkMail" ? (
        <CheckMail />
      ) : currentProcess === "updatePassword" ? (
        <UpdatePassword />
      ) : (
        ""
      )}
    </>
  );
};

export default ForgotPassword;
