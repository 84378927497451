import { createSlice } from "@reduxjs/toolkit";

const initialState = "forgotPassword";

const forgotSlice = createSlice({
  name: "forgotProcess",
  initialState,
  reducers: {
    nextProcess: (state, action) => {
      const payload = action.payload;
      return (state = payload);
    },
  },
});

export default forgotSlice.reducer;

export const { nextProcess } = forgotSlice.actions;
