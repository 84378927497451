import React from "react";
import Category from "../../features/category";
import CategoryLayout from "../../layout/CategoryLayout";

const CategoryPage = () => {
  return (
    <CategoryLayout>
      <Category />
    </CategoryLayout>
  );
};

export default CategoryPage;
