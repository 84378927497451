import React from "react";
import RolesLayout from "../../layout/RolesLayout";
import EditRole from "../../features/roles/EditRole";

const EditRolePage = () => {
  return (
    <RolesLayout>
      <EditRole />
    </RolesLayout>
  );
};

export default EditRolePage;
