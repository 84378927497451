import { Modal } from 'antd'
import { styled } from 'styled-components'

export const HairStyled = styled.section`
  padding: 16px 50px 90px;
  h1 {
    text-transform: capitalize;
  }
`

export const StyledRadioWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  font-size: 16px;
  color: #333;
  cursor: pointer;

  input[type='radio'] {
    appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #d4d4d4;
    border-radius: 50%;
    outline: none;
    position: relative;
    transition: border-color 0.3s ease-in-out;

    &:checked {
      border-color: #ff35d3;
      background-color: transparent;

      &::before {
        content: '';
        position: absolute;
        top: 2.4px;
        left: 2.8px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #ff35d3;
      }
    }
  }

  span {
    flex-grow: 1;
    color: #ff35d3;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
`

export const HairStyleInfo = styled.div`
  padding: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  .hairstyle-heading {
    display: flex;
    justify-content: space-between;
    padding-bottom: ${({ checkprogram }) => (checkprogram ? 0 : '43px')};
    align-items: center;
    .category-heading {
      p {
        font-size: 14px;
        color: #505050;
      }

      .program {
        color: #ff46ce;
      }
    }

    .heading {
      .addhairstyle {
        color: #bdbdbd;
        span {
          color: #ff46ce;
        }
      }
      h1 {
        color: #191919;
      }
      p {
        font-size: 14px;
        span {
          color: #ff46ce;
        }
      }
    }

    .btn {
      display: flex;
      gap: 20px;
      button {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
        span {
          font-weight: 600;
          font-size: 16px;
        }
        img {
          filter: invert(1);
        }
      }

      /* a {
        button {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 8px;
          span {
            font-weight: 400;
            font-size: 16px;
            color: white;
          }
        }
      } */
    }
  }
  .small-btn {
    position: relative;
    text-align: right;
    margin-bottom: 16px;
    margin-left: auto;
    span {
      color: #a8a8a8;
      font-family: rebound;
      font-weight: 400;
    }
    .dropdown {
      min-width: 142px;
      position: absolute;
      background-color: white;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      top: 45px;
      left: -50px;
      text-align: left;
      border-radius: 8px;
      p:nth-child(1) {
        margin-top: 24px;
      }
      p {
        border-bottom: 0.3px solid #e8e8e8;
        padding: 6px 27px 11px;
        color: #858282;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        &:hover {
          background-color: #ebebeb;
        }
      }
    }
  }

  .add-hairstyle {
    background-color: white;
    display: grid;
    height: 100vh;
    place-items: center;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      img {
        margin-bottom: 8px;
      }
    }
  }
`

export const HairStyleForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .input {
    display: flex;
    flex-direction: column;
    gap: 10px;
    input,
    select {
      border: 1px solid #c2c7d0;
      padding: 18px 16px;

      &::placeholder {
        color: #c2c7d0;
      }
    }
    label {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .img-upload {
    h3 {
      padding: 0 0 14px 0;
    }
    > label {
      font-weight: 400;
    }
    > div {
      margin-top: 14px;
      border-radius: 10px;
      border: 1px dashed #c2c7d0;
      background: #fff;
      padding: 40px 0 88px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 44px;
      input {
        display: none;
      }
    }
  }
  .options-preference {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .option {
      display: flex;
      cursor: pointer;
      gap: 8px;
      &:hover {
        p {
          color: #000;
        }
      }
    }
    .preference {
      align-self: flex-end;
      div {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 28px 32px;
        border-radius: 6px;
        border: 1px solid #a4a6a7;
        cursor: pointer;
        p {
          color: #a4a6a7;
        }
      }
      &:hover {
        // background-color: #f5f6f7;
        color: white;
      }
    }
  }
  .preference-list {
    > div {
      margin-bottom: 40px;
      > div:nth-child(1) {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #8c8c95;
        padding: 22px 0;
        img:nth-child(2) {
          margin-left: 10px;
        }
      }
      > div:nth-child(2) {
        margin-top: 10px;
      }
    }
  }
  .btn {
    align-self: start;
  }
`

export const InputFlex = styled.div`
  display: flex;
  // align-items: flex-start;
  justify-content: space-between;
  gap: 22px;
  margin-top: 24px;
  img {
    margin-top: 20px;
    cursor: pointer
  }
  div {
    flex: 1;
  }
  &.add-preference {
    margin-bottom: 26px;
    opacity: 0.30000001192092896;
    div {
      label {
        color: #191919;
      }
    }
  }
`

export const AddHairStyled = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 32px 48px 32px;

  .select {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 24px;
    div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    p {
      white-space: nowrap;
    }
  }
  .heading {
    width: 100%;
    white-space: nowrap;
    margin: 20px 0 24px;
    h1 {
      font-weight: 700;
    }
  }
  > div {
    background-color: white;
  }
`

export const NumberStyle = styled.span`
  background-color: #ff47ce;
  color: white;
  display: flex;
  padding: 7px 12px;
  justify-content: center;
  align-items: center;
  display: inline;
  border-radius: 100%;
  margin-right: 8px;
  font-size: 16px;
  &:has(img) {
    background-color: #f6d5f1;
    /* padding: 7px 8px; */
  }
`

export const HairListStyle = styled.div`
  background-color: white;
  padding: 24px 16px 109px;
  border-radius: 4px;
`



export const HairStyleTable = styled.div`
  /* width: 100%;
  thead, tbody{
    width: 100%;
  } */
`

export const HairStyleFlex = styled.div`
  background-color: white;
`

export const HairTableStyle = styled.div`
  margin-top: 40px;
  background-color: transparent;
  min-height: 400px;
  table {
    thead {
      margin-bottom: 32px !important;
      tr {
        .ant-table-cell {
          background-color: transparent;
          border-bottom: 1px solid #eaeaea;
          color: #1f1f1f;
          font-weight: 400;
          padding: 18px 0 !important;
          width: 200px;

          &:nth-child(1) {
            padding-left: 19px !important;
          }
        }
        th {
          text-align: center;
          width: 200px;
          &::before {
            /* display: none; */
          }
        }
      }
    }
    tbody {
      .no-hairstyle {
        /* outline: solid red; */
      }
      tr {
        position: relative;
        &:hover {
          background-color: transparent !important;
        }
        .menu-dot {
          div {
            img {
              cursor: pointer;
            }
          }
        }
        td {
          text-transform: capitalize;

          color: #a8a8a8;
          font-weight: 400;
          font-size: 14px;
          padding: 18px 0 !important;
          text-align: center;
          width: 200px;

          &:nth-child(1):has(img) {
            div {
              width: 70%;
              margin: auto;
              display: flex;
              align-items: center;
              gap: 8px;
              span {
                font-weight: 400;
              }
              img {
                background-color: #ffd6f2;
                width: 40px;
                height: 40px;

                border-radius: 2000px;
              }
            }
          }
        }
        td:nth-child(4) {
          /* color: #a8a8a8; */
          font-size: 18px;
          /* font-weight: 700; */
        }
      }
    }
  }
`

export const TableImgStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 15px;

  .img {
    background-color: ${({ theme }) => theme.color.pink};
    width: 15%;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 2000px;
    img {
      width: 33px;
      height: 35.792px;
    }
  }
  .text {
  }
`

export const TableMenuList = styled.div`
  cursor: pointer;
  .dropdown {
    position: absolute;
    top: 73px;
    left: -100px;
    min-width: 142px;
    background-color: white;
    padding: 30px 26px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    text-align: center;
    border-radius: 8px;
    z-index: 3;
    fill: #fff;
    filter: drop-shadow(0px 12px 24px rgba(29, 27, 27, 0.2));
    p {
      color: #858282;
      font-size: 14px;
      cursor: pointer;
    }
    .delete {
      color: #ef4444;
    }
  }
`

export const HairListModal = styled.div`
  position: relative;
  display: flex;
  align-items: start;
  gap: 38px;
  padding: 72px 40px;
  text-transform: capitalize;

  .status {
    position: absolute;
    width: fit-content;
    top: 60px;
    margin-left: 10px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.2);
    // background: red;
    color: #000;
    font-family: rebound;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding: 6px 8px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  > div {
    flex: 1;
  }
  ul {
    li {
      span {
        color: #505050;
      }
    }
  }
  h4 {
    font-size: 20px;
    margin-bottom: 8px;
    font-weight: 700;
  }
  p {
    color: #8c8c95;
  }
  .img {
    img {
      width: 100%;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.color.pink};
    }
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
      gap: 8px;
      img {
        width: 32%;
      }
    }
  }
  .text {
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 24px 40px 72px;
    border-radius: 10px;
    h1 {
      margin-bottom: 24px;
    }
    .information {
      ul {
        display: flex;
        flex-direction: column;
        gap: 24px;
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid rgba(158, 158, 158, 0.5);
          padding: 4px 0 16px;
          span {
            color: #505050;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
    }
    .hairstyle {
      > div {
        border-radius: 4px;
        border: 1px solid #e3e4ea;
        padding: 16px;
        > div {
          margin-bottom: 24px;
          p {
            font-weight: 600;
          }
        }
        ul {
          display: flex;
          flex-direction: column;
          gap: 12px;
          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 24px;
            border-radius: 4px;
            margin-bottom: 15px;
            border: 1px solid #dfe0e0;
            span {
              font-weight: 400;
              color: #64686d;
            }
            > span {
              font-size: 14px;
            }
            div {
              display: flex;
              align-items: center;
              gap: 8px;
              span {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .btn {
      display: flex;
      gap: 16px;
      div {
        width: 25%;
        button {
          width: 100%;
        }
      }
    }
  }
  .cancel {
    position: absolute;
    top: -60px;
    right: -5px;
    padding: 2px;
    cursor: pointer;
    border-radius: 6px;
  }
`


export const CustomModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    background: #fafafa;
    width: 100%;
    margin: 116px 0;
    padding: 0;
  }
  
`
