import { styled } from "styled-components";

export const MailStyle = styled.div`
  > div:nth-child(2) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
    margin-top: 30px;
    span{
      span{
        cursor: pointer;
        color: #191919;
      }
    }
  }

`;
