import React, { useState, useEffect } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import Edit from '../../assets/icon/edit2.svg'
import { Wrap, CalendarModal } from './style'
import { Spin } from 'antd'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import { format } from 'date-fns'
import { Button, CustomModal } from '../../styles/components'
import { Skeleton3, Skeleton1 } from './components/skeleton'
import closeIcon from '../../assets/icon/Close Square.svg'
import { calendar, singleCalendar, updateCalendar } from '../../api/calendar'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Switch from '@mui/material/Switch'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { useDispatch, useSelector } from 'react-redux'
import { edit } from '../../redux/reducers/calendarReducer'

const CalendarPage = () => {
  const { modalStatus, loading, data, singleCalendarData, error } = useSelector(
    (state) => state.calendar,
  )
  const dispatch = useDispatch()

  const [editHour, setEditHour] = useState(false)
  const [data2, setData2] = useState()
  const [editStartTime, setEditStartTime] = useState('')
  const [selectedId, setSelectedId] = useState('')
  const [editEndTime, setEditEndTime] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [editBlock, setEditBlock] = useState()
  const [checkStylist, setcheckStylist] = useState(null)
  // const [currentTime, setCurrentTime] = useState()



  const [id, setId] = useState('')

  useEffect(() => {
    function fetchData() {
      dispatch(calendar())
    }
    fetchData()
  }, [dispatch])


  useEffect(() => {
    if (data?.length > 0) {
      setData2(data)
    }
  }, [data])

  useEffect(() => {
    if (error === 'success') {
      setIsLoading(false)
    }
  }, [error])


  useEffect(() => {
    const userRole = localStorage.getItem('userRole')
      ? localStorage.getItem('userRole')
      : null
    setcheckStylist(userRole)
  }, [])

  useEffect(() => {
    if (singleCalendarData) {
      setEditBlock(singleCalendarData?.blocked)
    }
  }, [singleCalendarData])

  const handleOpen = (id) => {
    dispatch(singleCalendar(id))
    dispatch(edit(true))
    setId(id)
  }
  const handleCancel = () => {
    dispatch(edit(false))
  }

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 40,
    height: 24,
    padding: 0,
    borderRadius: 12,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 18,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(17px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 19,
      height: 19,
      borderRadius: 9,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255,255,255,.35)'
          : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }))

  const handleDayClick = (id) => {
  //  const currentDay = data.find((time) => time.id === id)
  //   setCurrentTime(currentDay)
    setIsLoading(true)
    setSelectedId(id)
    setId(id)
    dispatch(singleCalendar(id))
  }

  const handleOpen2 = () => {
    setIsLoading(true)
    const id = data[0]?.id
    setId(id)
    setSelectedId(id)
    dispatch(singleCalendar(id))
    setEditHour(true)
  }

  const handleCancel2 = () => {
    setEditHour(false)

  }

  const handleStartTime = (newValue) => {
    try {
      const val = format(new Date(newValue), 'HH:mm')
      setEditStartTime(val)
    } catch (error) {
      console.error(error.message)
    }
  }

  const handleEndTime = (newValue) => {
    try {
      const val = format(new Date(newValue), 'HH:mm')
      setEditEndTime(val)
    } catch (error) {
      console.error(error.message)
    }
  }

  const startDate =
    error !== 'updateCalendar' ? singleCalendarData?.openTime : editStartTime
  const blockDate =
    error !== 'updateCalendar' ? singleCalendarData?.blocked : editBlock
  const endDate =
    error !== 'updateCalendar' ? singleCalendarData?.closeTime : editEndTime
  
  const convertTime = (time) => {
    return `${new Date().toISOString().split('T')[0]}T${time}`
  }
  
  const handleSubmit = () => {
    const updatedData = data2.map(item => 
      item.id === id ? { ...item, openTime: editStartTime || startDate, closeTime: editEndTime || endDate, blocked: editBlock } : item
    );
    setData2(updatedData)
    dispatch(
      updateCalendar({
        id,
        openTime: editStartTime || startDate,
        closeTime: editEndTime || endDate,
        // blocked: editBlock,
      }),
    )
    dispatch(edit(false))

  }

  const handleToggleChange = () => {
    setEditBlock(!blockDate)
  }

  return (
    <Wrap>
      <div className="layout">
        {loading ? (
          <Skeleton1 />
        ) : (
          <>
            <h3>Calendar Settings</h3>
            <p>
              Customise time for each working day, Editing a day means users would book a time based on the customised time
            </p>
            <div className="weekWrapper">
              {data2?.map((days, index) => {
                return (
                  <div className="flex">
                    <div className="dayOfWeek">
                      {days.day}{' '}
                      {/* {days?.blocked && (
                        <span
                          style={{
                            borderRadius: '20px',
                            background: 'rgba(0, 0, 0, 0.2)',
                            color: '#000',
                            fontFamily: 'rebound',
                            fontSize: '10px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '140%',
                            padding: '6px 8px',
                          }}
                        >
                          blocked
                        </span>
                      )} */}
                    </div>
                    <div className="flex2">
                      <div>
                        {days.openTime} - {days.closeTime}
                      </div>
                      {
                        checkStylist !== 'hairstylist' && (
                        <div onClick={() => handleOpen(days.id)}>
                          <img src={Edit} alt="pencil" />
                        </div>
                        )
                      }
                    </div>
                  </div>
                )
              })}
            </div>
            {
                checkStylist !== 'hairstylist' && (
              <div className="btnWrapper">
                <div className="btn">
                  <Button
                    padding={'8px 40px'}
                    rounded={'5px'}
                    bg="black"
                    onClick={handleOpen2}
                  >
                    {loading ? <Spin /> : 'Edit all hours'}
                  </Button>
                </div>
              </div>
                  
              )
            }
          </>
        )}
        <CustomModal
          centered
          closable={false}
          open={modalStatus}
          onCancel={handleCancel}
          footer={null}
          width={900}
        >
          <CalendarModal>
            <div>
              <h3>Edit Day</h3>
            </div>
            {!loading && singleCalendarData ? (
              <>
                <form className="category-form">
                  <div>
                    {/* <div>Start Time</div> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div></div>
                      <TimePicker
                        label="Start Time"
                        defaultValue={dayjs(convertTime(startDate))}
                        onChange={handleStartTime}
                      />
                    </LocalizationProvider>
                  </div>
                  <div>
                    {/* <div>End Time</div> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="End Time"
                        defaultValue={dayjs(convertTime(endDate))}
                        onChange={handleEndTime}
                      />
                    </LocalizationProvider>
                  </div>
                </form>
                {/* <div className="flex">
                  <div>
                    <p>Block Day</p>
                    <p>Block this day in the calender</p>
                  </div>
                  <div>
                    <AntSwitch
                      defaultChecked={blockDate}
                      onChange={handleToggleChange}
                      inputProps={{ 'aria-label': 'ant design' }}
                    />
                  </div>
                </div> */}
              </>
            ) : (
              <Skeleton3 />
            )}
            <div className="btn">
              <Button
                padding={'8px 40px'}
                rounded={'5px'}
                bg="black"
                onClick={handleSubmit}
              >
                {loading ? <Spin /> : 'Publish'}
              </Button>
            </div>
            <div className="cancel" onClick={handleCancel}>
              <img src={closeIcon} alt="closeIcon" />
            </div>
          </CalendarModal>
        </CustomModal>

        <CustomModal
          centered
          closable={false}
          open={editHour}
          onCancel={handleCancel2}
          footer={null}
          width={900}
        >
          <CalendarModal>
            <div>
              <h3>Edit All Hours</h3>
            </div>
            <p>Select Days</p>
            <div className="dayWrapper">
              {data?.map((days, index) => {
                return (
                  <>
                    <div
                      className={`day ${days?.id === selectedId && 'selected'}`}
                      onClick={() => handleDayClick(days?.id)}
                      key={index}
                    >
                      <div>{days.day.slice(0, 1)}</div>
                    </div>
                  </>
                )
              })}
            </div>
            {!isLoading ? (
              <>
                <form className="category-form">
                  <div>
                    {/* <div>Start Times</div> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div></div>
                      <TimePicker
                        label="Start Time"
                        defaultValue={dayjs(convertTime(startDate))}
                        onChange={handleStartTime}
                      />
                    </LocalizationProvider>
                  </div>
                  <div>
                    {/* <div>End Time</div> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="End Time"
                        defaultValue={dayjs(convertTime(endDate))}
                        onChange={handleEndTime}
                      />
                    </LocalizationProvider>
                  </div>
                </form>
                {/* <div className="flex">
                  <div>
                    <p>Block Day</p>
                    <p>Block this day in the calender</p>
                  </div>
                  <div>
                    <AntSwitch
                      defaultChecked={blockDate}
                      onChange={handleToggleChange}
                      inputProps={{ 'aria-label': 'ant design' }}
                    />
                  </div>
                </div> */}
              </>
            ) : (
              <Skeleton3 />
            )}
            <div className="btn">
              <Button
                padding={'8px 40px'}
                rounded={'5px'}
                bg="black"
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? <Spin /> : 'Update'}
              </Button>
            </div>
            <div className="cancel" onClick={handleCancel2}>
              <img src={closeIcon} alt="closeIcon" />
            </div>
          </CalendarModal>
        </CustomModal>
      </div>
    </Wrap>
  )
}

export default CalendarPage
