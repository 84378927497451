import React from 'react'
import {
  HairStyleInfo,
  HairStyled,
} from '../features/hairstyle/Style'
import BookingsBox from "../components/BookingsBox";
import { BookingInfoBox } from '../features/bookings/Style'
import { useSelector } from 'react-redux'

const CategoryLayout = ({ children }) => {
  const { data,loading } = useSelector((state) => state.transaction)

  const totalTransactionCount = data?.filter(
    (item) => item.status === 'success',
  ).length

  const pendingTransactionCount = data?.filter(
    (item) => item.status === 'pending',
  ).length

  const canceledTransactionCount = data?.filter(
    (item) => item.status === 'cancelled',
  ).length

  const transactionInfo = [
    {
      type: 'Total Transactions',
      number: `${(loading || totalTransactionCount === undefined) ? '-' : totalTransactionCount}`,
    },
    {
      type: 'Pending Transactions',
      number: `${(loading || pendingTransactionCount === undefined) ? '-' : pendingTransactionCount}`,
    },
    {
      type: 'Cancelled Transactions',
      number: `${(loading || canceledTransactionCount === undefined) ? '-' : canceledTransactionCount}`,
    },
  ]
  return (
    <HairStyled>
      <HairStyleInfo>
        <div className="hairstyle-heading">
          <div className="heading">
            <h1>Transactions</h1>
            <p>
              Dashboard/<span>Transactions</span>
            </p>
          </div>
        </div>
          <BookingInfoBox>
            {transactionInfo.map((box, i) => (
              <BookingsBox box={box} key={i} />
            ))}
          </BookingInfoBox>
        {children}
      </HairStyleInfo>
    </HairStyled>
  )
}

export default CategoryLayout
