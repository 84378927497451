import React, { useEffect } from "react";
import dots from "../../../assets/icon/dots-horizontal.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NiyoNotificationModal from "../../../components/modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import { sortBySearch } from "../../../redux/reducers/hairstyleReducer";
import Actions from "../../../constants/actions";
import { hairstyle } from "../../../api/hairstyle";
import { GridStyle, EmptyWrapper } from "../../../styles/components";
import { toast } from "react-hot-toast";
import EmptyCategory from "../../category/component/emptyCat";

const HairListGrid = ({
  handleOpen,
  isLoading,
  rowsPerPage,
  page,
  case1,
  handleChangePage,
  handleChangeRowsPerPage,
  latestData,
  filteredHairstyle,
}) => {
  const { loading, data, error } =
    useSelector((state) => state.hairstyle);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    function fetchData() {
      dispatch(hairstyle());
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (error === "deleteHairstyle") {
      dispatch(hairstyle());
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (error === "null") {
      dispatch(hairstyle());
      setTimeout(() => {
        toast.success("Successfully Added Category");
      }, 1000);
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (error === "updateCategory") {
      dispatch(hairstyle());
    }
  }, [error, dispatch]);

  useEffect(() => {
    dispatch(sortBySearch(""));
  }, [dispatch]);

  return (
    <>
      <Table>
        <TableBody>
          {data?.length === 0 ? (
            <EmptyCategory hairstyle/>
          ) : filteredHairstyle?.length < 1 ? (
            <EmptyWrapper>There Is No Hairstyle For Your Search</EmptyWrapper>
          ) : (
            <GridStyle>
              {loading ? (
                <>
                  {[
                    ...Array(data?.length > 2 ? rowsPerPage : data?.length),
                  ].map((_, index) => (
                    <div key={index} className="skeleton-loader skeleton"></div>
                  ))}
                </>
              ) : (
                case1?.map((row) => (
                    <div key={row?.id}>
                      <img src={row?.thumbnail?.url} alt="hairStyleImg" />
                      {
                        row?.status === "draft" && (
                          <div className="status">Draft</div>
                        )
                      }
                      <div className="thumbnail">
                        <div>
                          <span>{row?.name}</span>
                          <h3>£{row?.price}</h3>
                          <p>{row?.averageTime} {row?.averageTime === "1" ? 'hr' : 'hrs'}</p>
                        </div>
                        <div className="menu">
                          <NiyoNotificationModal
                            Content={Actions}
                            id={row?.id}
                            url={"hairstyle"}
                            handleOpen={handleOpen}
                          >
                            <img src={dots} alt="dot" />
                          </NiyoNotificationModal>
                        </div>
                      </div>
                    </div>
                  ))
              )}
            </GridStyle>
          )}
        </TableBody>
      </Table>
      {data?.length !== 0 && (
        <div className="footer">
          {loading ? (
            <div className="pagination-skeleton skeleton"></div>
          ) : (
            <TablePagination
              rowsPerPageOptions={[2, 4, 6]}
              component="div"
              count={latestData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      )}
    </>
  );
};

export default HairListGrid;
