import { createSlice } from '@reduxjs/toolkit'
import {
  recentBooking,
  getNumbers,
  getCharts,
  recentBookingStylist,
  getNumbersStylist,
  getChartsStylist
} from '../../api/dashboard'

const initialState = {
  loading: false,
  numbers: null,
  stylistNumbers: null,
  modalStatus: false,
  error: null,
  success: false,
  searchedBooking: '',
  bookings: null,
  stylistBookings: null,
  charts: null,
  stylistCharts: null,
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    edit: (state, action) => {
      const payload = action.payload
      state.modalStatus = payload
    },
    sortBySearch: (state, action) => {
      const payload = action.payload
      state.searchedBooking = payload
    },
    // getCat: (state, action) => {
    //   const payload = action.payload
    //   const filter = state.data.filter((cat) => cat.id === payload)
    //   state.category = filter
    // },
  },
  extraReducers: {
    [recentBooking.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [recentBooking.fulfilled]: (state, action) => {
      state.loading = false
      state.bookings = action.payload
    },
    [recentBooking.rejected]: (state, action) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [recentBookingStylist.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [recentBookingStylist.fulfilled]: (state, action) => {
      state.loading = false
      state.stylistBookings = action.payload
    },
    [recentBookingStylist.rejected]: (state, action) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [getNumbers.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [getNumbers.fulfilled]: (state, action) => {
      state.loading = false
      state.numbers = action.payload
    },
    [getNumbers.rejected]: (state, action) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [getNumbersStylist.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [getNumbersStylist.fulfilled]: (state, action) => {
      state.loading = false
      state.stylistNumbers = action.payload
    },
    [getNumbersStylist.rejected]: (state, action) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [getCharts.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [getCharts.fulfilled]: (state, action) => {
      state.loading = false
      state.charts = action.payload
    },
    [getCharts.rejected]: (state, action) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [getChartsStylist.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [getChartsStylist.fulfilled]: (state, action) => {
      state.loading = false
      state.stylistCharts = action.payload
    },
    [getChartsStylist.rejected]: (state, action) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
  
  },
})
export default dashboardSlice.reducer

export const { edit, sortBySearch } = dashboardSlice.actions
