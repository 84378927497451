import { createSlice } from '@reduxjs/toolkit'
import {
  transactions,
} from '../../api/transaction'

const initialState = {
  loading: false,
  data: null,
  modalStatus: false,
  error: null,
  success: false,
  searchedTransaction: '',
  transaction: null,
}

const categorySlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    editTransaction: (state, action) => {
      const payload = action.payload
      state.modalStatus = payload
    },
    sortBySearch: (state, action) => {
      const payload = action.payload
      state.searchedTransaction = payload
    },
    getTran: (state, action) => {
      const payload = action.payload
      const filter = state.data?.filter((tran) => tran.id === payload)
      state.transaction = filter
    },
  },
  extraReducers: {
    [transactions.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [transactions.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    [transactions.rejected]: (state, action) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
  },
})
export default categorySlice.reducer

export const { editTransaction, sortBySearch, getTran } = categorySlice.actions
