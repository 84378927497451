import { Modal } from "antd";
import { styled } from "styled-components";

export const Button = styled.button`
  background-color: ${({ theme, bg }) => bg || theme.color.btnBg};
  color: ${({ theme, color }) => color || theme.color.white};
  padding: ${({ padding }) => padding || "16px 24px"};
  border: ${({ border }) => border || "#1d1f25"};
  font-size: ${({ font }) => font || "16px"};
  cursor: pointer;
  border-radius: ${({ rounded }) => rounded || "10px"};
  outline: none;
  white-space: nowrap;
  font-weight: ${({ weight }) => weight};

  display: ${({ flex }) => (flex ? "flex" : "")};
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: ${({ hover, bg }) =>
      hover ? hover : bg === "white" || bg === "#fff" ? "#f1f1f2" : ""};
  }
  span {
    color: ${({ theme, color }) => color || theme.color.white};
    font-size: ${({ font }) => font || "16px"};
  }
  /* margin: 16px 0 0; */
  /* width: ${({ width }) => (width ? width : "100%")}; */
`;

export const Btn = styled.div`
  background-color: ${({ theme, bg }) => bg || theme.color.btnBg};
  color: ${({ theme, color }) => color || theme.color.white};
  padding: ${({ padding }) => padding || "16px 24px"};
  border: ${({ border }) => border || "#1d1f25"};
  font-size: ${({ font }) => font || "16px"};
  cursor: pointer;
  border-radius: ${({ rounded }) => rounded || "10px"};
  outline: none;
  white-space: nowrap;
  font-weight: ${({ weight }) => weight};

  display: ${({ flex }) => (flex ? "flex" : "")};
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: ${({ hover, bg }) =>
      hover ? hover : bg === "white" || bg === "#fff" ? "#f1f1f2" : ""};
  }
  span {
    color: ${({ theme, color }) => color || theme.color.white};
    font-size: ${({ font }) => font || "16px"};
  }
  /* margin: 16px 0 0; */
  /* width: ${({ width }) => (width ? width : "100%")}; */
`;

export const FilterSearchStyle = styled.div``;

export const InputStyle = styled.div`
  > input,
  select,
  textarea {
    display: block;
    width: 100%;
    padding: 19px 22px;
    margin: 12px 0 0;
    border-radius: 10px;
    outline: none;
    /* border: 1px solid #8993a4; */
    border: 1px solid #c2c7d0;
    font-size: 16px;
    /* color: #8993a4; */
    color: black;
    &::placeholder {
      color: #c2c7d0;
      font-size: 14px;
    }
    option {
      text-transform: capitalize;
    }
  }

  .select {
    position: relative;
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      cursor: pointer;
    }
    img {
      position: absolute;
      right: 20px;
      top: 32px;
      height: 15px;
      width: 15px;
    }
  }

  .program {
    img {
      top: 22px;
    }
  }
  .img-upload {
    margin-top: 14px;
    img {
      cursor: pointer;
    }
    .upload {
      cursor: pointer;
    }
    p {
      font-size: 14px;
    }
  }

  label {
    font-weight: 600;
  }

  &:has(span) {
    input {
      margin-bottom: 8px;
    }
    p,
    span {
      font-size: 10px;
    }
    span {
      font-weight: 700;
      color: black;
    }
  }

  p {
    cursor: pointer;
    b {
      color: #3578db;
      font-weight: 400;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    margin-right: 10px;
  }
`;

export const AuthLayoutStyle = styled.section`
  display: flex;
  // height: 100vh;
  overflow: hidden;
  > div:nth-child(1) {
    background: #ffc2ef;
    min-height: 100%;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div:nth-child(1) {
      padding: 62px 62px 30px;
      display: flex;
      gap: 154px;
      flex-direction: column;
      h1 {
        margin-bottom: 20px;
      }
    }
    img {
      display: block;
    }
  }

  > div:nth-child(2) {
    width: 65%;
    margin: 0 auto;
    padding: 200px 61px;
    max-width: 510px;
  }
`;

export const DashLayoutStyle = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: #f5f6f7;
`;

export const SideNavStyle = styled.div`
  background-color: ${({ theme }) => theme.color.darkBg};
  padding: 24px;
  width: 17%;
  height: 100vh;
  overflow-y: scroll;
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;

  ::-webkit-scrollbar {
    height: 0px;
    width: 7px;
    border-radius: 10px;
    overflow: hidden;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    display: none;
    background-color: rgb(174, 174, 174);
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 37px;
    margin-bottom: 100px;
    li {
      color: ${({ theme }) => theme.color.white};
      padding: 10px;
      display: flex;
      align-items: center;
      gap: 16px;
      border-radius: 8px;
      font-family: reboundLight;
      img {
        width: 24px;
        height: 24px;
      }
      .active-img {
        filter: brightness(0);
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: white;
      }
      &.active {
        background-color: ${({ theme }) => theme.color.pink};
        p {
          color: black;
          font-weight: 600;
        }
      }
    }
  }
`;

export const InputField = styled.input`
  border-radius: 10px;
  border: 1px solid #c2c7d0;
  width: ${({ width }) => (width ? width : "100%")};
  background: ${({ background }) =>
    background ? background : "var(--light-grey)"};
  padding: 19px 22px;
  outline: none;
  margin-top: 10px;
  color: var(--semi-grey);
  &::placeholder {
    color: #a0a6a6;
    font-size: 16px;
  }
`;

export const SelectField = styled.select`
  border-radius: 10px;
  border: 1px solid #c2c7d0;
  width: ${({ width }) => (width ? width : "100%")};
  background: ${({ background }) =>
    background ? background : "var(--light-grey)"};
  padding: 19px 22px;
  outline: none;
  margin-top: 10px;
  cursor: pointer;
  padding-right: 20px;
  color: var(--semi-grey);
  &::placeholder {
    color: #a0a6a6;
    font-size: 16px;
  }
`;

export const TextAreaField = styled.textarea`
  border-radius: 10px;
  border: 1px solid #c2c7d0;
  width: ${({ width }) => (width ? width : "100%")};
  background: ${({ background }) =>
    background ? background : "var(--light-grey)"};
  padding: ${({ booking }) => (booking ? "19px 22px 137px" : "19px 22px")};
  outline: none;
  margin-top: ${({ booking }) => (booking ? "0" : "10px")};
  color: var(--semi-grey);
  &::placeholder {
    color: #a0a6a6;
    font-size: 16px;
  }
`;

export const AdminSearch = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 61px;
  padding: 24px 50px 0;
  padding: ${({ localSearch }) => (localSearch ? 0 : "24px 50px 0")};
  .notification-img {
    cursor: pointer;
  }
  .notification {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 95px;
    right: 110px;
    z-index: 4;
    > div:nth-child(1) {
      padding: 16px 9px 9px 24px;
      border-radius: 10px 10px 0px 0px;
      background-color: #252629;
      p {
        color: #fafafb;
      }
    }
    .messages {
      padding: 24px 23px 48px 24px;
      > div:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 19px 0;
        span {
          color: #252629;
        }
      }
      .check {
        width: 20px;
        height: 20px;
      }
      > div:nth-child(2) {
        border-top: 1px solid #e3e6e9;
        > div {
          border-bottom: 1px solid #e3e6e9;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 103px;
          padding: 24px 0 39px;
          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            span {
              color: #858585;
              display: block;
            }
            div {
              > span:nth-child(2) {
                margin-top: 8px;
                font-size: 10px;
                color: #a8a8a8;
              }
            }
          }
        }
      }
    }
  }
  > div:nth-child(1) {
    flex-grow: 1;
    position: relative;
    input {
      border: 1px solid #e3e6e9;
      padding: 12px;
      padding-left: 40px;
      width: 100%;
      font-size: 14px;
      background-color: transparent;
      border-radius: 4px;
      &::placeholder {
        font-size: 14px;
        color: #4e5054;
        font-family: satoshi;
      }
    }
    img {
      position: absolute;
      left: 9px;
      top: 9px;
    }
  }
  > div:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 22px;
    .grid-img {
      border-radius: 4px;
      background: #f5f9ff;
      padding: 12px;
    }
    img {
      cursor: pointer;
    }
    > img {
      height: 30px;
      width: 30px;
      border-radius: 20px;
    }
  }
  select {
    outline: none;
    padding: 10px 12px;
    border: 1px solid rgba(230, 232, 234, 0.62);
    background: #fff;
    color: #858585;
    font-size: 12px;
  }
`;

export const AdminMain = styled.div`
  width: 85%;
`;

export const HeadingStyle = styled.section`
  text-align: center;
  p {
    margin: 8px 0 41px 0;
    color: #8993a4;
  }
`;

export const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  a {
    text-decoration: none;
    p {
      color: #4b4b4b;
    }
  }
`;

export const DropDownStyle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    color: #858282;
    font-weight: 400;
    text-transform: capitalize;
    width: 100%;
    cursor: pointer;
    padding: 10px 20px 14px;
    &:hover {
      background-color: #f1f1f2;
    }
  }
  p:nth-child(1) {
    padding-top: 20px;
  }

  .delete {
    color: #ef4444;
  }
  div {
    background: #dbdbdb;
    height: 0.3px;
    width: 100%;
  }
`;

export const DeleteModal = styled(Modal)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 30px;

  .ant-modal-content {
    padding: 0;
    border-radius: 8px;
    background: #fafafa;
    width: 100%;
    margin: 116px 0;
    background-color: white;
  }

  .text {
    padding: 19px 0 25px;
    text-align: center;
    h3 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
      color: #333;
      margin-top: 12px;
    }
  }
  .line {
    height: 1px;
    width: 100%;
    background-color: #e4e4e7;
  }

  .btn {
    padding: 19px 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    max-width: 300px;
    margin: auto;
  }

  .cancel {
    position: absolute;
    top: -65px;
    right: -5px;
    padding: 2px;
    cursor: pointer;
    border-radius: 6px;
  }
`;

export const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    border-radius: 8px;
    background: #fafafa;
    width: 100%;
    margin: 116px 0;
  }
`;

export const GridStyle = styled.div`
  padding-top: 29px;
  display: grid;
  gap: 16px;
  position: relative;
  min-height: 400px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  .status {
    position: absolute;
    width: fit-content;
    top: 10px;
    margin-left: 10px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.2);
    // background: red;
    color: #000;
    font-family: rebound;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding: 6px 8px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  > div {
    position: relative;
    overflow: hidden;
    max-width: 260px;
    height: 282px;
    width: 100%;
    border-radius: 10px;
    margin: 0;
    :where(.css-dev-only-do-not-override-14wwjjs).ant-popover {
    }
    .menu {
      cursor: pointer;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: #ffd5f1;
    }
    .thumbnail {
      position: absolute;
      width: 100%;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(4.313984394073486px);
      padding: 8px 16px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .menu {
        position: relative;
        img {
          width: 24px;
          height: 20px;
        }
      }
      h3,
      span {
        font-weight: 700;
      }
      p,
      h3,
      span {
        color: white;
      }
      p {
        font-size: 12px;
      }
      h3 {
        font-size: 18px;
      }
    }
  }
`;

export const EmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
`

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-input {
    width: 45%;
    position: relative;
    input {
      border: 1px solid #e3e6e9;
      padding: 16px;
      padding-left: 45px;
      width: 100%;
      font-size: 14px;
      background-color: transparent;
      border-radius: 8px;
      &::placeholder {
        font-size: 14px;
        color: #4e5054;
      }
    }
    img {
      position: absolute;
      left: 15px;
      top: 15px;
    }
  }
  .tableHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 14px;
      color: #858585;
    }
    .pagination {
      display: flex !important;
      justify-content: center !important;
      gap: 10px !important;
      align-items: center !important;
      margin-right: 16px !important;
      span {
        color: #858585 !important;
        font-size: 12px !important;
      }
      .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
        padding: 0 !important;
      }
      .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
        cursor: pointer !important;
        border: 1px solid rgba(230, 232, 234, 0.62) !important;
        margin: 0 !important;
        padding: 0 !important;
        background: #fff !important;
        color: #858585 !important;
        svg {
          margin-right: 5px !important;
          font-size: 13px !important;
        }
        .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
          padding: 8.5px 12px !important;
          background: #fff !important;
          margin-right: 14px !important;
        }
      }
    }
    .menu {
      margin: 0 28px 0 20px;
      cursor: pointer;
    }
    .grid-view {
      cursor: pointer;
      border-radius: 4px;
      padding: 12px;
    }
    .active {
      background-color: #f5f9ff;
    }
  }
`;

export const CardBody = styled.div`
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "4px")};
  h2 {
    margin-top: 0;
  }

  .dateWrapper {
    display: flex;
    gap: 19px;
    width: 100%;

    label {
      display: flex;
      flex-direction: row-reverse;
      span {
        display: block !important;
      }
    }

    .dateBody {
      overflow-y: hidden;
      border: 1px solid #dfe0e0;
      border-radius: 8px;
      flex: 1;
      .css-flbe84-MuiDayCalendar-weekContainer {
        margin: 20px;
        button {
          color: #222;
          font-size: 19.5px;
          font-family: reboundBold;
          font-weight: 600;
        }
      }
      .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition {
        overflow-x: visible !important;
      }

      .css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
      .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
        background: #ffc5ef;
      }
      .css-cwhad8-MuiDateCalendar-root {
        overflow: visible !important;
        .css-nk89i7-MuiPickersCalendarHeader-root {
          display: flex;
          .css-cyfsxc-MuiPickersCalendarHeader-labelContainer {
            width: 300px;
            overflow: visible !important;
            .css-31ca4x-MuiPickersFadeTransitionGroup-root {
              .css-dplwbx-MuiPickersCalendarHeader-label {
                white-space: nowrap;
                color: #222;
                font-family: reboundBold;
                font-size: 22.75px;
                font-weight: 600;
                line-height: normal;
              }
            }
          }
        }
        .css-sf5j28-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton {
        }
        .css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton {
          display: none;
        }
      }
      .css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,
      .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
        padding: 0 !important;
        background-color: inherit !important;

        &:hover {
          background-color: none !important;
        }
        span {
          display: none !important;
        }
      }
      .css-9reuh9-MuiPickersArrowSwitcher-root {
        background-color: transparent !important;
        display: flex;
        gap: 9px;
        text-align: left;
        justify-content: start;
        width: 46px;
        margin-right: 50px;
        &:hover {
          background-color: none;
        }
        svg {
          margin-left: 0px;
          border-radius: 100%;
          background: #ffc5ef;
          box-shadow: 0px 1.625px 6.5px 0px rgba(190, 190, 190, 0.15);
          width: 26.105px;
          height: 26px;
          &:hover {
            background-color: none;
          }
        }
      }
      .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
      }
    }

    .acceptedDate {
      font-size: 16px;
      display: flex;
      gap: 7px;
    }

    .dateData {
      background: #ff99e4;
      border: 1px solid #dfe0e0;
      display: flex;
      justify-content: space-between;
      border-radius: 4px;
      padding: 15px;
      color: white;
      top: 0px;
      z-index: 100;
      position: sticky;
    }

    .dateBody1 {
      flex: 1;
      height: 400px;
      overflow-y: auto;
      position: relative;
    }
    .dateContent {
      border: 1px solid #dfe0e0;
      display: flex;
      justify-content: space-between;
      border-radius: 4px;
      padding: 15px;
      margin-bottom: 4px;
    }
    label {
      display: flex;
      flex-direction: row-reverse;
      span {
        display: block !important;
      }
    }
  }
`;
