import React from 'react'
import { styled } from 'styled-components'

export const Skeleton1 = () => {
  const weeks = [1, 2, 3, 4, 5, 6, 7]
  return (
    <Wrapper>
      <h3 className="skeleton">{}</h3>
      <p className="skeleton">{}</p>
      {weeks.map((week) => (
        <div key={week} className="weekWrapper">
          <div className="skeleton weeks"></div>
          <div className="skeleton date"></div>
        </div>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .skeleton {
    background: linear-gradient(
      to right,
      #f0f0f0 0%,
      #e0e0e0 50%,
      #f0f0f0 100%
    );
    background-size: 200% 100%;
    border-radius: 4px;
    animation: shimmer 1s infinite;
  }
  .weekWrapper {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 24px;
  }
  .weeks {
    width: 20%;
    height: 30px;
  }
  .date {
    width: 15%;
    height: 30px;
  }
  h3 {
    width: 24%;
    height: 30px;
    margin-bottom: 20px;
  }
  p {
    width: 55%;
    height: 30px;
    margin-bottom: 20px;
  }
`
export const Skeleton2 = () => {
  return <div>skeleton</div>
}
export const Skeleton3 = () => {
  return (
    <Wrapper3>
      <div className="inputWrapper">
        <div className="input"></div>
        <div className="input"></div>
      </div>
      <div className="blockWrapper">
        <div className="blockContent"></div>
        <div className="block"></div>
      </div>
    </Wrapper3>
  )
}

const Wrapper3 = styled.div`
  .inputWrapper,
  .blockWrapper {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 40px;
  }
  .input {
    background: linear-gradient(
      to right,
      #f0f0f0 0%,
      #e0e0e0 50%,
      #f0f0f0 100%
    );
    background-size: 200% 100%;
    border-radius: 4px;
    animation: shimmer 1s infinite;
    width: 100%;
    height: 50px;
  }
  .blockContent {
    background: linear-gradient(
      to right,
      #f0f0f0 0%,
      #e0e0e0 50%,
      #f0f0f0 100%
    );
    background-size: 200% 100%;
    border-radius: 4px;
    animation: shimmer 1s infinite;
    width: 30%;
    height: 50px;
  }
  .block {
    background: linear-gradient(
      to right,
      #f0f0f0 0%,
      #e0e0e0 50%,
      #f0f0f0 100%
    );
    background-size: 200% 100%;
    border-radius: 4px;
    animation: shimmer 1s infinite;
    width: 7%;
    height: 30px;
  }
`
