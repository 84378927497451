import { styled } from "styled-components";

export const LoginStyle = styled.div`
  label {
    font-size: 16px;
    font-weight: 600;
  }
  
  > div {
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
`;

export const NiyoLogoStyle = styled.div`
  padding: 62px 0 0 62px;
`;
