import React from "react";
import Transaction from "../../features/transaction";
import TransactionLayout from "../../layout/TransactionLayout";

const TransactionPage = () => {
  return (
    <TransactionLayout>
      <Transaction />
    </TransactionLayout>
  );
};

export default TransactionPage;
