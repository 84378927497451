import { styled } from "styled-components";

export const RoleStyle = styled.section`
  padding: 16px 43px 118px;
  margin-bottom: 24px;
  .roles-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .heading {
      margin-bottom: 22px;
      p {
        color: #bdbdbd;
        span {
          color: #ff46ce;
        }
      }
    }
  }
  .roles-edit {
    > div:nth-child(1) {
      p {
        display: flex;
        align-items: center;
        margin-top: 8px;
      }
      h4 {
        font-size: 20px;
      }
    }
    .inputs {
      // display: none;
      border: 3px solid red !important;
    }
    .role-profile {
      display: flex;
      align-items: start;
      margin: 27.5px 0 0 0;
      background-color: white;
      border-radius: 16px;
      padding: 32px 24px 58px;
      width: 75%;
      gap: 24px;
      .img {
        img {
          border-radius: 100%;
          width: 80px;
          height: 80px;
          object-fit: cover;
        }
       
      }

      .text {
        .tag {
          span {
            margin-top: 14px;
            text-align: center;
            min-width: 118px;
            display: inline-block;
            padding: 4px 8px;
            background-color: #ffe6f8;
            color: #ff65d6;
            border-radius: 100px;
            font-size: 12px;
          }
        }
        .contact {
          gap: 27px;
        }

        .street {
          margin: 8px 0 16px;
        }
      }
      .edit {
        gap: 16px;
      }
    }
  }

  .role-stat {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    > div {
      background-color: #fff;
      border-radius: 16px;
      display: flex;
      padding: 24px 0px 36px 24px;
      gap: 12px;
      flex: 1;
      h4 {
        margin: 10px 0;
        font-weight: 400;
      }
    }
  }

  .admin-heading {
    margin: 40px 0 24px;
  }
`;

export const RolesListStyle = styled.div`
  background-color: white;
  padding: 24px 16px 70px;
  border-radius: 8px;
`;

export const RolesTableStyle = styled.div`
  /* margin-top: 40px; */
  background-color: ${({ edit }) => (edit ? "white" : "transparent")};
  min-height: ${({ edit }) => (edit ? "" : "400px")};

  table {
    thead {
      margin-bottom: 32px !important;
      tr {
        .ant-table-cell {
          &:nth-child(1) {
            text-align: left;
          }
        }
        th {
          text-align: left;
          border-bottom: ${({ edit }) => (edit ? "" : "none")};
          padding-left: ${({ edit }) => (edit ? "16px" : "0")} !important;
        }
      }
    }
    tbody {
      tr {
        position: relative;
        &:hover {
          background-color: transparent !important;
        }
        .menu-dot {
          div {
            img {
              cursor: pointer;
            }
          }
        }
        td {
          text-align: left;
          text-transform: capitalize;
          color: #a8a8a8;
          font-weight: 400;
          font-size: 14px;
          padding: 18px 0 !important;
          padding-left: ${({ edit }) => (edit ? "18px" : "")} !important;
          border-bottom: ${({ edit }) => (edit ? "" : "none")};
          &:nth-child(1):has(img) {
            div {
              width: 100%;
              display: flex;
              align-items: center;
              gap: 8px;
              img {
                background-color: #ffd6f2;
                width: 40px;
                height: 40px;
                border-radius: 2000px;
              }
            }
          }
        }
        td:nth-child(4) {
          div {
            span {
              text-align: center;
              min-width: 118px;
              display: inline-block;
              padding: 4px 8px;
              background-color: #ffe6f8;
              color: #ff65d6;
              border-radius: 100px;
              font-size: 12px;
            }
          }
        }
      }
      .preference {
        text-decoration: underline;
        text-align: center;
      }
      .completed {
        span {
          margin-right: 20px;
          padding: 10px;
          color: #3dba49;
          background-color: #ecf8ed;
          border-radius: 10px;
        }
      }
    }
  }
`;
