import React from 'react'
import HairStyle from '../../features/hairstyle';
import HairStyleLayout from '../../layout/HairStyleLayout';

const HairStylePage = () => {
  return (
    <HairStyleLayout>
        <HairStyle/>
    </HairStyleLayout>
  );
}

export default HairStylePage