import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { CatTableStyle, CatWrapperStyle, CategoryModal } from './styles'
import { Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import searchIcon from '../../assets/icon/search.svg'
import closeIcon from '../../assets/icon/Close Square.svg'
import menuPink from '../../assets/icon/menuPinkIcon.svg'
import NiyoNotificationModal from '../../components/modal'
import Actions from '../../constants/actions'
import {
  InputField,
  Btn,
  TextAreaField,
  CustomModal,
  SearchWrapper,
} from '../../styles/components'
import dots from '../../assets/icon/dots-horizontal (1).svg'
import { edit, sortBySearch } from '../../redux/reducers/categoryReducer'
import { categories, updateCategory } from '../../api/category'
import { toast } from 'react-hot-toast'
import EmptyCategory from './component/emptyCat'

const columns = [
  { id: 'Date Added', label: 'Date Added', minWidth: 150 },
  { id: 'Hair Category', label: 'Hair Category', minWidth: 150 },
  { id: 'Description', label: 'Description', minWidth: 550 },
  { id: 'Actions', label: 'Actions', minWidth: 10 },
]

const Category = () => {
  const {
    modalStatus,
    loading,
    searchedCategory,
    data,
    error,
    category,
  } = useSelector((state) => state.category)
  const navigate = useNavigate()
  const dispatch = useDispatch()


  const [page, setPage] = useState(0)
  const [name, setName] = useState('')
  const [description, setDescription1] = useState()
  const [searchCat, setSearchCat] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(6)

  const [isLoading, setIsLoading] = useState(true)

  const filteredCategories = data?.filter((cat) => {
    return (
      cat?.name?.toLowerCase().indexOf(searchedCategory?.toLowerCase()) !== -1
    )
  })

  const latestData =
    filteredCategories &&
    filteredCategories
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) }
      })
      .sort((a, b) => b?.date - a?.date)

  const case1 =
    searchCat === ''
      ? latestData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : latestData
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (category) {
      setName(category[0]?.name)
      setDescription1(category[0]?.description)
    }
  }, [dispatch, category])

  useEffect(() => {
    function fetchData() {
      dispatch(categories())
      setIsLoading(false)
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    if (error === 'deleteCategory') {
      dispatch(categories())
    }
  }, [error, dispatch])

  useEffect(() => {
    if (error === 'null') {
      dispatch(categories())
      setTimeout(() => {
        toast.success('Successfully Added Category')
      }, 1000)
    }
  }, [error, dispatch])

  useEffect(() => {
    if (error === 'updateCategory') {
      dispatch(categories())
      setTimeout(() => {
        toast.success('Successfully Updated Category')
      }, 1000)
    }
  }, [error, dispatch])

  const handleCancel = () => {
    dispatch(edit(false))
  }

  const handleCategory = (e) => {
    setName(e.target.value)
  }

  const handleDescription = (e) => {
    setDescription1(e.target.value)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const handleEdit = () => {
    const id = category[0].id
    dispatch(updateCategory({ id, name, description }))
    .then((result) => {
      dispatch(edit(false))
    })
    .catch((error) => {
      // Handle errors if needed
      console.error('Error:', error)
    })
  
  }

  const handleSearchCat = (e) => {
    setSearchCat(e.target.value)
    dispatch(sortBySearch(e.target.value))
  }

  useEffect(() => {
    dispatch(sortBySearch(''))
  }, [dispatch])

  if (data?.length < 1) return <EmptyCategory category={true} />

  return (
    <CatWrapperStyle>
      <>
        <TableContainer>
          <div className="initialSort">
            <SearchWrapper>
              <div className="search-input">
                <img src={searchIcon} alt="search Icon" />
                <input
                  type="text"
                  value={searchCat}
                  onChange={handleSearchCat}
                  placeholder="Search..."
                />
              </div>
              <div className="tableHeader">
                <div className="pagination">
                  <span>Show</span>
                  <TablePagination
                    rowsPerPageOptions={[2, 4, 6]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage=""
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                  <p>entries</p>
                </div>
                <div className="grid-view active">
                  <img src={menuPink} alt="format List" className="grid-img" />
                </div>
              </div>
            </SearchWrapper>
          </div>
          <CatTableStyle>
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <>
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          top: 57,
                          minWidth: column.minWidth,
                          width: column.minWidth,
                        }}
                      >
                        {isLoading ? (
                          <div className="table-header-skeleton  skeleton"></div>
                        ) : (
                          column.label
                        )}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.length === 0 && (
                  <div>There are currently no category available</div>
                )}
                {filteredCategories?.length === 0 ? (
                  <div className="empty-search">
                    <p>
                      There are currently no category available for your search
                    </p>
                  </div>
                ) : (
                  case1?.map((row) => {
                    return (
                      <>
                        {isLoading ? (
                          <TableRow hover>
                            <TableCell>
                              <div className="table-header-skeleton  skeleton"></div>
                            </TableCell>
                            <TableCell>
                              <div className="table-header-skeleton  skeleton"></div>
                            </TableCell>
                            <TableCell>
                              <div className="table-header-skeleton  skeleton"></div>
                            </TableCell>
                            <TableCell className="menu-dot">
                              <NiyoNotificationModal
                                Content={Actions}
                                id={row?.id}
                                url={'category'}
                              >
                                <div className="table-header-skeleton  skeleton"></div>
                              </NiyoNotificationModal>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow hover>
                            <TableCell>{row?.createdAt.slice(0, 10)}</TableCell>
                            <TableCell>{row?.name}</TableCell>
                            <TableCell>{row?.description || '-----'}</TableCell>
                            <TableCell className="menu-dot">
                              <NiyoNotificationModal
                                Content={Actions}
                                id={row?.id}
                                url={'category'}
                              >
                                <img src={dots} alt="dot" />
                              </NiyoNotificationModal>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )
                  })
                )}
              </TableBody>
            </Table>
          </CatTableStyle>
        </TableContainer>

        <div className="footer">
          {isLoading ? (
            <div className="pagination-skeleton skeleton"></div>
          ) : (
            <TablePagination
              rowsPerPageOptions={[2, 4, 6]}
              component="div"
              count={latestData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>

        <CustomModal
          centered
          closable={false}
          visible={modalStatus}
          onCancel={handleCancel}
          footer={null}
          width={563}
        >
          <CategoryModal>
            <div>
              <h3>Edit Hair Category</h3>
            </div>
            <form className="category-form" >
              <div>
                <label>Hair Category*</label>
                <InputField
                  value={name}
                  onChange={handleCategory}
                  type="text"
                  placeholder="Wigs"
                />
              </div>
              <div>
                <label>Description (optional)</label>
                <TextAreaField
                  name=""
                  value={description}
                  onChange={handleDescription}
                  placeholder="Write a description"
                  rows="2"
                ></TextAreaField>
              </div>
              <div className="btn">
                <Btn
                  padding={'8px 40px'}
                  rounded={'5px'}
                  bg="black"
                  onClick={handleEdit}
                >
                  {loading ? <Spin /> : 'Publish'}
                </Btn>
              </div>
            </form>
            <div className="cancel" onClick={handleCancel}>
              <img src={closeIcon} alt="closeIcon" />
            </div>
          </CategoryModal>
        </CustomModal>
      </>
    </CatWrapperStyle>
  )
}
export default Category
