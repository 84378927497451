import adminMoneyIcon from './assets/icon/moneys.svg'
import adminUserIcon from './assets/icon/userDark.svg'
import adminPeopleIcon from './assets/icon/referral.svg'
import adminBoxIcon from './assets/icon/box.svg'

export const adminInfo = [
  {
    type: 'Revenue',
    number: '0.00',
    icon: adminMoneyIcon,
  },
  {
    type: 'Users',
    number: '000',
    icon: adminUserIcon,
  },
  {
    type: 'Referrals',
    number: '000',
    icon: adminPeopleIcon,
  },
  {
    type: 'Total Bookings',
    number: '000',
    icon: adminBoxIcon,
  },
]

// role
export const rolePermissions = [
  {
    title: 'Metrics',
    options: [{ label: 'View Performance Metrics' }],
  },
  {
    title: 'Messages',
    options: [{ label: 'View Messages' }, { label: 'Send Messages' }],
  },
  {
    title: 'Users',
    options: [{ label: 'View Users' }, { label: 'Manage Users' }],
  },
  {
    title: 'Admins',
    options: [{ label: 'View Admins' }, { label: 'Manage Admins' }],
  },
]

// booking
export const BookingPreHeading = [
  {
    title: 'Select Size',
    description: 'Choose the size of the hairstyle you selected',
  },
  {
    title: 'Scalp Tenderness',
    description: 'Choose your scalp tendnerness',
  },
  {
    title: 'Natural Hair Length',
    description: 'Choose the length of your natural hair ?',
  },
  {
    title: 'Chattiness',
    description:
      'Choose an interaction level your looking for with your stylist',
  },
  {
    title: 'Refreshments',
    description: 'Choose your referred drinks',
  },
]

export const bookingPreOptions = [
  [{ text: 'Thin' }, { text: 'Small' }, { text: 'Medium' }, { text: 'Jumbo' }],
  [{ text: 'Sensitive' }, { text: 'Not Sensitive' }],
  [
    { text: 'Short' },
    { text: 'Shoulder Length' },
    { text: 'Chin Length' },
    { text: 'Long' },
  ],
  [
    { text: 'Chat Away' },
    { text: 'Just a skosk' },
    { text: 'Causual Chat' },
    { text: 'No chatting at all' },
  ],
  [{ text: 'Lemonade ' }, { text: 'Water' }],
]

export const bookingsInfo = [
  {
    type: 'Total Bookings',
    number: '100',
  },
  {
    type: 'Pending Bookings',
    number: '10',
  },
  {
    type: 'Cancelled Bookings',
    number: '20',
  },
]

export const bookingDetailStatus = [
  {
    type: 'Date',
    text: '03-08-23',
  },
  {
    type: 'Hairstyle Selected',
    text: 'Twists',
  },
  {
    type: 'Payment Type',
    text: 'Debit Card',
  },
  {
    type: 'Booking ID',
    text: 'NHB001',
  },
  {
    type: 'Duration',
    text: '60min',
  },
  {
    type: 'Payment',
    text: 'completed',
  },
]

export const bookingDetailUnits = [
  {
    type: 'Length',
    info: 'Long',
    text: '£25.00',
  },
  {
    type: 'Volume',
    info: 'Medium',
    text: '£25.00',
  },
  {
    type: 'Volume',
    info: 'Medium',
    text: '£25.00',
  },
  {
    type: 'Root',
    info: 'Knotless',
    text: '£25.00',
  },
  {
    type: 'Finish Type',
    info: 'Curly',
    text: '£25.00',
  },
  {
    type: 'Scalp Tenderness',
    info: 'Sensitive',
    text: '£25.00',
  },
]

export const bookingPreferenceSize = [
  {
    text: 'Thin',
  },
  {
    text: 'Small',
  },
  {
    text: 'Medium',
  },
  {
    text: 'Jumbo',
  },
]

export const customTime = [
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
]
