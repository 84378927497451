import React, { useState, useEffect } from 'react'
import { ReferralProgramStyle } from './Style'
import { TableContainer, TablePagination } from '@mui/material'
import { CatWrapperStyle } from '../category/styles'
import { SearchWrapper } from '../../styles/components'
import { ReactComponent as ReferralProgramIcon } from '../../assets/icon/referralStat-program.svg'
import dropDown from "../../assets/icon/down.svg";
import { ReactComponent as ReferralPeopleIcon } from '../../assets/icon/referralStat-people.svg'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  CatTableStyle,
  CategoryModal,
} from '../category/styles/index'
import {
  CustomModal,
} from '../../styles/components'
import { Spin } from 'antd'
import { useNavigate, Link } from 'react-router-dom'
import { referral, updateReferProgram, userReferred } from '../../api/referall'
import { useDispatch, useSelector } from 'react-redux'
import searchIcon from '../../assets/icon/search.svg'
import closeIcon from '../../assets/icon/Close Square.svg'
import menuPink from '../../assets/icon/menuPinkIcon.svg'
import NiyoNotificationModal from '../../components/modal'
import Actions from '../../constants/actions'
import { InputField, Button, InputStyle } from '../../styles/components'
import dots from '../../assets/icon/dots-horizontal (1).svg'
import { editRefer, sortBySearch } from '../../redux/reducers/referralReducer'
import { toast } from 'react-hot-toast'

const columns = [
  { id: 'Program Type', label: 'Program Type', minWidth: 150 },
  { id: 'Program Name', label: 'Program Name', minWidth: 150 },
  { id: 'Discount', label: 'Percentage', minWidth: 150 },
  { id: 'Date Created', label: 'Date Created', minWidth: 150 },
  // { id: 'Status', label: 'Status', minWidth: 150 },
  { id: 'Action', label: 'Action', minWidth: 50 },
]

const ReferralProgram = () => {
  const {
    modalStatus,
    loading,
    searchedReferall,
    data,
    error,
    errorMsg,
    referall,
    usersRefer,
  } = useSelector((state) => state.referral)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [page, setPage] = useState(0)
  const [title, setTitle] = useState()
  const [discount, setDiscount] = useState()
  const [referProgramType, setReferProgramType] = useState()
  const [searchRef, setSearchRef] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(6)

  useEffect(() => {
    function fetchData() {
      dispatch(referral())
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    function fetchData() {
      dispatch(userReferred())
    }
    fetchData()
  }, [dispatch])

  const filteredReferalls = data?.filter((cat) => {
    return (
      cat?.title?.toLowerCase().indexOf(searchedReferall?.toLowerCase()) !== -1
    )
  })

  const latestData =
    filteredReferalls &&
    filteredReferalls
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) }
      })
      .sort((a, b) => b?.date - a?.date)

      const case1 =
      searchRef === ''
        ? latestData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : latestData
  
  useEffect(() => {
    if (error !== null && typeof error !== 'string') {
      error &&
        error?.map((err) => {
            return toast.error(`${err?.message}`)
        })
    } 
    // return toast.error(error)
  }, [error])

  useEffect(() => {
    if (errorMsg) {
      dispatch(referral())
      dispatch(userReferred())
      setTimeout(() => {
        toast.success('Successfully Updated referral')
      }, 1000)
    }
  }, [errorMsg, dispatch])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])


  useEffect(() => {
    if (referall) {
      setTitle(referall[0]?.title);
      setDiscount(referall[0]?.discount);
      setReferProgramType(referall[0]?.type)
    }
  }, [dispatch, referall]);

  const handleCancel = () => {
    dispatch(editRefer(false))
  }

  const handleName = (e) => {
    setTitle(e.target.value)
  }

  const handleDiscount = (e) => {
    const val = e.target.value.replace(/\D/g, '');
    setDiscount(Number(val))
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const handleSubmit = () => {
      const id = referall[0].id;
    if (title) {
        dispatch(updateReferProgram({ id, title }))
          .then((result) => {
            // Redirect upon successful completion
            dispatch(editRefer(false))
          })
          .catch((error) => {
            // Handle errors if needed
            console.error('Error:', error)
          })
      }
    if (discount) {
        dispatch(updateReferProgram({ id, discount }))
          .then((result) => {
            // Redirect upon successful completion
            dispatch(editRefer(false))
          })
          .catch((error) => {
            // Handle errors if needed
            console.error('Error:', error)
          })
      }
      editRefer(false);
  }


  const handleSearchRef = (e) => {
    setSearchRef(e.target.value)
    dispatch(sortBySearch(e.target.value))
  }

  useEffect(() => {
    dispatch(sortBySearch(''))
  }, [dispatch])

  // if (data?.length < 1) return <EmptyCategory />;

  return (
    <ReferralProgramStyle>
      <div className="referral-stat">
        <div>
          <div>
            <ReferralProgramIcon />
            <h3>No of Referral Programs</h3>
          </div>
          <h1>{data?.length}</h1>
        </div>
        <Link to="/referral/user">
          <div>
            <div>
              <ReferralPeopleIcon />
              <h3>Total Users Referred</h3>
            </div>
            <h1>{usersRefer?.length}</h1>
          </div>
        </Link>
      </div>

      <CatWrapperStyle>
        <TableContainer>
          <div className="initialSort">
            <SearchWrapper>
              <div className="search-input">
                <img src={searchIcon} alt="search Icon" />
                <input
                  type="text"
                  value={searchRef}
                  onChange={handleSearchRef}
                  placeholder="Search..."
                />
              </div>
              <div className="tableHeader">
                <div className="pagination">
                  <span>Show</span>
                  <TablePagination
                    rowsPerPageOptions={[2, 4, 6]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage=""
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />

                  <p>entries</p>
                </div>
                <div className="grid-view active">
                  <img src={menuPink} alt="format List" className="grid-img" />
                </div>
              </div>
            </SearchWrapper>
          </div>
          <CatTableStyle>
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        top: 57,
                        minWidth: column.minWidth,
                        width: column.minWidth,
                      }} // Add width style
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredReferalls?.length === 0 ? (
                  <div>There are currently no referral program available</div>
                ) : (
                  case1?.map((row) => {
                      return (
                        <TableRow hover>
                          <TableCell>{row?.type}</TableCell>
                          <TableCell>{row?.title}</TableCell>
                          <TableCell>{row?.discount}</TableCell>
                          <TableCell>{row?.createdAt.slice(0, 10)}</TableCell>
                          {/* <TableCell>{row?.createdAt.slice(0, 10)}</TableCell> */}
                          <TableCell className="menu-dot">
                            <NiyoNotificationModal
                              Content={Actions}
                              id={row?.id}
                              url={'referral'}
                              removeModal={true}
                            >
                              <img src={dots} alt="dot" />
                            </NiyoNotificationModal>
                          </TableCell>
                        </TableRow>
                      )
                    })
                )}
              </TableBody>
            </Table>
          </CatTableStyle>
        </TableContainer>

        <CustomModal
          centered
          closable={false}
          open={modalStatus}
          onCancel={handleCancel}
          footer={null}
          width={563}
          className="custom-modal"
        >
          <CategoryModal>
            <div>
              <h3>Edit Referral Program</h3>
            </div>
            <div className="category-form">
              <div>
                <label>Referral Program Name*</label>
                <InputField type="text" value={title} onChange={handleName} placeholder="NHB Discounts" />
              </div>
              <div>
                <div className="program-select">
                  <InputStyle>
                    <label>Referral Program Type</label>
                    <div className="select program">
                      <select name="" id="" value={referProgramType}>
                        <option value={referProgramType} >{referProgramType}</option>
                      </select>
                      <img src={dropDown} alt="dropDown" />
                    </div>
                  </InputStyle>
                </div>
              </div>
                <div>
                  <label>Discount Percentage Per Referral(min: 0.5, max: 1)</label>
                  <InputField type="text" value={discount} onChange={handleDiscount} placeholder="10%" />
                </div>
              <div className="btn">
                {/* <Link to="/referral/program"> */}
                  <Button
                    padding={'8px 40px'}
                    rounded={'5px'}
                  onClick={handleSubmit}
                  // className={`${
                  //   title === "" ||
                  //   discount === "" 
                  //     ? "disabled"
                  //     : ""
                  // }`}
                  >
                    {loading ? <Spin /> : 'Publish'}
                  </Button>
                {/* </Link> */}
              </div>
            </div>
            <div className="cancel" onClick={handleCancel}>
              <img src={closeIcon} alt="closeIcon" />
            </div>
          </CategoryModal>
        </CustomModal>
      </CatWrapperStyle>
      <div className="footer">
        <TablePagination
          rowsPerPageOptions={[2, 4, 6]}
          component="div"
          count={latestData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </ReferralProgramStyle>
  )
}

export default ReferralProgram
