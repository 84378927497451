import React from 'react'
import HairStyle from '../../../features/hairstyle/editHairstyle';
import HairStyleLayout from '../../../layout/HairStyleLayout';

const EditHairStylePage = () => {
  return (
    <HairStyleLayout>
        <HairStyle/>
    </HairStyleLayout>
  );
}

export default EditHairStylePage