import React, { useState } from "react";
import { HairStyleInfo, HairStyled } from "../features/hairstyle/Style";
import { Button } from "../styles/components";
import { useLocation } from "react-router-dom";

const ReferralLayout = ({ children }) => {
  const params = useLocation();
  const [dropDown, setDropDown] = useState(false);

  const checkprogram = params.pathname === "/referral/program";
  const checkuser = params.pathname === "/referral/user";

  const handleDropdown = () => {
    setDropDown(!dropDown);
  };

  return (
    <HairStyled>
      <HairStyleInfo checkprogram={checkprogram}>
        <div className="hairstyle-heading">
          <div className="heading">
            <h1>
              {checkuser && "User"} Referrals {checkprogram && "Programs"}
            </h1>
            <p className={`${(checkprogram || checkuser) && "program"}`}>
              Dashboard/<span>Referrals</span>
            </p>
          </div>

        </div>
        {checkprogram && (
          <div className="small-btn">
            <Button
              padding={"9.5px"}
              rounded="5px"
              font="10px"
              color="#A8A8A8"
              border="0.5px solid #B3B9C4"
              bg="transparent"
              weight="600"
              hover="#f1f1f2"
              onClick={handleDropdown}
            >
              <span>This Month</span>
            </Button>

            {dropDown && (
              <div className="dropdown">
                <p>Today</p>
                <p>This Week</p>
                <p>This Month</p>
              </div>
            )}
          </div>
        )}
        {children}
      </HairStyleInfo>
    </HairStyled>
  );
};

export default ReferralLayout;
