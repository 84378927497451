import React from "react";
import { Button } from "../../../styles/components";

const HairStyleEmpty = ({ img, text, btn, modal }) => {
  
  return (
    <div className="add-hairstyle">
      <div>
        <img src={img} alt="addHaistyle" />
        <p>{text}</p>
        {/* <Link to="/hairstyle/add"> */}
        <Button rounded={"8px"} padding={"8px 48px"} onClick={modal}>
          {btn}
        </Button>
        {/* </Link> */}
      </div>
    </div>
  );
};

export default HairStyleEmpty;
