import React, { useState, useEffect } from 'react'
import { Button, CustomModal, InputStyle } from '../styles/components'
import { RoleStyle } from '../features/roles/Style'
import profileIcon from '../assets/icon/ProfileIcon.svg'
import { Spin } from 'antd'
import isFormFilled from '../lib/isFormFilled'
import { CategoryModal } from '../features/category/styles/index'
import closeIcon from '../assets/icon/Close Square.svg'
import { InputField } from '../styles/components'
import { useSelector, useDispatch } from 'react-redux'
import dropDown from '../assets/icon/down.svg'
import successImg from '../assets/img/success.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as AddressIcon } from '../assets/icon/addressIcon.svg'
import user from '../assets/icon/avatar.jpeg'
import { ReactComponent as PhoneIcon } from '../assets/icon/callIcon.svg'
import leftArrow from '../assets/icon/Left.svg'
import { toast } from 'react-hot-toast'
import bitmap from '../assets/img/bitmap.svg'
import addProfile from '../assets/img/add-profile.svg'
import addCamera from '../assets/icon/camera.svg'
import { ReactComponent as EditIcon } from '../assets/icon/edit.svg'
import { ReactComponent as MessageIcon } from '../assets/icon/message.svg'
import { roles, createRole } from '../api/roles'
import { rolePermissions } from '../data'
import { editRole1, update } from '../redux/reducers/rolesReducer'

const RolesLayout = ({ children }) => {
  const [adminProcess, setAdminProcess] = useState('addAdmin')

  const [uploadedImage, setUploadedImage] = useState(null)

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const dataURL = reader.result
        setUploadedImage(dataURL)
      }
      reader.readAsDataURL(file)
    }
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, data, error, errorStatus, modalStatus1 } = useSelector(
    (state) => state.roles,
  )

  const stylist = data?.filter((item) => item.role === 'hairstylist').length

  const admin = data?.filter((item) => item.role === 'admin').length

  const superAdmin = data?.filter((item) => item.role === 'super-admin').length

  const rolesInfo = [
    {
      label: 'Hairstylist',
      no: `${loading || stylist === undefined ? '-' : stylist}`,
    },
    {
      label: 'Admin',
      no: `${loading || admin === undefined ? '-' : admin}`,
    },
    {
      label: 'Super Admin',
      no: `${loading || superAdmin === undefined ? '-' : superAdmin}`,
    },
  ]

  useEffect(() => {
    function fetchData() {
      dispatch(roles())
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    if (error !== null && typeof error !== 'string') {
      error &&
        error?.map((err) => {
          return toast.error(`${err?.name}${err?.message}`)
        })
    }
  }, [error])

  useEffect(() => {
    if (errorStatus === 'null') {
      setAdminProcess('added')
    }
  }, [errorStatus])

  const { pathname } = useLocation()

  const [rolesData, setRolesData] = useState({
    email: '',
    role: '',
    firstName: '',
    lastName: '',
    password: '',
  })
  const [files, setFiles] = useState(null)
  const [avatar, setAvatar] = useState(null)


  const addRoleForm1 = {
    email: rolesData?.email,
    role: rolesData?.role,
  }
  const addRoleForm2 = {
    files: avatar,
    firstName: rolesData?.firstName,
    lastName: rolesData?.lastName,
  }

  const handleForm = (e) => {
    const { value, name } = e.target
    setRolesData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = () => {
    let form_data = new FormData()
    if (files) {
      form_data.append('photo', files)
    }
    form_data.append('firstName', rolesData?.firstName)
    form_data.append('lastName', rolesData?.lastName)
    form_data.append('email', rolesData?.email)
    form_data.append('password', rolesData?.password)
    form_data.append('role', rolesData?.role)
    dispatch(createRole(form_data))
    update({ firstName: rolesData?.firstName, lastName: rolesData?.lastName, email: rolesData?.email, password: rolesData?.password, role: rolesData?.role})
  }
  const handleOpen = () => {
    dispatch(editRole1(true))
  }

  const handleAvatarClick = () => {
    document.getElementById('avatarInput').click()
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file && file.size <= 1024 * 1024) {
      // Max size of 1MB

      const reader = new FileReader()

      reader.onload = () => {
        setAvatar(reader.result)
      }

      reader.readAsDataURL(file)
        setFiles(file)

    } else {
      toast.error('file exceeds limits of 1mb')
    }
  }

  const handleCancel = () => {
    dispatch(editRole1(false))
    setAdminProcess('addAdmin')
    setRolesData({
      email: '',
      role: '',
      firstName: '',
      lastName: '',
      password: '',
    })
    setAvatar(null)
    if (adminProcess === 'added') {
      dispatch(roles())
    }
  }

  return (
    <RoleStyle>
      {pathname === '/roles/edit' ? (
        <div className="roles-edit">
          <div>
            <h4>Admin</h4>
            <Link to="/roles">
              <p className="flex">
                <img src={leftArrow} alt="leftArrow" />{' '}
                <span>Back to Admins</span>
              </p>
            </Link>
          </div>

          <div className="role-profile">
            <div className="img">
              <img src={bitmap} alt="bitmap" />
            </div>
            <div className="text">
              <h3>Homer Simpson</h3>
              <div className="tag">
                <span>Customer Support</span>
              </div>
              <p className="street">
                39 Hurst Street The Arcadian,Birmingham, England
              </p>
              <div className="flex contact">
                <div className="flex">
                  <AddressIcon />
                  <span>homersimpson@gmail.com</span>
                </div>
                <div className="flex">
                  <PhoneIcon />
                  <span>+44 7570382777</span>
                </div>
              </div>
            </div>
            <div className="edit flex">
              <Button
                bg="inherit"
                color="#000"
                padding="8px 16px"
                border="1px solid #E3E6E9"
                rounded="8px"
                flex={true}
              >
                <span>Message</span>
                <MessageIcon />
              </Button>
              <Button
                bg="inherit"
                color="#000"
                padding="8px 16px"
                border="1px solid #E3E6E9"
                rounded="8px"
                flex={true}
                onClick={handleOpen}
              >
                <span>Edit</span>
                <EditIcon />
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="roles-heading">
            <div className="heading">
              <h1>Roles & Permissions</h1>
              <p>
                Dashboard/<span>Admins</span>
              </p>
            </div>

            <div className="btn">
              <Button
                padding="8px 40px"
                rounded="5px"
                bg="#1D1F25"
                color="#FFFDFA"
                weight="600"
                onClick={() => handleOpen()}
              >
                <span>Add Admin</span>
              </Button>
            </div>
          </div>
          {!(data?.length < 1) && (
            <>
              <div className="role-stat">
                {rolesInfo.map((role) => (
                  <div key={role.label}>
                    <div className="img">
                      <img src={profileIcon} alt="profile Icon " />
                    </div>
                    <div>
                      <h4>{role.label}</h4>
                      <h1>{role.no}</h1>
                    </div>
                  </div>
                ))}
              </div>
              <div className="admin-heading">
                <h3>Admins</h3>
              </div>
            </>
          )}
        </>
      )}

      <div>{children}</div>

      <CustomModal
        centered
        closable={false}
        open={modalStatus1}
        onCancel={handleCancel}
        footer={null}
        width={pathname === '/roles' ? 563 : 760}
      >
        <CategoryModal adminprocess={adminProcess}>
          {pathname === '/roles' ? (
            <>
              {adminProcess === 'addAdmin' ? (
                <>
                  <div className="heading">
                    <h3>Add Admin</h3>
                  </div>
                  <div className="category-form">
                    <div>
                      <label>Email Address</label>
                      <InputField
                        name="email"
                        type="email"
                        value={rolesData.email}
                        onChange={handleForm}
                        placeholder="Wigs"
                      />
                    </div>
                    <div className="program-select">
                      <InputStyle>
                        <label>Role</label>
                        <div className="select program" value={rolesData.role}>
                          <select name="role" id="" onChange={handleForm}>
                            <option value="">Select option </option>
                            <option value="hairstylist">Hairstylist</option>
                            <option value="admin">Admin</option>
                            <option value="super-admin">Super Admin</option>
                          </select>
                          <img src={dropDown} alt="dropDown" />
                        </div>
                      </InputStyle>
                    </div>
                    <div className="btn">
                      <Button
                        padding={'8px 40px'}
                        rounded={'5px'}
                        onClick={() => setAdminProcess('details')}
                        className={isFormFilled(addRoleForm1) ? 'disabled' : ''}
                      >
                        {loading ? <Spin /> : 'Next'}
                      </Button>
                    </div>
                  </div>
                </>
              ) : adminProcess === 'details' ? (
                <>
                  <div className="heading">
                    <h3>Detail</h3>
                    <p>Add details for this Admin</p>
                  </div>
                  <div className="category-form">
                    <div>
                      {avatar ? (
                        <img
                          src={avatar}
                          alt="Avatar"
                          style={{
                            borderRadius: '50%',
                            width: '100px',
                            height: '100px',
                          }}
                          onClick={handleAvatarClick}
                        />
                      ) : (
                        <label
                          htmlFor="avatarInput"
                          style={{
                            display: 'block',
                            width: '70px',
                            height: '70px',
                            background: '#ccc',
                            borderRadius: '50%',
                            cursor: 'pointer',
                            overflow: 'hidden',
                          }}
                        >
                          <img
                            src={user} 
                            alt="Default Avatar"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                          <input
                            type="file"
                            id="avatarInput"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            multiple={false} 
                          />
                        </label>
                      )}

                    </div>

                    <div>
                      <label>First Name</label>
                      <InputField
                        name="firstName"
                        type="text"
                        value={rolesData.firstName}
                        onChange={handleForm}
                        placeholder="Lisa"
                      />
                    </div>
                    <div>
                      <label>Last Name</label>
                      <InputField
                        name="lastName"
                        type="text"
                        value={rolesData.lastName}
                        onChange={handleForm}
                        placeholder="Simon"
                      />
                    </div>
                    <div>
                      <label>Password</label>
                      <InputField
                        name="password"
                        type="password"
                        value={rolesData.password}
                        onChange={handleForm}
                        placeholder="password"
                      />
                    </div>
                    <div className="btn">
                      <Button
                        padding={'8px 40px'}
                        rounded={'5px'}
                        onClick={handleSubmit}
                        className={
                          isFormFilled(addRoleForm2) ? 'disabled' : ''
                        }
                      >
                        {loading ? <Spin /> : 'Next'}
                      </Button>
                    </div>
                  </div>
                </>
              ) : adminProcess === 'added' ? (
                <div className="success">
                  <div>
                    <img src={successImg} alt="successImg" />
                  </div>
                  <div>
                    <h3>Admin Added !</h3>
                  </div>
                </div>
              ) : (
                ''
              )}
            </>
          ) : (
            <>
              <div className="category-form edit">
                <div className="flex">
                  <div>
                    <h3>Edit Member</h3>
                    <p>Edit details of this Admin</p>
                  </div>

                  <div className="img">
                    <label htmlFor="fileInput">
                      {uploadedImage ? (
                        <img src={uploadedImage} alt="Uploaded Profile" />
                      ) : (
                        <img src={addProfile} alt="addProfile" />
                      )}
                      <input
                        type="file"
                        name="firstName"
                        id="fileInput"
                        onChange={handleImageUpload}
                      />
                      <div>
                        <img src={addCamera} alt="addCamera" />
                      </div>
                    </label>
                  </div>
                </div>

                <div className="input-flex">
                  <div>
                    <div>
                      <label>First Name</label>
                      <InputField
                        name="email"
                        type="text"
                        value={rolesData.name}
                        onChange={handleForm}
                        placeholder="Lisa"
                      />
                    </div>
                    <div>
                      <label>Last Name</label>
                      <InputField
                        name="email"
                        type="text"
                        value={rolesData.name}
                        onChange={handleForm}
                        placeholder="Simon"
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <label>Email Address</label>
                      <InputField
                        name="email"
                        type="text"
                        value={rolesData.name}
                        onChange={handleForm}
                        placeholder="Wigs"
                      />
                    </div>
                    <div className="program-select">
                      <InputStyle>
                        <label>Role</label>
                        <div className="select program" value={rolesData.role}>
                          <select name="role" id="" onChange={handleForm}>
                            <option value="">Select option </option>
                            <option value="HAIRSTYLIST">Hairstylist</option>
                            <option value="MANAGER">Manager</option>
                            <option value="ADMIN">Admin</option>
                            <option value="SUPER_ADMIN">Super Admin</option>
                          </select>
                          <img src={dropDown} alt="dropDown" />
                        </div>
                      </InputStyle>
                    </div>
                  </div>
                </div>
              </div>
              <div className="category-form edit">
                <div className="permission">
                  <p>Permissions</p>
                </div>

                <div className="check-list">
                  {rolePermissions.map((permission, i) => (
                    <div key={i}>
                      <div>
                        <h2>{permission.title}</h2>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="btn">
                  <Button padding={'8px 40px'} rounded={'5px'}>
                    {loading ? <Spin /> : 'Save Changes'}
                  </Button>
                </div>
              </div>
            </>
          )}
          <div className="cancel" onClick={handleCancel}>
            <img src={closeIcon} alt="closeIcon" />
          </div>
        </CategoryModal>
      </CustomModal>
    </RoleStyle>
  )
}

export default RolesLayout
