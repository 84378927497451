import React, { useState } from "react";
import {
  Button,
  FormStyle,
  HeadingStyle,
  InputStyle,
} from "../../../../styles/components";
import { useDispatch } from "react-redux";
import { nextProcess } from "../../../../redux/reducers/forgotReducer";

const ForgotForm = () => {

  const dispatch = useDispatch();

  const [userEmail, setUserEmail] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setUserEmail({
      email: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(nextProcess("checkMail"));
  };
  
  return (
    <div>
      <HeadingStyle>
        <h4>Forgot Password</h4>
        <p>
          Enter your email address, a password reset link will be sent to the
          registered email address
        </p>
      </HeadingStyle>
      <FormStyle onSubmit={handleSubmit}>
        <InputStyle>
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            name="email"
            value={userEmail.email}
            onChange={handleChange}
            placeholder="enter email address"
            required
          />
        </InputStyle>

        <Button onClick={handleSubmit}>Reset</Button>
      </FormStyle>
    </div>
  );
};

export default ForgotForm;
