import React from "react";
import RolesLayout from "../../layout/RolesLayout";
import Roles from "../../features/roles";

const RolesPage = () => {
  return (
    <RolesLayout>
      <Roles />
    </RolesLayout>
  );
};

export default RolesPage;
