import { createSlice } from '@reduxjs/toolkit'
import {
  referral,
  updateReferProgram,
  userReferred,
} from '../../api/referall'

const initialState = {
  loading: false,
  data: null,
  usersRefer: null,
  modalStatus: false,
  error: null,
  errorMsg: false,
  success: false,
  searchedReferall: '',
  referall: null,
}

const referrallSlice = createSlice({
  name: 'referrall',
  initialState,
  reducers: {
    editRefer: (state, action) => {
      const payload = action.payload
      state.modalStatus = payload
    },
    sortBySearch: (state, action) => {
      const payload = action.payload
      state.searchedReferall = payload
    },
    getRefer: (state, action) => {
      const payload = action.payload
      const filter = state.data.filter((refer) => refer.id === payload)
      state.referall = filter
    },
  },
  extraReducers: {
    [referral.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [referral.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.errorMsg = false
    },
    [referral.rejected]: (state, action) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [userReferred.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [userReferred.fulfilled]: (state, action) => {
      state.loading = false
      state.usersRefer = action.payload
    },
    [userReferred.rejected]: (state, action) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [updateReferProgram.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [updateReferProgram.fulfilled]: (state, action) => {
      state.loading = false
      state.errorMsg = true
    },
    [updateReferProgram.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
     
    },
  },
})
export default referrallSlice.reducer

export const { editRefer, sortBySearch, getRefer } = referrallSlice.actions
