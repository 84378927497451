import { createSlice } from '@reduxjs/toolkit'
import {
  categories,
  getCategory,
  updateCategory,
  deleteCategory,
  createCategory,
} from '../../api/category'

const initialState = {
  loading: false,
  data: null,
  modalStatus: false,
  error: null,
  success: false,
  searchedCategory: '',
  category: null,
}

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    edit: (state, action) => {
      const payload = action.payload
      state.modalStatus = payload
    },
    sortBySearch: (state, action) => {
      const payload = action.payload
      state.searchedCategory = payload
    },
    getCat: (state, action) => {
      const payload = action.payload
      const filter = state.data.filter((cat) => cat.id === payload)
      state.category = filter
    },
  },
  extraReducers: {
    [categories.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [categories.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    [categories.rejected]: (state, action) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [createCategory.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [createCategory.fulfilled]: (state, action) => {
      state.loading = false
      state.category = action.payload
      state.error = 'null'
    },
    [createCategory.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [getCategory.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [getCategory.fulfilled]: (state, action) => {
      state.loading = false
      state.category = action.payload
      state.error = 'getCategory'
    },
    [getCategory.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [updateCategory.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [updateCategory.fulfilled]: (state, action) => {
      state.loading = false
      state.error = 'updateCategory'
    },
    [updateCategory.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [deleteCategory.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [deleteCategory.fulfilled]: (state, action) => {
      state.loading = false
      state.error = 'deleteCategory'
    },
    [deleteCategory.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})
export default categorySlice.reducer

export const { edit, sortBySearch, getCat } = categorySlice.actions
