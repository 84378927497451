import { createSlice } from "@reduxjs/toolkit";
import {
  hairstyle,
  hairstylePreference,
  createHairstylePreference,
  deleteHairstyle,
  createHairstyle,
  updateHairstyle,
  updateHairstylePreference,
  deleteHairstylePreference,
  publishHairstyle
} from '../../api/hairstyle'

const initialState = {
  loading: false,
  data: null,
  modalStatus: false,
  error: null,
  success: false,
  searchedHairstyle: '',
  hairstyles: [],
  hairstylePre: null,
  publish: null,
  hairstylesPreference: null,
  hairstyleName: "",
  hairstyleCat: "",
};

const hairstyleSlice = createSlice({
  name: "hairstyle",
  initialState,
  reducers: {
    edit: (state) => {
      state.modalStatus = true
    },
    edit2: (state) => {
      state.modalStatus = false
    },
    sortBySearch: (state, action) => {
      const payload = action.payload;
      state.searchedHairstyle = payload;
    },
    getHairstyle: (state, action) => {
      const storedData = localStorage.getItem('hairstyle')
      const hairstyles1 = JSON.parse(storedData)
      const payload = action.payload
      const filter = state.data?.filter((cat) => cat.id === payload)
      const filter2 = state.data?.filter((cat) => cat.id === payload)
      state.hairstyles = filter
      localStorage.setItem('hairstyles', JSON.stringify(filter2[0]))
    },
    getHairPreference: (state, action) => {
      const payload = action.payload
      const filter = state.hairstylePre?.filter((cat) => cat.id === payload)
      state.hairstylesPreference = filter
    },
    setHairstyleName: (state, action) => {
      const payload = action.payload;
      state.hairstyleName = payload;
    },
    setHairstyleCat: (state, action) => {
      const payload = action.payload;
      state.hairstyleCat = payload;
    },
  },
  extraReducers: {
    [hairstyle.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [hairstyle.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
      localStorage.setItem('hairstyle', JSON.stringify(action.payload))
    },
    [hairstyle.rejected]: (state, action) => {
      state.loading = false;
      state.error = "BadNetwork";
    },
    [createHairstyle.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [createHairstyle.fulfilled]: (state, action) => {
      state.loading = false;
      state.hairstyles = action.payload;
      state.error = "null";
    },
    [createHairstyle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updateHairstyle.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [updateHairstyle.fulfilled]: (state, action) => {
      state.loading = false
      state.hairstyles = action.payload
      state.error = 'updateHairstyle'
    },
    [updateHairstyle.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [hairstylePreference.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [hairstylePreference.fulfilled]: (state, action) => {
      state.loading = false
      state.hairstylePre = action.payload
      state.error = 'hairstylePreference'
      localStorage.setItem('hairstylePre', JSON.stringify(action.payload))
    },
    [hairstylePreference.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [createHairstylePreference.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [createHairstylePreference.fulfilled]: (state, action) => {
      state.loading = false;
      state.hairstylePre = action.payload;
      state.data = action.payload;
      state.error = "createHairstylePreference";
    },
    [createHairstylePreference.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updateHairstylePreference.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [updateHairstylePreference.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = 'updateHairstylePreference'
    },
    [updateHairstylePreference.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [publishHairstyle.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [publishHairstyle.fulfilled]: (state, action) => {
      state.loading = false
      state.publish = 'publishHairstyle'
    },
    [publishHairstyle.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [deleteHairstyle.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [deleteHairstyle.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "deleteHairstyle";
    },
    [deleteHairstyle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [deleteHairstylePreference.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [deleteHairstylePreference.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "deleteHairstylePreference";
    },
    [deleteHairstylePreference.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export default hairstyleSlice.reducer;

export const {
  edit,
  edit2,
  sortBySearch,
  getHairPreference,
  getHairstyle,
  setHairstyleName,
  setHairstyleCat,
} = hairstyleSlice.actions;
