import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector  } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd'
import { updateAdminPassword } from '../api/roles'
import { Button, InputField } from '../styles/components'

export const PasswordTitle = () => {
  return (
    <Title>
      <p>Change Password</p>
    </Title>
  )
}

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p:first-child {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
  }
`

export const PasswordContent = ({ id, hide }) => {
  const [newPassword, setNewPassword] = useState('')

  const { passwordError, loading } = useSelector((state) => state.roles)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    if (passwordError === 'updateAdminPassword') {
      navigate(0)
    }
  }, [passwordError, navigate])

  const handleSubmitPassword = (e) => {
    e.preventDefault()
    dispatch(updateAdminPassword({ id:id[0]?.id, password: newPassword }))
    hide()
  }

  return (
    <Wrapper>
      <form>
        <div className='form'>
          <label>New Password</label>
          <InputField
             style={{ color: 'black' }}
            type="text"
            value={newPassword}
            placeholder="Mynewemail.com"
            onChange={({ target }) => {
              setNewPassword(target.value)
            }}
          />

          <Button
             onClick={handleSubmitPassword}
            padding="5px 8px"
            Radius="4px"
            className={`${
             newPassword === '' ? 'disabled' : ''
            }`}
          >
            {loading ? <Spin /> : 'Submit'}
          </Button>
        </div>
      </form>
    </Wrapper>
  )
}
export const Wrapper = styled.div`
.form {
  padding: 30px !important;
}
  width: 500px;
  button {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .disabled {
    pointer-events: none;
  }
`
