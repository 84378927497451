import { styled } from 'styled-components'

export const DashboardStyle = styled.div`
  padding: 32px 50px 53px;
`

export const WelcomeModal = styled.div``

export const WelcomeAdminStyle = styled.div`
  padding: 40px 0 58px;
  display: flex;
  gap: 22px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p {
    font-size: 18px;
    margin: 8px 0 2px;
    color: #a8a8a8;
    padding: 0 120px;
  }
  > div:nth-child(3) {
    width: 50%;
  }
`

export const AdminInfo = styled.div`
  .heading {
    display: flex;
    justify-content: space-between;
    h1 {
      color: #2c2929;
    }
    p {
      font-size: 14px;
      color: #858585;
    }
  }
`

export const AdminInfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-top: 24px;
  .box {
    // flex: 1;
    background-color: white;
    padding: 33px 12px 56px 24px;
    width: 24% !important;
    border-radius: 16px;
    display: flex;
    align-items: start;
    h1 {
      color: #505050;
    }
    > div:nth-child(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 11px;
      border-radius: 50%;
      margin-right: 13px;
      background-color: #ffedfa;
      img {
        width: 24px;
        height: 24px;
      }
    }
    > div:nth-child(2) {
      padding: 8px 0 0 0;
      span {
        margin: 0 3px;
      }
    }
  }
`

export const AdminBooking = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin: 40px 0;
  .nodata {
    padding: 63px 0;
    text-align: center;
  }
  .data {
    padding: -20px 0;
    text-align: center;
  }
  > div {
    // flex: 2;
    width: 50%;
    background-color: white;
    padding: 24px 16px;
    span {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: #757575;
    }
    > div:nth-child(1) {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      button {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 8px 16px;
        background-color: transparent;
        border-radius: 6px;
        border: 1px solid #25712d;
        span {
          font-style: italic;
          font-weight: 500;
          color: #2b8233;
          font-size: 10px;
        }
        &.booking2 {
          border: 1px solid #000;
          border-radius: 5px;
          color: #a8a8a8;
          border: 0.5px solid #b3b9c4;
        }
      }
    }
  }
`

export const RecentBooking = styled.div`
  > div:nth-child(1) {
    padding-bottom: 24px;
  }

  > div:nth-child(2) {
    > div:nth-child(1) {
      gap: 180px;
      input {
        border-radius: 8px;
      }
      p {
        color: #858585;
        font-size: 14px;
      }
      select {
        border: 1px solid #e6e8ea9e;
        background: #fff;
        padding: 9.5px 12px;
        color: #858585;
      }
    }

    background-color: white;
    padding: 32px 36px;
    > div:nth-child(2) {
      padding: 149px 0 163px;
      text-align: center;
      p {
        color: #8993a4;
        font-size: 18px;
      }
    }
  }
`
