import axios from 'axios';
 
const client = axios.create();

const token = localStorage.getItem('userToken')
? localStorage.getItem('userToken')
: null;

if (token) {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export default client;
