import React from "react";
import Calendar from "../../features/calendar";
import CalendarLayout from "../../layout/CalendarLayout";

const CategoryPage = () => {
  return (
    <CalendarLayout>
      <Calendar />
    </CalendarLayout>
  );
};

export default CategoryPage;
