import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'


export const referral = createAsyncThunk(
  'referral/fetchReferral',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/referral-program`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)

export const userReferred = createAsyncThunk(
  'referral/fetchUserReferred',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/referral`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const updateReferProgram = createAsyncThunk(
  'referral/updateReferall',
  async (
    { id, title, discount },
    { rejectWithValue },
  ) => {
    try {
      if (title) {
        const {
          data: { data },
        } = await client.patch(`${BASE_URL}/api/v1/referral-program/${id}`, {
          title,
        })
        return data
      }
      if (discount) {
        const {
          data: { data },
        } = await client.patch(`${BASE_URL}/api/v1/referral-program/${id}`, {
          discount,
        })
        return data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

// export const createHairstyle = createAsyncThunk(
//   'hairstyle/createhairstyle',
//   async ( form_data , { rejectWithValue }) => {
//       try {
//         const config = {
//             headers: {
//               'Content-Type': 'multipart/form-data',
//             },
//           }
//       const { data:{ data } } = await client.post(
//           `${BASE_URL}/api/v1/hairstyles`,
//           form_data ,
//           config,
//       )
//       return data
//     } catch (error) {
//       // return custom error message from API if any
//       if (error.response && error.response.data.message) {
//         return rejectWithValue(error.response.data.message)
//       } else {
//         return rejectWithValue(error.message)
//       }
//     }
//   }
// )

// export const createHairstylePreference = createAsyncThunk(
//   'hairstyle/createhairstylePreference',
//   async ({ preferences, id }, { rejectWithValue }) => {
//     try {
//       const { data:{ data } } = await client.post(
//           `${BASE_URL}/api/v1/hairstyles/${id}/preferences`,
//           preferences
//       )
//       return data
//     } catch (error) {
//       // return custom error message from API if any
//       if (error.response && error.response.data.message) {
//         return rejectWithValue(error.response.data.message)
//       } else {
//         return rejectWithValue(error.message)
//       }
//     }
//   }
// )

