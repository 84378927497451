import React from 'react'
import addHaistyle from '../../../assets/img/add-hairstyle.svg'
import categoryImg from '../../../assets/img/category-img.svg'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from '../../../styles/components'
import { Link } from 'react-router-dom'
import {editRole} from '../../../redux/reducers/rolesReducer'

const EmptyCategory = ({ category, roles, hairstyle, booking }) => {
  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(editRole(true))
  }

  return (
    <>
      <div className="add-hairstyle">
        <div>
          {category ? (
            <img src={categoryImg} alt="categoryImg" />
          ) : (
            <img src={addHaistyle} alt="addHaistyle" />
          )}
          {category ? (
            <p>You have not created Categories yet</p>
          ) : roles ? (
            <p>You have not created Role yet</p>
          ) : booking ? (
            <p>You have no booking</p>
          ) : (
            <p>You have not created hairstyles yet</p>
          )}
          {hairstyle && (
            <Link to="/hairstyle/add">
              <Button rounded={'8px'} padding={'8px 48px'}>
                Add New Hairstyles
              </Button>
            </Link>
          )}
          {roles && (
              <Button rounded={'8px'} padding={'8px 48px'}  onClick={handleOpen}>
                Add New Role
              </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default EmptyCategory
