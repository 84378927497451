import React from "react";
import {
  Button,
  FormStyle,
  HeadingStyle,
  InputStyle,
} from "../../../../styles/components";

const UpdatePassword = () => {
  return (
    <div>
      <HeadingStyle>
        <h4>Choose a New Password</h4>
        <p>Create a strong password for this account</p>
      </HeadingStyle>
      <FormStyle>
        <InputStyle>
          <label htmlFor="newPassword">New password</label>
          <input
            type="newPassword"
            name="newPassword"
            placeholder="*****************"
            required
          />
        </InputStyle>
        <InputStyle>
          <label htmlFor="comfirmPassword">Confirm Password</label>
          <input
            type="comfirmPassword"
            name="comfirmPassword"
            placeholder="*****************"
            required
          />
        </InputStyle>
        <Button>Reset</Button>
      </FormStyle>
    </div>
  );
};

export default UpdatePassword;
