import React from "react";
import BookingsLayout from "../../layout/BookingsLayout";
import Bookings from "../../features/bookings";

const BookingsPage = () => {
  return (
    <BookingsLayout>
      <Bookings />
    </BookingsLayout>
  );
};

export default BookingsPage;
