import React, { useState } from "react";
import {
  HairStyleInfo,
  HairStyled,
} from "../features/hairstyle/Style";
import { Spin } from "antd";
import { CategoryModal } from "../features/category/styles/index";
import closeIcon from "../assets/icon/Close Square.svg";
import {
  Button,
  CustomModal,
  InputField,
  TextAreaField,
} from "../styles/components";
import { useDispatch, useSelector } from "react-redux";
import { createCategory } from "../api/category";

const CalendarLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state) => state.category
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const categoryField = {
    name: "",
    description: "",
  };

  const [categoryInfo, setCategoryInfo] = useState(categoryField);

  const handleForm = (e) => {
    const { value, name } = e.target;
    setCategoryInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
 
  const handleSubmit = async () => {
    await dispatch(createCategory(categoryInfo));
    setCategoryInfo(categoryField);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <HairStyled>
      <HairStyleInfo>
        <div className="hairstyle-heading">
          <div className="heading">
            <h1>Calendar</h1>
            <p>Dashboard/<span>Calendar</span></p>
          </div>

        </div>
        {children}
      </HairStyleInfo>
      <CustomModal
        centered
        closable={false}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={563}
      >
        <CategoryModal>
          <div>
            <h3>Add Hair Category</h3>
          </div>
          <div className="category-form">
            <div>
              <label>Hair Category*</label>
              <InputField
                name="name"
                type="text"
                value={categoryInfo.name}
                onChange={handleForm}
                placeholder="Wigs"
              />
            </div>
            <div>
              <label>Description (optional)</label>
              <TextAreaField
                name="description"
                value={categoryInfo.description}
                onChange={handleForm}
                placeholder="Write a description"
                rows="2"
              ></TextAreaField>
            </div>
            <div className="btn">
              <Button
                padding={"8px 40px"}
                rounded={"5px"}
                onClick={() => handleSubmit()}
                className={`${categoryInfo.name === "" ? "disabled" : ""}`}
              >
                {loading ? <Spin /> : "Publish"}
              </Button>
            </div>
          </div>
          <div className="cancel" onClick={handleCancel}>
            <img src={closeIcon} alt="closeIcon" />
          </div>
        </CategoryModal>
      </CustomModal>
    </HairStyled>
  );
};

export default CalendarLayout;
