import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import forgotReducer from "./reducers/forgotReducer";
import categoryReducer from "./reducers/categoryReducer";
import calendarReducer from "./reducers/calendarReducer";
import hairstyleReducer from "./reducers/hairstyleReducer";
import referralReducer from "./reducers/referralReducer";
import bookingReducer from "./reducers/bookingReducer";
import rolesReducer from "./reducers/rolesReducer";
import transactionReducer from "./reducers/transactionReducer";
import dashboardReducer from "./reducers/dashboardReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    category: categoryReducer,
    hairstyle: hairstyleReducer,
    forgotProcess: forgotReducer,
    referral: referralReducer,
    booking: bookingReducer,
    roles: rolesReducer,
    transaction: transactionReducer,
    dashboard: dashboardReducer,
    calendar: calendarReducer,
  },
});
