import React from 'react';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const options = {
  scales: {
    scaleOverride: true,
    y: [
      {
        ticks: {
          min: 0,
          max: 300,
          stepSize: 100,
        },
      },
    ],
  },
};

const NiyoLineChart = ({ labels, datasets, option, ...props }) => (
  <Line {...props} data={{ labels, datasets }} options={option} />
);


export default NiyoLineChart;
