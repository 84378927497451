import React, { useEffect } from "react";
import { Spin } from "antd";
import closeIcon from "../../../assets/icon/Close Square.svg";
import { useSelector } from "react-redux";
import {
  Button,
  CustomModal,
  TextAreaField,
  SelectField,
} from "../../../styles/components";
import { CategoryModal } from "../../category/styles";
import { useDispatch } from 'react-redux'
import { users } from '../../../api/booking';

const AssignModal = ({ isAssignOpen, setIsAssignOpen, setUserId, bookingId, handleSubmit }) => {
  const { loading, user } = useSelector((state) => state.booking);
  const dispatch = useDispatch()

  useEffect(() => {
    const run = () => {
      dispatch(users())
    }
    run()
  },[dispatch])


  const handleForm = (e) => {
    const { value } = e.target;
    setUserId(value)
  };

  return (
    <CustomModal
      centered
      closable={false}
      open={isAssignOpen}
      onCancel={() => setIsAssignOpen(false)}
      footer={null}
      width={563}
    >
      <CategoryModal booking={true}>
        <div>
          <h3>Assign Booking</h3>
        </div>
        <div className="category-form">
          <div>
          <SelectField
              name=""
              id=""
              onChange={handleForm}
              required
            >
              <option selected disabled value="">
              Assign to Admins and People"
              </option>
              {user?.map((per) => (
                <option value={per?.id}>{per?.firstName}{per?.lastName}</option>
              ))}
            </SelectField>
          </div>
          <div>
            <TextAreaField
              booking={true}
              name="message"
              // value={bookingId}
              // onChange={handleForm}
              placeholder="Message"
              rows="2"
            ></TextAreaField>
          </div>
          <div className="btn">
            <Button
              padding="8px 40px"
              rounded="5px"
              onClick={handleSubmit}
              // className={bookingId ? "disabled" : ""}
            >
              {loading ? <Spin /> : "Assign"}
            </Button>
          </div>
        </div>
        <div className="cancel" onClick={() => setIsAssignOpen(false)}>
          <img src={closeIcon} alt="closeIcon" />
        </div>
      </CategoryModal>
    </CustomModal>
  );
};

export default AssignModal;
