import React from 'react'
import { HairStyleInfo, HairStyled } from '../features/hairstyle/Style'
import { Link, useLocation } from 'react-router-dom'
import { Button } from '../styles/components'
import { ReactComponent as AddIcon } from '../assets/icon/add.svg'

const HairStyleLayout = ({ children }) => {
  const params = useLocation()

  const currentPath1 = params.pathname === '/hairstyle'
  const currentPath =
    params.pathname === '/hairstyle/preference' ||
    params.pathname === '/hairstyle/add'
  const currentPath2 = params.pathname.includes('/hairstyle/edit') || params.pathname.includes('/hairstyle/preference/')

  return (
    <HairStyled>
      <HairStyleInfo>
        <div className="hairstyle-heading">
          <div className="heading">
            <h1>hairstyles</h1>
            <p>
              {currentPath1 && (
                <span className="addhairstyle">
                  Dashboard/<span>Hairstyles</span>
                </span>
              )}
              {currentPath && (
                <span className="addhairstyle">
                  Dashboard/Hairstyles/<span>Add Hairstyles</span>
                </span>
              )}
              {currentPath2 && (
                <span className="addhairstyle">
                  Dashboard/Hairstyles/<span>Edit Hairstyles</span>
                </span>
              )}
            </p>
          </div>
          <div className="btn">
            {!currentPath && !currentPath2 && (
              <Link to="/hairstyle/add">
                <Button
                  padding="8px 16px"
                  rounded="5px"
                  bg={currentPath ? 'white' : 'black'}
                  color={currentPath ? 'black' : 'white'}
                  weight="600"
                  border="1px solid #1D1F25"
                >
                  {!currentPath && <AddIcon />}
                  <span>
                    {currentPath ? 'Save to draft' : 'Add New Hairstyle'}
                  </span>
                </Button>
              </Link>
            )}
          </div>
        </div>
        {children}
      </HairStyleInfo>
    </HairStyled>
  )
}

export default HairStyleLayout
