import React from 'react'
import bookingHairLogo from '../assets/icon/booking-hairstyle-logo.svg'
import { enableFilter } from '../redux/reducers/bookingReducer'
import { useDispatch } from 'react-redux'

const BookingsBox = ({ box }) => {
  const dispatch = useDispatch()
  return (
    <div className="box" onClick={() => dispatch(enableFilter(box.filterType))}>
      <img src={bookingHairLogo} alt="box" />
      <div className="text">
        <span>{box.type}</span>
        <h1>{box.number}</h1>
      </div>
    </div>
  )
}

export default BookingsBox
