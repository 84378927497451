import React, { useEffect, useState } from 'react'
import { BookingsListStyle } from './Style'
import { useDispatch, useSelector } from 'react-redux'
import EmptyCategory from '../category/component/emptyCat'
import searchIcon from '../../assets/icon/search.svg'
import menuPink from '../../assets/icon/format-list-bulleted (3).svg'
import { TablePagination } from '@mui/material'
import { sortBySearch, setPaymentStatus } from '../../redux/reducers/bookingReducer'
import { SearchWrapper } from '../../styles/components'
import BookingsList from './components/BookingsList'
import { bookings } from '../../api/booking'

const Bookings = () => {
  const { data } = useSelector((state) => state.booking)
  const dispatch = useDispatch()

  const [searchBook, setSearchBook] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(6)

  const [isLoading, setIsLoading] = useState(true)

  const handleSearchTeam = (e) => {
    setSearchBook(e.target.value)
    dispatch(sortBySearch(e.target.value))
  }
  useEffect(() => {
    function fetchData() {
      dispatch(bookings())
      setIsLoading(false)
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    function setStaus() {
      dispatch(setPaymentStatus())
    }
    setStaus()
  }, [dispatch])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const allBookings =
    data?.pendingBookings.concat(
      data?.completedBookings,
      data?.otherBookings,
    ) || []
  if (allBookings?.length < 1) return <EmptyCategory />

  return (
    <BookingsListStyle>
      <div className="initialSort">
        <SearchWrapper>
          <div className="search-input">
            <img src={searchIcon} alt="search Icon" />
            <input
              type="text"
              value={searchBook}
              onChange={handleSearchTeam}
              placeholder="Search..."
            />
          </div>
          <div className="tableHeader">
            <div className="pagination">
              <span>Show</span>
              <TablePagination
                rowsPerPageOptions={[2, 4, 6, 8, 10, 12, 14, 16, 18]}
                component="div"
                rowsPerPage={rowsPerPage}
                labelRowsPerPage=""
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <p>entries</p>
            </div>
            <div className="menu grid-view active">
              <img src={menuPink} alt="menu icon" className="list-img" />
            </div>
          </div>
        </SearchWrapper>
      </div>
      {isLoading ? (
        <div className="pagination-skeleton skeleton"></div>
      ) : (
        <BookingsList
          rowsPerPage={rowsPerPage}
          page={page}
            setPage={setPage}
            searchBook={searchBook}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </BookingsListStyle>
  )
}

export default Bookings
