import { styled } from "styled-components";

export const ReferralProgramStyle = styled.section`
  padding-bottom: 32px;
  .referral-stat {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 19px;
    margin-bottom: 72px;
    > div, a {
      display: flex;
      flex-direction: column;
      gap: 22px;
      flex: 1;
      background-color: white;
      border-radius: 16px;
      padding: 24px 24px 44px 24px;
      > div {
        display: flex;
        align-items: center;
        gap: 12px;
        h3 {
          font-weight: 400;
          font-family: rebound;
        }
      }
      h1 {
        color: #505050;
        font-size: 48px;
        font-weight: 600;
        padding-left: 67px;
      }
    }
  }
`;

export const SpecificUserStyle = styled.section`
  padding: 16px 50px 118px;
  .user-stat {
    margin: 48px 0 32px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    > div {
      background-color: #fff;
      border-radius: 16px;
      padding: 25px 15px 32px 24px;
      display: flex;
      gap: 24px;
      color: #505050;
      white-space: nowrap;
      h3 {
        color: #505050;
        font-size: 24px;
        font-weight: 400;
      }
      h1 {
        font-size: 48px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
      }
      .street {
        margin: 10px 0 0;
      }
      .flex {
        margin-bottom: 15px;
        gap: 24px;
        > div {
          display: flex;
          align-items: center;
          margin-top: 8px;
          gap: 8px;
          span {
            font-size: 12px;
          }
        }
      }
      .copy {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 5px;
      }
    }
    > div:nth-child(1) {
      width: 47%;
      padding-right: 40px;

      h1 {
        border-radius: 200px;
        background: #0d0d0d;
        padding: 19px 14px 19px 14px;
        color: #ffc2ef;
        font-size: 32px;
        font-weight: 700;
      }
      h3{
        color: #0F0E0E;
      }
      h5 {
        color: #000;
        font-size: 14px;
        font-weight: 400;
      }
      p,
      span {
        font-family: reboundLight;
        color: #606060;
      }
    }
    > div:nth-child(2) {
      width: 27%;
    }
    > div:nth-child(3) {
      width: 27%;
    }
    > div:nth-child(2),
    > div:nth-child(3) {
      display: flex;
      gap: 22px;
      flex-direction: column;
      padding: 24px 15px 0 24px;
      
      div {
        display: flex;
        align-items: center;
        gap: 12px;
        h3 {
          font-family: rebound;
          color: #191919;
        }
      }
      h1 {
        margin-left: 55px;
          color: #505050;

      }
    }
  }
  .heading {
    margin-bottom: 24px;
    h3{
      color: #2C2C2C;
    }
  }
`;
