import { styled } from 'styled-components'

export const CalenderWrapper = styled.div`
  width: 100% !important;
  height: 95vh !important;
  .rbc-calendar {
    width: 100% !important;
  }
  .rbc-toolbar {
    display: block !important;
    span {
      display: block !important;
      width: 60px !important;
    }
  }
  .rbc-btn-group {
    button {
      // outline: 3px solid red !important;
    }
  }
  .rbc-header {
    background: #79b8e4;
    color: white;
    padding: 10px;
    font-size: 12px;
  }

  .rbc-event {
    padding: 0px !important;
    border-radius: 0px !important;
    border: none !important;
    background-color: transparent !important;
    z-index: 2;
    position: relative;
  }

  .rbc-background-event {
    padding: 0px !important;
    border-radius: 0px !important;
    border: none !important;
    background-color: transparent !important;
  }

  .rbc-event-label {
    display: none !important;
  }

  .rbc-events-container {
    width: 100% !important;
  }

  .rbc-timeslot-group {
    min-height: 120px !important;
  }

  .rbc-day-slot .rbc-time-slot:after {
    content: '';
    width: 100%;
    border-top: 1px solid lightblue;
    display: block;
  }

  .rbc-time-slot {
    background: #eaf3fa;
    color: #74a4c3;
    z-index: 1;
  }

  .rbc-addons-dnd-resizable {
    height: 100%;
    width: 100%;
  }
`

export const Wrap = styled.div`
  .layout {
    border-radius: 16px;
    background: var(--white, #fff);
    padding: 20px;
  }
  h3 {
    color: var(--neutral-700, #404040);
    font-size: 24px;
    font-style: normal;
    font-weight: 200;
    line-height: 140%;
  }
  p {
    color: var(--neutral-500, #737373);
    font-size: 16px;
    margin-top: 10px;

    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .weekWrapper {
    margin-top: 15px;
  }
  .dayOfWeek {
    color: var(--neutral-600, #525252);
    font-size: 16px;
    text-transform: lowercase;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
  .dayOfWeek:first-letter {
    text-transform: capitalize !important;
  }
  .btnWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .btn {
    // width: 144px;
  }
  .flex {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
    justify-content: space-between;
  }
  .flex2 {
    display: flex;
    align-items: center;
    color: var(--neutral-600, #525252);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    img {
      cursor: pointer;
      // max-width: 100%;
      // width: 100%;
      margin-left: 20px;
    }
  }
  .switch {
    display: flex;
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
  .calendar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }

  .day-view th,
  .day-view td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc;
  }
  .day-view th,
  .day-view td .allocated {
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc;
  }
  table {
    thead {
      tbody {
        tr {
          td {
            border: 3px solid red !important;
            .allocated {
              background: green !important;
              border: 3px solid red !important;
            }
          }
        }
      }
    }
  }
`

export const CalendarModal = styled.div`
  border-radius: 8px;
  background-color: ${({ adminprocess }) => adminprocess && 'white'} !important;
  padding: 48px;
  .dayWrapper {
    display: flex;
    margin-bottom: 20px;
  }
  h3 {
    color: var(--neutral-700, #404040);
    // font-family: Stacion;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 10px;
  }
  p {
    color: var(--neutral-900, #171717);
    /* Paragraph/Small/Medium */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .day {
    display: flex;
    width: 40px;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 2000px;
    border: 1px solid var(--neutral-300, #d4d4d4);
    background: var(--neutral-50, #fafafa);
  }
  .selected {
    color: var(--rdr-pink-400-main, #ff63dc);
    background: var(--rdr-pink-50, #fef1fb);
    border: 1px solid var(--rdr-pink-50, #fef1fb);
  }
  .day:not(:first-child) {
    margin-left: 20px;
  }
  .cancel {
    position: absolute;
    top: -65px;
    right: -5px;
    padding: 2px;
    cursor: pointer;
    border-radius: 6px;
  }
  .heading {
    margin-bottom: 40px;
  }

  .select-input,
  .program-select {
    margin-bottom: 29px;
    select {
      background-color: transparent;
      padding: 18px 16px;
    }
  }
  .program-select {
    margin-bottom: 0;
  }
  .timePicker {
    display: flex;
    alignitems: center; // Align content vertically in the center
    width: 100%;
  }
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    min-width: 390px !important;
    max-width: 400px !important;
    // height: 200px !important;
  }
  .category-form {
    margin-bottom: 40px;
    display: flex;
    // flex-direction: column;
    gap: 24px;
    .title {
      color: #171717;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .img {
      width: 80px;
      img {
        height: 80px;
        width: 80px;
      }
      label {
        position: relative;
        cursor: pointer;

        img {
          border-radius: 100%;
        }
        div {
          position: absolute;
          left: 20px;
          top: -20px;
        }
      }
    }
    h2 {
      font-size: 16px;
      color: #191919;
      margin-bottom: 8px;
    }
  }

  .permission {
    padding: 10px;
    background-color: #fafafb;
    p {
      color: #505050;
    }
  }

  .btn {
    display: flex;
    width: 144px;
    justify-content: flex-end;
    // margin-right:
    float: right;
    align-self: end;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    p:first-child {
      color: var(--neutral-900, #171717);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    p:last-child {
      color: var(--neutral-500, #737373);
      font-family: Instrument Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 14px;
    div {
      text-align: center;
      h2 {
      }
    }
  }
`
