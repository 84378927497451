import React from "react";
import SpecificUser from "../../features/referrals/SpecificUser";

const SpecificUserPage = () => {
  return (
      <SpecificUser />
  );
};

export default SpecificUserPage;
