import { styled } from "styled-components";

export const BookingsStyle = styled.section`
  padding: 16px 43px 118px;
  margin-bottom: 24px;
  .booking-heading {
    display: flex;
    justify-content: space-between;
    margin: 14px 0 30px;
    .heading {
      h2 {
        font-size: 20px;
      }
      p {
        color: #505050;
        span {
          color: #ff46ce;
        }
      }
    }
    .btn {
      gap: 16px;
      /* justify-self: end; */
      display: flex;
    }
  }
`;

export const BookingInfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin: 36px 0 33px;
  .box {
    flex: 1;
    background-color: white;
    cursor: pointer;
    border-radius: 16px;
    padding: 16px 24px;
    display: flex;
    align-items: start;
    gap: 16px;
    > p {
      margin: 35px 0 0 40px;
    }
    .text {
      flex-grow: 1;
      span {
        margin: 0 3px;
      }
      h1 {
        color: #505050;
        margin: 8px 0 35px 0;
      }
      p {
        color: #25712d;
      }
    }
  }
`;

export const BookingsListStyle = styled.section`
  background-color: white;
  padding: 24px 16px 70px;
  border-radius: 8px;
`;

export const BookingTableStyle = styled.div`
  background-color: ${({ edit }) => (edit ? "white" : "transparent")};
  min-height: ${({ edit }) => (edit ? "" : "400px")};

  table {
    thead {
      margin-bottom: 32px !important;
      tr {
        .ant-table-cell {
          &:nth-child(1) {
            text-align: left;
          }
        }
        th {
          text-align: left;
          border-bottom: ${({ edit }) => (edit ? "" : "none")};
          padding-left: ${({ edit }) => (edit ? "16px" : "0")} !important;
        }
      }
    }
    tbody {
      tr {
        position: relative;
        &:hover {
          background-color: transparent !important;
        }
        .menu-dot {
          div {
            img {
              cursor: pointer;
            }
          }
        }
        td {
          text-align: left;
          text-transform: capitalize;
          color: #a8a8a8;
          font-weight: 400;
          font-size: 14px;
          padding: 18px 0 !important;
          padding-left: ${({ edit }) => (edit ? "18px" : "")} !important;
          border-bottom: ${({ edit }) => (edit ? "" : "none")};
          &:nth-child(1):has(img) {
            div {
              width: 100%;
              display: flex;
              align-items: center;
              gap: 8px;
              img {
                background-color: #ffd6f2;
                width: 40px;
                height: 40px;
                border-radius: 2000px;
              }
            }
          }
        }
        td:nth-child(4) {
          div {
            span {
              text-align: center;
              min-width: 118px;
              display: inline-block;
              padding: 4px 8px;
              background-color: #ffe6f8;
              color: #ff65d6;
              border-radius: 100px;
              font-size: 12px;
            }
          }
        }
      }
      .preference {
        text-decoration: underline;
      }
      .completed {
        span {
          margin-right: 20px;
          padding: 10px;
          color: #3dba49;
          background-color: #ecf8ed;
          border-radius: 10px;
        }
      }
      .awaiting {
        span {
          margin-right: 20px;
          padding: 10px;
          color: #FEB92B;
          background-color: #FFF7E6;
          border-radius: 10px;
        }
      }
      .part_paid {
        span {
          margin-right: 20px;
          padding: 10px;
          color: black;
          background-color: #FFC966;
          border-radius: 10px;
        }
      }
      .cancelled {
        span {
          margin-right: 20px;
          padding: 10px;
          color: #F23835;
          background-color: #FDE7E7;
          border-radius: 10px;
        }
      }
      .pending {
        span {
          margin-right: 20px;
          padding: 10px;
          color: #FFF;
          background-color: #000;
          border-radius: 10px;
        }
      }
      .refund {
        span {
          margin-right: 20px;
          padding: 10px;
          color: #000FB6;
          background-color: #EFF0FF;
          border-radius: 10px;
        }
      }
    }
  }
`;

export const ViewBookingModal = styled.div`
  position: relative;
  padding: ${({ newBooking }) => (newBooking ? 0 : "72px 40px")};
  .cancel {
    position: absolute;
    top: -65px;
    right: -5px;
    padding: 2px;
    cursor: pointer;
    border-radius: 6px;
  }
  .view-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    .btn {
      gap: 8px;
    }
  }
  .view-info {
    // display: flex;
    // justify-content: space-between;
    // align-items: start;
    // gap: 24px;
    margin-bottom: 40px;
    > div {
      padding: ${({ newBooking }) => (newBooking ? "" : "20px 32px 20px 24px")};
      flex: 1;
      border-radius: 8px;
    }
    .client {
      display: flex;
      align-items: start;
      gap: 24px;
      margin-bottom: 40px;
      background-color: #fff;
      > p {
        color: #3dba49;
      }
      .profile {
        p {
          border-radius: 200px;
          background: #0d0d0d;
          padding: 10px 11px;
          color: #ffc2ef;
          font-size: 14px;
          font-weight: 700;
        }
      }
      .detail {
        flex-grow: 1;
        p:nth-child(1) {
          font-size: 16px;
          color: #191919;
          font-weight: 700;
        }
        p {
          color: #a8a8a8;
          margin-bottom: 8px;
        }
      }
    }
    .payment {
      background-color: #1f1f1f;
      p:nth-child(1) {
        font-size: 16px;
        color: #fff;
        font-weight: 700;
      }
      p {
        color: #a8a8a8;
        margin-bottom: 8px;
      }
    }
  }

  .view-detail {
    // display: flex;
    // align-items: start;
    // gap: 24px;
    margin-top: 40px;
    > div {
      flex: 1;
      background-color: #fff;
      border-radius: 8px;
      padding: 16px 24px;
      .flex {
        justify-content: space-between;
        padding: 16px 0;
        border-bottom: 0.5px solid rgba(158, 158, 158, 0.5);
        p {
          font-size: 16px;
          text-transform: capitalize;
          color: #505050;
        }

        .completed {
          color: #3dba49;
          padding: 2px 10px;
          border-radius: 10px;
          font-size: 12px;
          background-color: #ecf8ed;
        }
        &.flex:last-child {
          border-bottom: none;
        }
      }
    }
    .units {
      margin-top: 40px;
      span {
        color: #505050;
      }
      .flex {
        p:nth-child(1) {
          font-size: 16px;
          span:nth-child(2) {
            font-weight: 700;
          }
        }
        p:nth-child(2) {
          color: #15294b;
          font-weight: 600;
          font-size: 14px;
        }
      }
      .flex:nth-child(1),
      .flex:nth-child(2) {
        span,
        .item {
          font-weight: 600;
        }
      }
      > div:nth-child(1) {
        border-bottom: 0.5px solid rgba(158, 158, 158, 0.5);
        .flex {
          border: none;
        }
        > p {
        }
      }
      > .flex:nth-child(1) {
        border: 0;
      }

      .total {
        p:last-child {
          font-size: 18px;
        }
      }
    }
  }
`;

export const BookingModal = styled.div`
  position: relative;
  display: flex;
  align-items: start;
  gap: 38px;
  padding: 72px 40px;
  text-transform: capitalize;
  > div:first-child {
    // border: 2px solid red;
    width: 50%;
  }
  > div:last-child {
    width: 50%;
    // border: 2px solid yellow;
  }
  .img {
    img {
      width: 100%;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.color.pink};
    }
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
      gap: 8px;
      img {
        width: 32%;
      }
    }
  }
`
export const OpenBookingModal = styled.div`
  position: relative;
  padding: 72px 40px 30px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .cancel {
    position: absolute;
    top: -65px;
    right: -5px;
    padding: 2px;
    cursor: pointer;
    border-radius: 6px;
  }
  .input-flex {
    label {
      font-weight: 400;
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      > div {
        flex: 1;
        justify-self: end;
      }
    }
    > div:nth-child(2) {
      margin-top: 23px;
    }
  }
  .btn {
    margin-top: 28px;
    align-self: start;
  }
  .preference {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .select {
      border: 1px solid #e3e4ea;
      margin-top: 8px;
      padding-bottom: 46px;
      .heading {
        padding: 16px 0 24px 16px;
      }
      .options {
        display: flex;
        flex-wrap: wrap;
        row-gap: 24px;
        column-gap: 20px;

        .flex {
          width: 48.4%;
          justify-content: space-between;
          border: 1px solid #dfe0e0;
          cursor: pointer;
          padding: 12px 14px 12px 16px;
        }
        .flex:nth-child(1),
        .flex:nth-child(3) {
          border-left: 0;
        }
      }
    }
  }
  .booking {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .calender {
      min-height: 300px;
    }
  }
`;

export const BookingClient = styled.div`
  display: flex;
  align-items: start;
  gap: 24px;
  padding: 20px 32px 20px 24px;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  > p {
    color: #3dba49;
  }
  .profile {
    p {
      border-radius: 200px;
      background: #0d0d0d;
      padding: 10px 11px;
      color: #ffc2ef;
      font-size: 14px;
      font-weight: 700;
    }
  }
  .detail {
    flex-grow: 1;
    p:nth-child(1) {
      font-size: 16px;
      color: #191919;
      font-weight: 700;
    }
    p {
      color: #a8a8a8;
      margin-bottom: 8px;
    }
  }
`;

export const BookingStatus = styled.div`
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 24px;

  .flex {
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 0.5px solid rgba(158, 158, 158, 0.5);
    p {
      font-size: 16px;
      text-transform: capitalize;
      color: #505050;
    }

    .completed {
      color: #3dba49;
      padding: 2px 10px;
      border-radius: 10px;
      font-size: 12px;
      background-color: #ecf8ed;
    }
    &.flex:last-child {
      border-bottom: none;
    }
  }
`;

export const BookingUnit = styled.div`
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 24px;
  span {
    color: #505050;
  }
  .flex {
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 0.5px solid rgba(158, 158, 158, 0.5);
    p:nth-child(1) {
      font-size: 16px;
      span:nth-child(2) {
        font-weight: 700;
      }
    }
    p:nth-child(2) {
      font-weight: 600;
    }
  }
  .flex:nth-child(1),
  .flex:nth-child(2) {
    span,
    .item {
      font-weight: 600;
    }
  }
  > div:nth-child(1) {
    border-bottom: 0.5px solid rgba(158, 158, 158, 0.5);
    .flex {
      border: none;
    }
    > p {
    }
  }
  > .flex:nth-child(1) {
    border: 0;
  }

  .sub-total {
    border: 0;
    padding: 8px 0;
  }
  .total {
    border-bottom: none;
    border-top: 0.5px solid rgba(158, 158, 158, 0.5);

    p:last-child {
      font-size: 18px;
    }
  }
`;

export const BookingDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 40px;
  flex: 1;
`;
