import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'


export const categories = createAsyncThunk(
  'category/fetchCategories',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/hairstyles/hair/categories`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const createCategory = createAsyncThunk(
  'category/createcategory',
  async ( { name, description } , { rejectWithValue }) => {
    try {
      const { data:{ data } } = await client.post(
          `${BASE_URL}/api/v1/hairstyles/hair/categories`,
          { name, description }
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const getCategory = createAsyncThunk(
  'category/getCategory',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/hairstyles/hair/categories/${id}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const updateCategory = createAsyncThunk(
  'category/updateCategory',
  async ( { id, name, description } , { rejectWithValue }) => {
    try {
        const { data:{ data } } = await client.put(
          `${BASE_URL}/api/v1/hairstyles/hair/categories/${id}`,
          { name, description }
        )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteCategory = createAsyncThunk(
  'category/deleteCategory',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.delete(
        `${BASE_URL}/api/v1/hairstyles/hair/categories/${id}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


