import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NiyoNotificationModal from "../../components/modal";
import Actions from "../../constants/actions";
import dots from "../../assets/icon/dots-horizontal (1).svg";
import { sortBySearch } from "../../redux/reducers/categoryReducer";
import { toast } from "react-hot-toast";
import { RolesTableStyle } from "./Style";
import { hairstyle } from "../../api/hairstyle";

const columns = [
  { id: "Activity ", label: "Activity ", minWidth: 250 },
  { id: "Date ", label: "Date ", minWidth: 250 },
  { id: "Time", label: "Time", minWidth: 250 },
  { id: "Actions", label: "Actions", minWidth: 10 },
];

const EditRole = () => {
  const { searchedHairstyle, data, error } = useSelector(
    (state) => state.hairstyle
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page] = useState(0);
  const [rowsPerPage] = useState(6);


  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function fetchData() {
      dispatch(hairstyle());
      setIsLoading(false);
    }
    fetchData();
  }, [dispatch]);

  const filteredHairstyle = data?.filter((cat) => {
    return (
      cat?.category?.toLowerCase().indexOf(searchedHairstyle?.toLowerCase()) !==
      -1
    );
  });

  const latestData =
    filteredHairstyle &&
    filteredHairstyle
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) };
      })
      .sort((a, b) => b?.date - a?.date);
  
  // useEffect(() => {
  //   if (error === "deleteHairstyle") {
  //     navigate(0);
  //   }
  // }, [error, navigate]);

  // useEffect(() => {
  //   if (error === "null") {
  //     navigate(0);
  //     setTimeout(() => {
  //       toast.success("Successfully Added Category");
  //     }, 1000);
  //   }
  // }, [error, navigate]);


  useEffect(() => {
    dispatch(sortBySearch(""));
  }, [dispatch]);



  return (
    <div>
      <div className="admin-heading">
        <h5>Recent Activities</h5>
      </div>
      <RolesTableStyle edit={true}>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <>
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      top: 57,
                      minWidth: column.minWidth,
                      width: column.minWidth,
                    }}
                  >
                    {isLoading ? (
                      <div className="table-header-skeleton  skeleton"></div>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                </>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.length === 0 && <div>There Is No Hairstyle</div>}
            {filteredHairstyle?.length === 0 ? (
              <div className="no-hairstyle">
                There are currently no hairstyle available
              </div>
            ) : (
              latestData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <>
                      {isLoading ? (
                        <TableRow hover>
                          <TableCell>
                            <div className="table-header-skeleton  skeleton"></div>
                          </TableCell>
                          <TableCell>
                            <div className="table-header-skeleton  skeleton"></div>
                          </TableCell>
                          <TableCell>
                            <div className="table-header-skeleton  skeleton"></div>
                          </TableCell>
                          <TableCell className="menu-dot">
                            <div className="table-header-skeleton  skeleton"></div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow hover>
                          <TableCell>{row?.createdAt.slice(0, 10)}</TableCell>
                          <TableCell>{row?.category}</TableCell>
                          <TableCell>{row?.category}</TableCell>
                          <TableCell className="menu-dot">
                            <NiyoNotificationModal
                              Content={Actions}
                              id={row?.id}
                              url={"hairstyle"}
                              //   handleOpen={handleOpen}
                            >
                              <img src={dots} alt="dot" />
                            </NiyoNotificationModal>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })
            )}
          </TableBody>
        </Table>
      </RolesTableStyle>
    </div>
  );
};

export default EditRole;
