import React, { useEffect, useState } from "react";
import { SearchWrapper } from "../../styles/components";
import searchIcon from "../../assets/icon/search.svg";
import { SpecificUserStyle } from "./Style";
import { CatTableStyle, CatWrapperStyle } from "../category/styles";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { categories } from "../../api/category";
import { useDispatch, useSelector } from "react-redux";
import menuPink from "../../assets/icon/menuPinkIcon.svg";
import { ReactComponent as AddressIcon } from "../../assets/icon/addressIcon.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icon/callIcon.svg";
import { ReactComponent as Copy } from "../../assets/icon/copy.svg";
import profileIcon from "../../assets/icon/ProfileIcon.svg";
import discount from "../../assets/icon/discount-shape.svg";
import { TableContainer, TablePagination } from "@mui/material";
import EmptyCategory from "../category/component/emptyCat";
import { getFirstLetters } from "../../helpers/validate";

const columns = [
  {
    id: "Name of User Referred",
    label: "Name of User Referred",
    minWidth: 250,
  },
  { id: "Email Address", label: "Email Address", minWidth: 250 },
  { id: "Phone Number", label: "Phone Number", minWidth: 250 },
  { id: "Date Joined", label: "Date Joined", minWidth: 120 },
];

const SpecificUser = () => {
  const { searchedCategory, data } = useSelector((state) => state.category);
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(6);

  useEffect(() => {
    function fetchData() {
    dispatch(categories());
    }
    fetchData();
  }, [dispatch]);

  const filteredCategories = data?.filter((cat) => {
    return (
      cat?.name?.toLowerCase().indexOf(searchedCategory?.toLowerCase()) !== -1
    );
  });

  const latestData =
    filteredCategories &&
    filteredCategories
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) };
      })
      .sort((a, b) => b?.date - a?.date);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (data?.length < 1) return <EmptyCategory />;

  return (
    <SpecificUserStyle>
      <div className="user-stat">
        <div>
          <div className="profile">
            <h1>{getFirstLetters("Homer Simpson")}</h1>
          </div>
          <div>
            <h3>Homer Simpson</h3>
            <p>Client</p>
            <p className="street">
              39 Hurst Street The Arcadian,Birmingham, England
            </p>
            <div className="flex">
              <div>
                <AddressIcon />
                <span>homersimpson@gmail.com</span>
              </div>
              <div>
                <PhoneIcon />
                <span>+44 7570382777</span>
              </div>
            </div>
            <h5>Referral Code</h5>
            <div className="copy">
              <Copy />
              <span>NHB1234</span>
            </div>
          </div>
        </div>
        <div>
          <div>
            <img src={profileIcon} alt="profileIcon" />
            <h3>No of Referrals</h3>
          </div>
          <h1>40</h1>
        </div>
        <div>
          <div>
            <img src={discount} alt="discount" />
            <h3>Discount Used</h3>
          </div>
          <h1>20</h1>
        </div>
      </div>

      <div className="heading">
        <h3>Users Referred</h3>
      </div>
      <CatWrapperStyle rounded="8px" referral="40px">
        <TableContainer>
          <div className="initialSort">
            <SearchWrapper referralGridBg="#FFF9FD">
              <div className="search-input">
                <img src={searchIcon} alt="search Icon" />
                <input type="text" placeholder="Search..." />
              </div>
              <div className="tableHeader">
                <div className="pagination">
                  <span>Show</span>
                  <TablePagination
                    rowsPerPageOptions={[2, 4, 6]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage=""
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />

                  <p>entries</p>
                </div>
                <div className="grid-view active">
                  <img src={menuPink} alt="format List" className="grid-img" />
                </div>
              </div>
            </SearchWrapper>
          </div>
          <CatTableStyle
            specific={true}
            border="transparent"
            referralColor="#2C2C2C"
          >
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        top: 57,
                        minWidth: column.minWidth,
                        width: column.minWidth,
                      }} // Add width style
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCategories?.length === 0 ? (
                  <div>There are currently no category available</div>
                ) : (
                  latestData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row) => {
                      return (
                        <TableRow hover>
                          <TableCell>{row?.createdAt.slice(0, 10)}</TableCell>
                          <TableCell>{row?.name}</TableCell>
                          <TableCell>{row?.description}</TableCell>
                          <TableCell>{row?.description}</TableCell>
                        </TableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </CatTableStyle>
        </TableContainer>
      </CatWrapperStyle>
      <div className="footer">
        <TablePagination
          rowsPerPageOptions={[2, 4, 6]}
          component="div"
          count={latestData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </SpecificUserStyle>
  );
};

export default SpecificUser;
