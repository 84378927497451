import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import {
  CatTableStyle,
  CatWrapperStyle,
  CategoryModal,
} from '../category/styles'
import { SearchWrapper, CustomModal } from '../../styles/components'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import searchIcon from '../../assets/icon/search.svg'
import closeIcon from '../../assets/icon/Close Square.svg'
import menuPink from '../../assets/icon/menuPinkIcon.svg'
import NiyoNotificationModal from '../../components/modal'
import Actions from '../../constants/actions'
import dots from '../../assets/icon/dots-horizontal (1).svg'
import {
  sortBySearch,
  editTransaction,
} from '../../redux/reducers/transactionReducer'
import { transactions } from '../../api/transaction'
import { toast } from 'react-hot-toast'
// import EmptyCategory from "./component/emptyCat";

const columns = [
  { id: 'Date Added', label: 'Date', minWidth: 150 },
  { id: 'Time', label: 'Time', minWidth: 150 },
  { id: 'Payment ID', label: 'Payment ID', minWidth: 150 },
  // { id: 'Customer', label: 'Customer', minWidth: 150 },
  { id: 'Amount', label: 'Amount', minWidth: 150 },
  // { id: 'Payment Type', label: 'Payment Type', minWidth: 550 },
  { id: 'Status', label: 'Status', minWidth: 550 },
  { id: 'Actions', label: 'Actions', minWidth: 10 },
]

const Category = () => {
  const {
    modalStatus,
    searchedTransaction,
    transaction,
    data,
    error,
  } = useSelector((state) => state.transaction)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [page, setPage] = useState(0)
  const [searchTransaction, setSearchTransaction] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(6)

  const [isLoading, setIsLoading] = useState(true)

  const filteredTransaction = data?.filter((tran) => {
    return (
      tran?.id?.toLowerCase().indexOf(searchedTransaction?.toLowerCase()) !== -1
    )
  })

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    function fetchData() {
      dispatch(transactions())
      setIsLoading(false)
    }
    fetchData()
  }, [dispatch])


  const handleCancel = () => {
    dispatch(editTransaction(false))
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleSearchCat = (e) => {
    setSearchTransaction(e.target.value)
    dispatch(sortBySearch(e.target.value))
    // setRowsPerPage(100)
  }

  useEffect(() => {
    dispatch(sortBySearch(''))
  }, [dispatch])

  const transctionDetails = [
    {
      label: 'Date',
      value: `${transaction && transaction[0]?.createdAt?.slice(0, 10)}`,
    },
    {
      label: 'Time',
      value: `${
        transaction && dayjs(transaction[0]?.createdAt)?.format('hh:mm a')
      }`,
    },
    {
      label: 'Customer',
      value: 'Olukemi Damilola',
    },
    {
      label: 'Booking ID',
      value: `${transaction && transaction[0]?.id}`,
    },
    {
      label: 'Amount',
      value: `${transaction && transaction[0]?.amount}`,
    },
    {
      label: 'Status',
      value: `${transaction && transaction[0]?.status}`,
    },
  ]

  const latestData =
    filteredTransaction &&
    filteredTransaction
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) }
      })
      .sort((a, b) => b?.date - a?.date)

  const case1 =
    searchTransaction === ''
      ? latestData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : latestData

  //   if (data?.length < 1) return <EmptyCategory category={true} />;

  return (
    <CatWrapperStyle>
      <>
        <TableContainer>
          <div className="initialSort">
            <SearchWrapper>
              <div className="search-input">
                <img src={searchIcon} alt="search Icon" />
                <input
                  type="text"
                  value={searchTransaction}
                  onChange={handleSearchCat}
                  placeholder="Search..."
                />
              </div>
              <div className="tableHeader">
                <div className="pagination">
                  <span>Show</span>
                  <TablePagination
                    rowsPerPageOptions={[2, 4, 6]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage=""
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                  <p>entries</p>
                </div>
                <div className="grid-view active">
                  <img src={menuPink} alt="format List" className="grid-img" />
                </div>
              </div>
            </SearchWrapper>
          </div>
          <CatTableStyle>
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <>
                      <TableCell key={column.id} align={column.align}>
                        {isLoading ? (
                          <div className="table-header-skeleton  skeleton"></div>
                        ) : (
                          column.label
                        )}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.length === 0 && (
                  <div>There are currently no transactions available</div>
                )}
                {filteredTransaction?.length === 0 ? (
                  <div className="empty-search">
                    <p>
                      There are currently no transactions available for your search
                    </p>
                  </div>
                ) : (
                  case1?.map((row) => {
                    return (
                      <>
                        {isLoading ? (
                          <TableRow hover>
                            <TableCell>
                              <div className="table-header-skeleton  skeleton"></div>
                            </TableCell>
                            <TableCell>
                              <div className="table-header-skeleton  skeleton"></div>
                            </TableCell>
                            <TableCell>
                              <div className="table-header-skeleton  skeleton"></div>
                            </TableCell>
                            <TableCell className="menu-dot">
                              <NiyoNotificationModal
                                Content={Actions}
                                id={row?.id}
                                url={'transaction'}
                              >
                                <div className="table-header-skeleton  skeleton"></div>
                              </NiyoNotificationModal>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow hover>
                            <TableCell>
                              {row?.createdAt?.slice(0, 10)}
                            </TableCell>
                            <TableCell>
                              {' '}
                              {dayjs(transaction?.createdAt).format('hh:mm a')}
                            </TableCell>
                            <TableCell>{row?.id}</TableCell>
                            {/* <TableCell>Olukemi Damilola</TableCell> */}
                            <TableCell>£{row.amount}</TableCell>
                            <TableCell>{row?.status}</TableCell>
                            <TableCell className="menu-dot">
                              <NiyoNotificationModal
                                Content={Actions}
                                id={row?.id}
                                url={'transaction'}
                              >
                                <img src={dots} alt="dot" />
                              </NiyoNotificationModal>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )
                  })
                )}
              </TableBody>
            </Table>
          </CatTableStyle>
        </TableContainer>

        <div className="footer">
          {isLoading ? (
            <div className="pagination-skeleton skeleton"></div>
          ) : (
            <TablePagination
              rowsPerPageOptions={[2, 4, 6]}
              component="div"
              count={latestData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </>
      <CustomModal
        centered
        closable={false}
        open={modalStatus}
        onCancel={handleCancel}
        footer={null}
        width={563}
      >
        <CategoryModal>
          <div className="category-form edit">
            <div className="title">Transaction Details</div>
            {transctionDetails?.map((transaction) => (
              <div className="flex">
                <div>{transaction?.label}</div>
                <div
                  style={{
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: '#A3A3A3',
                  }}
                >
                  {transaction?.value}
                </div>
              </div>
            ))}
          </div>

          <div className="cancel" onClick={handleCancel}>
            <img src={closeIcon} alt="closeIcon" />
          </div>
        </CategoryModal>
      </CustomModal>
    </CatWrapperStyle>
  )
}
export default Category
