import React, { useState } from "react";
import { Modal } from "antd";
import welcomeIcon from "../../assets/img/welcomeIcon.svg";
import { WelcomeAdminStyle, WelcomeModal } from "./Style";
import { Button } from "../../styles/components";

const WelcomeAdmin = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleCancel = () => {
    localStorage.removeItem('initialArrival')
    setIsModalOpen(false);
  };

  return (
    <WelcomeModal>
      <Modal
        centered
        closable={false}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={700}
      >
        <WelcomeAdminStyle>
          <div>
            <img src={welcomeIcon} alt="welcome Icon" />
          </div>
          <div>
            <h3>Welcome Niyo Hair & Beauty Super Admin</h3>
            <p>
              You will be able to track and manage all bookings on Niyo hair and
              beauty saloon
            </p>
          </div>
          <div>
            <Button onClick={handleCancel}>Go to Dashboard</Button>
          </div>
        </WelcomeAdminStyle>
      </Modal>
    </WelcomeModal>
  );
};

export default WelcomeAdmin;
