import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

  *,
    *::after,
    *::before {
        box-sizing: border-box;
        margin: 0;
        padding : 0;
    }

 
    input,select,textarea{
      outline: none;
      background-color: inherit;
    }
    h1,h3{
      font-family: reboundBold;
    }
    li{
      p{
        font-family: reboundLight;
      }
    }

    h1{
      font-size: 32px;
      color: #191919;
      line-height: 40px;
      font-weight: 700;
    }
     h3{
      font-size: 24px;
      font-weight: 700;
      color: #191919;
    }
     h4{
      font-size: 24px;
      font-weight: 600;
    }
    
    p{
      font-size: 14px;
      line-height: 24px;
      color: #42526D;
    }
    h5{
      font-size: 16px;
color: #333;
      font-weight: 600;
    }
    span{
      font-size: 14px;
color: #858585;
    }
    li{
      list-style: none;
    }
    a{
      text-decoration: none;
    }
    label{
      color: #191919;
      font-weight: 600;
    }
    button{
      width: 100%;
    }
    body{
      font-family: satoshi;
    }
    
::-webkit-scrollbar {
  height: 0px;
  width: 7px;
  border-radius: 10px;
  overflow: hidden;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  display: none;
  background-color: rgb(174, 174, 174);
}
.flex{
  display: flex;
  align-items: center;
}
    .footer {
      width: 100% !important;
    }
    .initialSort .css-levciy-MuiTablePagination-displayedRows {
      border: 3px solid red !important;
      display: none !important;
    }
    .initialSort .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
      display: none !important;
    }
    .footer .css-pdct74-MuiTablePagination-selectLabel {
      display: none !important;
    }
    .footer .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
      display: none !important;
    }
    // this is for the next bar
    .footer .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
      background: black !important;
      display: flex !important;
      width: 15% !important;
      height: 60% !important;
   justify-content: flex-start !important;
      color: white !important;
    }
    .css-zylse7-MuiButtonBase-root-MuiIconButton-root {
      color: white !important;
      background: black !important;
      display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    position: none !important; 
    box-sizing: border-box !important;
    outline: 0 !important;
    border: 0 !important;
    margin: 0 !important;
    border-radius: 0 !important;
    padding: 10px  !important;
    cursor: pointer !important;
    user-select: none !important;
    vertical-align: middle !important;
    text-decoration: none !important;
    color: inherit !important;
    text-align: center !important;
    flex: none !important;
    font-size: 1.5rem !important;
    padding: 8px !important;
    border-radius: 0% !important;
    overflow: hidden !important;
    }
      .ant-popover {
        div{
          >div{
            padding: 0 !important;
          }
        }
  }
    .disabled{
      pointer-events: none;
      background-color: #a7a7a7;
      &.preference{
      background-color: inherit;
      }
    }
    .footer .css-jtlhu6-MuiTablePagination-root:last-child {
      // max-width: 100%  !important;
      display: flex !important;
      position: absolute !important;
      right: 0 !important;
      width: 400px !important;
      justify-content: center !important;
      // border: 3px solid red;
    }
    .footer .css-jtlhu6-MuiTablePagination-root {
      display: flex !important;
      width: 100%  !important;
      overflow: hidden !important;
    }
    .footer .css-1psng7p-MuiTablePagination-spacer {
      display: flex !important;
      // padding-left: -230px !important;
    }
    .footer .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar{
      display: flex !important;
      margin: 36px 0 24px !important;

    }
    .add-hairstyle {
      background-color: white;
      display: grid;
      height: 100vh;
      place-items: center;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        img {
          margin-bottom: 8px;
        }
      }
    }
   
`;
