import React from "react";
import ReferralLayout from "../../layout/ReferralLayout";
import UserReferral from "../../features/referrals/UserReferral";

const UserReferralPage = () => {
  return (
    <ReferralLayout>
      <UserReferral />
    </ReferralLayout>
  );
};

export default UserReferralPage;
