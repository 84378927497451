import React from "react";
import { AuthLayoutStyle } from "../styles/components";
import niyoLogo from '../assets/img/niyo-logo.svg'
import nhbImg from '../assets/img/models.png'

const AuthLayout = ({ children }) => {
  return (
    <AuthLayoutStyle>
      <div>
        <div>
          <div>
            <img src={niyoLogo} alt="Niyo logo" />
          </div>
          <div>
            <h1>Easy Management of all Niyo Hair and Beauty Appointments</h1>
            <p>
              Add, edit and manage all hair appointments on Niyo Hair & beauty
            </p>
          </div>
        </div>
        <div>
          <img src={nhbImg} alt="Niyo hair and beauty img" style={{ width: '100%' }} />
        </div>
      </div>
      {children}
    </AuthLayoutStyle>
  );
};

export default AuthLayout;
