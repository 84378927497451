import React from "react";

const AdminBox = ({ box , no }) => {
  return (
    <div className="box">
      <div>
        <img src={box.icon} alt="box" />
      </div>
      <div>
        <span>{box.type}</span>
        <h1>{box.number}</h1>
      </div>
    </div>
  );
};

export default AdminBox;
