import React, { useState } from 'react'
import styled from 'styled-components'
import { Popover } from 'antd'

const NiyoNotificationModal = ({
  Title,
  url,
  Content,
  id,
  nav,
  email,
  disable,
  notifications,
  handleOpen,
  children,
}) => {

  const [visible, setVisible] = useState(false)


  const handleVisibleChange = (visible) => {
    setVisible(visible)
  }

  const hide = () => {
    setVisible(false)
  }

  return (
    <div
      style={{
        clear: "both",
        whiteSpace: "nowrap",
        pointerEvents: disable,
      }}
    >
      <Popover
        placement="bottom"
        title={Title}
        content={
          <Content
            hide={hide}
            id={id}
            nav={nav}
            oldEmail={email}
            url={url}
            handleOpen={handleOpen}
            notifications={notifications}
          />
        }
        trigger="click"
        onVisibleChange={handleVisibleChange}
        visible={visible}
      >
        {children}
      </Popover>
    </div>
  );
}
export default NiyoNotificationModal

export const Wrapper = styled.div``
