import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'

export const hairstyle = createAsyncThunk(
  'hairstyle/fetchHairstyle',
  async (obj, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await client.get(`${BASE_URL}/api/v1/hairstyles`)
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  },
)

export const createHairstyle = createAsyncThunk(
  'hairstyle/createhairstyle',
  async (form_data, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const {
        data: { data },
      } = await client.post(`${BASE_URL}/api/v1/hairstyles`, form_data, config)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const updateHairstyle = createAsyncThunk(
  'category/updateHairstyle',
  async (
    { id, name, averageTime, price, category, form_data },
    { rejectWithValue },
  ) => {
    try {
      if (name) {
        const {
          data: { data },
        } = await client.patch(`${BASE_URL}/api/v1/hairstyles/${id}`, {
          name,
        })
        return data
      }
      if (averageTime) {
        const {
          data: { data },
        } = await client.patch(`${BASE_URL}/api/v1/hairstyles/${id}`, {
          averageTime,
        })
        return data
      }
      if (price) {
        const {
          data: { data },
        } = await client.patch(`${BASE_URL}/api/v1/hairstyles/${id}`, {
          price,
        })
        return data
      }
      if (category) {
        const {
          data: { data },
        } = await client.patch(`${BASE_URL}/api/v1/hairstyles/${id}`, {
          category,
        })
        return data
      }
      if (form_data) {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const {
          data: { data },
        } = await client.patch(
          `${BASE_URL}/api/v1/hairstyles/${id}`,
          form_data,
          config,
        )
        return data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const createHairstylePreference = createAsyncThunk(
  'hairstyle/createhairstylePreference',
  async ({ preferences, id }, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await client.post(
        `${BASE_URL}/api/v1/hairstyles/${id}/preferences`,
        preferences,
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const updateHairstylePreference = createAsyncThunk(
  'hairstyle/updatehairstylePreference',
  async ({ newData, id }, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await client.put(
        `${BASE_URL}/api/v1/hairstyles/${id}/preferences`,
        newData,
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const publishHairstyle = createAsyncThunk(
  'hairstyle/publishhairstyle',
  async (id, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await client.put(`${BASE_URL}/api/v1/hairstyles/${id}/publish`)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const hairstylePreference = createAsyncThunk(
  'hairstyle/fetchHairstylePreference',
  async (id, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await client.get(`${BASE_URL}/api/v1/hairstyles/${id}/preferences`)
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  },
)

export const deleteHairstyle = createAsyncThunk(
  'hairstyle/deletingHairstyle',
  async (id, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await client.delete(`${BASE_URL}/api/v1/hairstyles/${id}`)
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  },
)

export const deleteHairstylePreference = createAsyncThunk(
  'hairstyle/deletingHairstylePreference',
  async ({ id, preferenceId}, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await client.delete(`${BASE_URL}/api/v1/hairstyles/${id}/preferences/${preferenceId}`)
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  },
)
