import React from "react";
import dots from "../../../assets/icon/dots-horizontal (1).svg";
import { useSelector } from "react-redux";
import NiyoNotificationModal from "../../../components/modal";
import Table from "@mui/material/Table";
import user from "../../../assets/icon/avatar.jpeg";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import Actions from "../../../constants/actions";
import { TableRow } from "@mui/material";
import { RolesListStyle, RolesTableStyle } from "../Style";

const columns = [
  { id: "Name ", label: "Name ", minWidth: 300 },
  { id: "Email Address", label: "Email Address", minWidth: 210 },
  { id: "Role", label: "Role", minWidth: 210 },
  { id: "Actions", label: "Actions", minWidth: 20 },
];

const RolesListTable = ({
  handleOpen,
  isLoading,
  case1,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  latestData,
  filteredRoles,
}) => {
  const { data } = useSelector((state) => state.roles);

  return (
    <RolesListStyle>
      <RolesTableStyle>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <>
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      top: 57,
                      minWidth: column.minWidth,
                      width: column.minWidth,
                    }}
                  >
                    {isLoading ? (
                      <div className="table-header-skeleton  skeleton"></div>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                </>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.length === 0 && <div>There are no Roles</div>}

            {filteredRoles?.length === 0 ? (
              <div className="no-hairstyle">
                There are currently no roles available
              </div>
            ) : (
              case1?.map((row) => {
                  return (
                    <>
                      {isLoading ? (
                        <TableRow hover>
                          <TableCell>
                            <div className="table-header-skeleton  skeleton"></div>
                          </TableCell>
                          <TableCell>
                            <div className="table-header-skeleton  skeleton"></div>
                          </TableCell>
                          <TableCell>
                            <div className="table-header-skeleton  skeleton"></div>
                          </TableCell>
                          <TableCell className="menu-dot">
                            <div className="table-header-skeleton  skeleton"></div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow hover>
                          <TableCell>
                            <div>
                              <img
                                src={row?.photo?.url || user}
                                alt="hairStyleImg"
                              />
                              <span>{row?.firstName} {row?.lastName}</span>
                            </div>
                          </TableCell>
                          <TableCell>{row?.email}</TableCell>
                          <TableCell>
                            <div>
                              <span>{row?.role}</span>
                            </div>
                          </TableCell>
                          <TableCell className="menu-dot">
                            <NiyoNotificationModal
                              Content={Actions}
                              id={row?.id}
                              url={"role"}
                            >
                              <img src={dots} alt="dot" />
                            </NiyoNotificationModal>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })
            )}
          </TableBody>
        </Table>
      </RolesTableStyle>

      <div className="footer">
        {isLoading ? (
          <div className="pagination-skeleton skeleton"></div>
        ) : (
          <TablePagination
            rowsPerPageOptions={[2, 4, 6]}
            component="div"
            count={latestData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </div>
    </RolesListStyle>
  );
};

export default RolesListTable;
