import { styled } from 'styled-components'

export const HairStyled = styled.section`
  padding: 16px 50px 90px;
  h1 {
    text-transform: capitalize;
  }
`

export const CategoryModal = styled.div`
  border-radius: 8px;
  background-color: ${({ adminprocess }) => adminprocess && 'white'} !important;
  padding: 48px;

  .ant-modal-content {
    /* outline: solid red !important; */
  }
  .cancel {
    position: absolute;
    top: -65px;
    right: -5px;
    padding: 2px;
    cursor: pointer;
    border-radius: 6px;
  }
  .heading {
    margin-bottom: 40px;
  }
  /* 
  label {
    font-weight: 400;
  } */

  .select-input,
  .program-select {
    margin-bottom: 29px;
    select {
      background-color: transparent;
      padding: 18px 16px;
    }
  }
  .program-select {
    margin-bottom: 0;
  }

  .category-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .title {
      color: #171717;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .img {
      width: 80px;
      img {
        height: 80px;
        width: 80px;
      }
      label {
        position: relative;
        cursor: pointer;

        img {
          border-radius: 100%;
        }
        div {
          position: absolute;
          left: 20px;
          top: -20px;
        }
      }
    }
    div:nth-child(2) {
      margin-top: 4px;
    }
    h2 {
      font-size: 16px;
      color: #191919;
      margin-bottom: 8px;
    }
  }

  .category-form.edit:nth-child(1) {
    gap: 18px;
    margin-top: 0;

    .input-flex {
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        > div {
          flex: 1;
          justify-self: end;
        }
      }
      > div:nth-child(2) {
        margin-top: 23px;
      }
    }
    .flex {
      justify-content: space-between;
      width: 100%;
      margin: 0;
      .img {
        text-align: right;
      }
    }
    > .flex:nth-child(2),
    .flex:nth-child(3) {
      max-width: 650px;
    }
  }

  .permission {
    padding: 10px;
    background-color: #fafafb;
    p {
      color: #505050;
    }
  }

  .category-form.edit:nth-child(2) {
    max-width: 550px;
    margin-top: 13px;
  }

  .btn {
    margin-top: ${({ booking }) => (booking ? '8px' : '')};
    align-self: start;
  }

  .check-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 5px;
    h2 {
      font-size: 18px;
      color: #0f1011;
      margin-bottom: 16px;
    }
    .flex {
      justify-content: space-between;
      width: 100%;
      margin-top: 16px;
      span:last-child {
        cursor: pointer;
      }
    }
  }

  .success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 14px;
    div {
      text-align: center;
      h2 {
      }
    }
  }
`

export const CatWrapperStyle = styled.div`
  background-color: white;
  padding: ${({ referral }) => referral || '24px 16px 105px'};
  border-radius: ${({ rounded }) => rounded || '4px'};
  .search-input {
    width: 44%;
  }
`

// export const SearchWrapper = styled.div`
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
//   //   gap: 61px;
//   > div:nth-child(1) {
//     width: 100%;
//     // flex-grow: 1;
//     position: relative;
//     input {
//       border: 1px solid #e3e6e9;
//       padding: 16px;
//       padding-left: 45px;
//       width: 50%;
//       font-size: 14px;
//       background-color: transparent;
//       border-radius: 8px;
//       &::placeholder {
//         font-size: 14px;
//         color: #4e5054;
//       }
//     }
//     img {
//       position: absolute;
//       left: 15px;
//       top: 15px;
//     }
//   }
//   > div:nth-child(2) {
//     // border: 3px solid blue;
//   }
//   .tableHeader {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     outline: solid red;
//     > div:nth-child(2) {
//       margin-left: -20px;
//     }
//     > div:nth-child(3) {
//       margin-right: 10px;
//       .list-img {
//         border-radius: 4px;
//         background: #f5f9ff;
//         padding: 12px;
//       }
//       img {
//         cursor: pointer;
//       }
//     }
//   }
// `
export const CatTableStyle = styled.div`
  margin-top: 32px;
  background-color: transparent;
  min-height: 400px;
  max-height: 400px;

  table {
    .empty-search {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      margin: 0 auto;
    }
    thead {
      tr {
        .ant-table-cell {
          border-bottom: 1px solid #eaeaea;
        }
        th:nth-last-child(1) {
          color: ${({ referralColor }) => referralColor || '#858585'};
        }
        th {
          padding-left: ${({ specific }) => specific && 0};
          color: #2c2c2c;
          font-family: rebound;
          border-bottom: 1px solid ${({ border }) => border || 'inherit'};
        }
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: transparent !important;
      }
      td {
        padding-left: ${({ specific }) => specific && 0}!important;
        border-bottom: 1px solid ${({ border }) => border || 'inherit'};
        color: #a8a8a8;
        font-weight: 400;
        padding: 24px 16px;
        text-transform: capitalize;
      }
      .menu-dot {
        div {
          cursor: pointer;
        }
      }
    }
  }
`
