import React, { useState, useEffect } from 'react'
import {
  AdminBooking,
  AdminInfo,
  AdminInfoBox,
  DashboardStyle,
  RecentBooking,
} from './Style'
import WelcomeAdmin from './WelcomeAdmin'
import { useDispatch, useSelector } from 'react-redux'
import AdminBox from '../../components/AdminBox'
import downloadIcon from '../../assets/icon/downloadGreen.svg'
import chart from '../../assets/img/u_chart-line.svg'
import adminMoneyIcon from '../../assets/icon/moneys.svg'
import Booking from './component/recentBooking'
import adminUserIcon from '../../assets/icon/userDark.svg'
import adminPeopleIcon from '../../assets/icon/referral.svg'
import adminBoxIcon from '../../assets/icon/box.svg'
import {
  sortRevenuelabel,
  sortBookinglabel,
  sortBookingData,
  sortRevenueData,
} from '../../utils/helper'
import { Button } from '../../styles/components'
import NiyoCharts from '../../components/NiyoLineChart'
import {
  getNumbers,
  getCharts,
  getChartsStylist,
  getNumbersStylist,
} from '../../api/dashboard'

const Dashboard = () => {
  const {
    loading,
    numbers,
    charts,
    stylistNumbers,
    stylistCharts,
  } = useSelector((state) => state.dashboard)
  const dispatch = useDispatch()

  useEffect(() => {
    function fetchData() {
      dispatch(getNumbers())
      dispatch(getCharts())
      dispatch(getNumbersStylist())
      dispatch(getChartsStylist())
    }
    fetchData()
  }, [dispatch])

  const [uniqueNumber, setUniqueNumber] = useState(null)
  const [uniqueCharts, setUniqueCharts] = useState(null)
  const [checkStylist, setcheckStylist] = useState(null)
  const [bookingChart, setBookingChart] = useState({
    label: ['--', '--', '--', '--', '--', '--', '--'],
    datasets: [],
  })

  const [revenueChart, setRevenueChart] = useState({
    label: ['--', '--', '--', '--', '--', '--'],
    datasets: [],
  })

  useEffect(() => {
    const userRole = localStorage.getItem('userRole')
      ? localStorage.getItem('userRole')
      : null
    setcheckStylist(userRole)
    if (userRole === 'hairstylist') {
      setUniqueNumber(stylistNumbers)
      setUniqueCharts(stylistCharts)
    } else {
      setUniqueNumber(numbers)
      setUniqueCharts(charts)
    }
  }, [charts, numbers, stylistCharts, stylistNumbers])

  useEffect(() => {
    const run = () => {
      setBookingChart({
        ...bookingChart,
        label: sortBookinglabel(uniqueCharts?.bookingsChartsData),
        datasets: sortBookingData(uniqueCharts?.bookingsChartsData),
      })
      setRevenueChart({
        ...revenueChart,
        label: sortRevenuelabel(uniqueCharts?.paymentsChartsData),
        datasets: sortRevenueData(uniqueCharts?.paymentsChartsData),
      })
    }
    run()
  }, [uniqueCharts])

  const adminInfo =
    checkStylist === 'hairstylist'
      ? [
          {
            type: 'Total Bookings',
            number: `${loading ? '-' : uniqueNumber?.bookingsCount}`,
            icon: adminBoxIcon,
          },
        ]
      : [
          {
            type: 'Revenue',
            number: `£${
              loading || uniqueNumber?.revenueTotal === undefined
                ? '-'
                : uniqueNumber?.revenueTotal
            }`,
            icon: adminMoneyIcon,
          },
          {
            type: 'Users',
            number: `${
              loading || uniqueNumber?.usersCount === undefined
                ? '-'
                : uniqueNumber?.usersCount
            }`,
            icon: adminUserIcon,
          },
          {
            type: 'Referrals',
            number: `${
              loading || uniqueNumber?.referralsCount === undefined
                ? '-'
                : uniqueNumber?.referralsCount
            }`,
            icon: adminPeopleIcon,
          },
          {
            type: 'Total Bookings',
            number: `${loading ? '-' : uniqueNumber?.bookingsCount}`,
            icon: adminBoxIcon,
          },
        ]

  const bookingOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 100,
        },
      },
      x: {
        display: true,
      },
    },
  }
  const revenueOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 200000,
          callback: (value, index, values) => {
            return value.toLocaleString()
          },
        },
      },
      x: {
        display: true,
      },
    },
  }
  const check = localStorage.getItem('initialArrival') ? true : false

  return (
    <DashboardStyle>
      {check ? <WelcomeAdmin /> : <div />}
      <AdminInfo>
        <div className="heading">
          <div>
            <h1>Welcome</h1>
            <p>Niyo Hair & Beauty Super Admin Dashboard</p>
          </div>
          <div className="btn">
            <Button
              padding={'5px 10.5px'}
              rounded="5px"
              font="10px"
              color="#A8A8A8"
              border="0.5px solid #B3B9C4"
              bg="transparent"
              weight="400"
            >
              <span>This Month</span>
            </Button>
          </div>
        </div>
        <AdminInfoBox>
          {adminInfo.map((box, i) => (
            <AdminBox box={box} no={i} key={i} />
          ))}
        </AdminInfoBox>
      </AdminInfo>

      <AdminBooking>
        <div>
          <div>
            <div>
              <p>Bookings Chart</p>
              <span>Overview of bookings appointments</span>
            </div>
            <div>
              {/* <button>
                <img src={downloadIcon} alt="" /> <span>Save Report</span>
              </button> */}
            </div>
          </div>
          {uniqueCharts?.bookingsChartsData?.length < 1 ? (
            <div className="nodata">
              <img src={chart} alt="" />
              <p>Booking Chart</p>
              <span>This data will appear when client book appointments</span>
            </div>
          ) : (
            <div className="data">
              <NiyoCharts
                labels={bookingChart.label}
                // height="100%"
                option={bookingOptions}
                datasets={bookingChart.datasets}
              />
            </div>
          )}
        </div>
        {checkStylist !== 'hairstylist' && (
          <div>
            <div>
              <div>
                <p>Total Revenue</p>
              </div>
              <div>
                <button className="booking2">This Month</button>
              </div>
            </div>
            {charts?.paymentsChartsData?.length > 1 ? (
              <div className="nodata">
                <img src={chart} alt="" />
                <p>Total Revenue</p>
                <span>
                  The data for total revenue will appear when client book
                  appointments
                </span>
              </div>
            ) : (
              <div className="data">
                <NiyoCharts
                  labels={revenueChart.label}
                  // height="50%"
                  option={revenueOptions}
                  datasets={revenueChart.datasets}
                />
              </div>
            )}
          </div>
        )}
      </AdminBooking>

      <RecentBooking>
        <div>
          <h4>Recent Bookings</h4>
        </div>
        <div>
          <Booking checkStylist={checkStylist} />
        </div>
      </RecentBooking>
    </DashboardStyle>
  )
}

export default Dashboard
