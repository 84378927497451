import { ReactComponent as CheckedMark } from "../assets/icon/check-mark.svg";
import { ReactComponent as CheckedMark2 } from "../assets/icon/check-mark-2.svg";

const CheckMark = ({ selected }) => {
  return (
    <span className={`check-mark ${selected ? "selected" : ""}`}>
      {selected ? <CheckedMark2 /> : <CheckedMark />}
    </span>
  );
};

export default CheckMark;