import { useEffect, useState } from 'react'
import { AddHairStyled, HairStyleForm, InputFlex } from './Style'
import { Button, InputStyle } from '../../styles/components'
import uploadImg from '../../assets/img/uploadImg.svg'
import { Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { categories } from '../../api/category'
import { createHairstyle } from '../../api/hairstyle'
import {
  setHairstyleCat,
  setHairstyleName,
} from '../../redux/reducers/hairstyleReducer'
import fileUpload from '../../assets/img/fileUpload.svg'
import { ReactComponent as One } from '../../assets/icon/one.svg'
import { ReactComponent as Two } from '../../assets/icon/two.svg'
import dropDown from '../../assets/icon/down.svg'
import { acceptNumber } from '../../helpers/validate'
import { toast } from 'react-hot-toast'

const AddHairStyle = () => {
  const { data } = useSelector((state) => state.category)
  const { loading, error } = useSelector((state) => state.hairstyle)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [averageTime, setAverageTime] = useState('')
  const [price, setPrice] = useState('')
  const [name, setName] = useState('')
  const [category, setCategory] = useState('')
  const [files, setFiles] = useState('null')


  const handleAvatarClick = () => {
    document.getElementById('addHair').click()
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file && file.size <= 1024 * 1024) {
      // Max size of 1MB
        setFiles(file)
    } else {
      toast.error('file exceeds limits of 1mb')
    }
  }
  const handleCat = (e) => {
    setCategory(e.target.value)
    dispatch(setHairstyleCat(e.target.value))
  }

  const handleName = (e) => {
    setName(e.target.value)
    dispatch(setHairstyleName(e.target.value))
  }

  const handlePrice = (e) => {
    setPrice(e.target.value)
  }

  const handleTime = (e) => {
    setAverageTime(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // if (files === 'null') {
    //   toast.error('please upload file')
    // }
    let form_data = new FormData()
    if ( files !== 'null') {
      form_data.append('thumbnail', files)
    }
    form_data.append('name', name)
    form_data.append('price', price)
    form_data.append('averageTime', averageTime)
    form_data.append('category', category)
    await dispatch(createHairstyle(form_data))
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    async function fetchData() {
      await dispatch(categories())
    }
    fetchData()
  }, [dispatch])

  if (error === 'null') return navigate('/hairstyle/preference')

  return (
    <AddHairStyled>
      <div className="select">
        <div>
          <One />
          <p> Hairstyle Information</p>
        </div>
        <div>
          <Two /> <p>Hairstyle Preference</p>
        </div>
      </div>
      <div className="heading">
        <h3>Add Hairstyles</h3>
      </div>
      <HairStyleForm onSubmit={handleSubmit}>
        <InputStyle>
          <label htmlFor="">Hairstyle Name</label>
          <input
            type="text"
            value={name}
            onChange={handleName}
            placeholder="Nulocs"
            required
          />
        </InputStyle>
        <InputStyle className="select-input">
          <label htmlFor="">Select Category</label>
          <div className="select">
            <select
              name=""
              id=""
              value={category}
              onChange={handleCat}
              required
            >
              <option selected disabled value="">
                Select your option
              </option>
              {data?.map((cat) => (
                <option value={cat?.name}>{cat?.name}</option>
              ))}
            </select>
            <img src={dropDown} alt="dropDown" />
          </div>
        </InputStyle>
        <InputStyle>
          <label htmlFor="">Duration </label>
          <input
            value={averageTime}
            onChange={handleTime}
            type="text"
            placeholder="Type in How long it will take to make this hairstyle"
            onKeyDown={acceptNumber}
            required
          />
        </InputStyle>
        <InputFlex>
          <InputStyle>
            <label htmlFor="">Regular Price </label>
            <input
              type="text"
              value={price}
              onChange={handlePrice}
              placeholder="£"
              onKeyDown={acceptNumber}
              required
            />
          </InputStyle>
        </InputFlex>
        <div>
          <div onClick={handleAvatarClick}>
            <InputStyle upload={true}>
              <div className="img-upload">
                <h3>Upload Image</h3>
                <p>Upload the hairstyle image</p>
                <div className="upload">
                  <p>Upload image of the hairstyle here</p>

                  {files !== 'null' ? (
                    <>
                      <img src={fileUpload} alt="file Upload" />
                      <p>File Uploaded</p>
                    </>
                  ) : (
                    <>
                      <label htmlFor="addHair">
                        <img src={uploadImg} alt="uploadImg" />
                        <input
                          type="file"
                          id="addHair"
                          accept="image/*"
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                          multiple={false}
                        />
                      </label>

                      <p>
                        Choose file to upload
                      </p>
                    </>
                  )}
                </div>
              </div>
            </InputStyle>
          </div>
        </div>
        <div className="btn">
          <Button
            padding="8px 40px"
            rounded="5px"
            disabled={loading}
          >
            {loading ? <Spin /> : 'Next'}
          </Button>
        </div>
    
      </HairStyleForm>
    </AddHairStyled>
  )
}

export default AddHairStyle
