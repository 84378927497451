import React, {useState} from "react";
import dots from "../../../assets/icon/dots-horizontal.svg";
import { useSelector } from "react-redux";
import NiyoNotificationModal from "../../../components/modal";
import Table from "@mui/material/Table";
// import hairStyleImg from "../../../assets/img/hairStyle.svg";
import user from "../../../assets/icon/avatar.jpeg";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import Actions from "../../../constants/actions";
import EmptyCategory from "../../category/component/emptyCat";
import { GridStyle } from "../../../styles/components";
import { RolesListStyle } from "../Style";

const RolesListGrid = ({
  handleOpen,
  isLoading,
  case1,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  latestData,
  filteredRoles,
}) => {
  const { data } = useSelector((state) => state.roles);

  return (
    <RolesListStyle>
      <Table>
        <TableBody>
          {data?.length === 0 ? (
            <EmptyCategory roles/>
          ) : filteredRoles?.length < 1 ? (
            <div>There Are No Role For Your Search</div>
          ) : (
            <GridStyle>
              {isLoading ? (
                <>
                  {[
                    ...Array(data?.length > 2 ? rowsPerPage : data?.length),
                  ].map((_, index) => (
                    <div key={index} className="skeleton-loader skeleton"></div>
                  ))}
                </>
              ) : (
                case1?.map((row) => (
                    <div key={row?.id}>
                      <img src={row?.photo?.url || user} alt="hairStyleImg" />
                      <div className="thumbnail">
                        <div>
                          <span>{row?.firstName} {row?.lastName}</span>
                          <p>{row?.role}</p>
                          <p>{row?.email}</p>
                        </div>
                        <div className="menu">
                          <NiyoNotificationModal
                            Content={Actions}
                            id={row?.id}
                            url={"role"}
                            handleOpen={handleOpen}
                          >
                            <img src={dots} alt="dot" />
                          </NiyoNotificationModal>
                        </div>
                      </div>
                    </div>
                  ))
              )}
            </GridStyle>
          )}
        </TableBody>
      </Table>
      {data?.length !== 0 && (
        <div className="footer">
          {isLoading ? (
            <div className="pagination-skeleton skeleton"></div>
          ) : (
            <TablePagination
              rowsPerPageOptions={[2, 4, 6]}
              component="div"
              count={latestData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      )}
    </RolesListStyle>
  );
};

export default RolesListGrid;
