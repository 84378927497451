import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "./styles/theme";
import { GlobalStyle } from "./styles/globalStyle";
import AuthLayout from "./layout/AuthLayout";
import DashboardLayout from "./layout/DashboardLayout";
import NotFound from "./pages/notFound";
import HairStylePage from "./pages/hairstyle";
import DashboardPage from "./pages/dashboard";
import CategoryPage from "./pages/categories";
import CalendarPage from "./pages/calendar";
import EmptyCategoryPage from "./pages/categories/emptyCat";
import LoginPage from "./pages/auth/login";
import ForgotPasswordPage from "./pages/auth/forgotPassword";
import AddHairStylePage from "./pages/hairstyle/component/addHairstyle";
import EditHairStylePage from './pages/hairstyle/component/editHairstyle';
import HairPreferencePage from "./pages/hairstyle/component/addHairPreference";
import EditHairPreferencePage from "./pages/hairstyle/component/editHairPreference";
import RequireAuth from "./utils/RequiredAuth";
import ReferralsPage from "./pages/referrals";
import UserReferralPage from "./pages/referrals/UserReferralPage";
import SpecificUserPage from "./pages/referrals/SpecificUserPage";
import { Toaster } from "react-hot-toast";
import RolesPage from "./pages/roles";
import EditRolePage from "./pages/roles/EditRolePage";
import BookingsPage from "./pages/booking";
import TransactionPage from "./pages/transaction";

const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <AuthLayout>
                <LoginPage />
              </AuthLayout>
            }
          ></Route>
          <Route element={<RequireAuth />}>
          <Route
            exact
            path="/referral/specific"
            element={
              <DashboardLayout>
                <SpecificUserPage />
              </DashboardLayout>
            }
          ></Route>
          <Route
            exact
            path="/roles"
            element={
              <DashboardLayout>
                <RolesPage />
              </DashboardLayout>
            }
          ></Route>
          <Route
            exact
            path="/roles/edit"
            element={
              <DashboardLayout>
                <EditRolePage />
              </DashboardLayout>
            }
          ></Route>
          <Route
            exact
            path="/bookings"
            element={
              <DashboardLayout>
                <BookingsPage />
              </DashboardLayout>
            }
          ></Route>
            <Route
              exact
              path="/dashboard"
              element={
                <DashboardLayout>
                  <DashboardPage />
                </DashboardLayout>
              }
            ></Route>
            <Route
              exact
              path="/hairstyle"
              element={
                <DashboardLayout>
                  <HairStylePage />
                </DashboardLayout>
              }
            ></Route>
            <Route
              exact
              path="/hairstyle/add"
              element={
                <DashboardLayout>
                  <AddHairStylePage />
                </DashboardLayout>
              }
            ></Route>
            <Route
              exact
              path="/hairstyle/edit/:id"
              element={
                <DashboardLayout>
                  <EditHairStylePage />
                </DashboardLayout>
              }
            ></Route>
            <Route
              exact
              path="/hairstyle/preference"
              element={
                <DashboardLayout>
                  <HairPreferencePage />
                </DashboardLayout>
              }
            ></Route>
            <Route
              exact
              path="/hairstyle/preference/:id"
              element={
                <DashboardLayout>
                  <EditHairPreferencePage />
                </DashboardLayout>
              }
            ></Route>
            <Route
              exact
              path="/referral"
              element={
                <DashboardLayout>
                  <ReferralsPage />
                </DashboardLayout>
              }
            ></Route>
            <Route
              exact
              path="/transactions"
              element={
                <DashboardLayout>
                  <TransactionPage />
                </DashboardLayout>
              }
            ></Route>
            <Route
              exact
              path="/referral/user"
              element={
                <DashboardLayout>
                  <UserReferralPage />
                </DashboardLayout>
              }
            ></Route>

          <Route
            exact
            path="/category"
            element={
              <DashboardLayout>
                <CategoryPage />
              </DashboardLayout>
            }
          ></Route>
          <Route
            exact
            path="/calendar"
            element={
              <DashboardLayout>
                <CalendarPage />
              </DashboardLayout>
            }
          ></Route>
          <Route
            exact
            path="/categorys"
            element={
              <DashboardLayout>
                <EmptyCategoryPage />
              </DashboardLayout>
            }
          ></Route>
          <Route
            exact
            path="/auth/forgot-password"
            element={
              <AuthLayout>
                <ForgotPasswordPage />
              </AuthLayout>
            }
          ></Route>
          </Route>
          <Route exact path="*" element={<NotFound />}></Route>
        </Routes>
      </Router>
      <Toaster position="top-right" />
    </ThemeProvider>
  );
};

export default App;
