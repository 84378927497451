import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import peopleIcon from '../../../assets/icon/people.svg'
import { CatTableStyle, CatWrapperStyle } from '../../category/styles'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import searchIcon from '../../../assets/icon/search.svg'
import menuPink from '../../../assets/icon/menuPinkIcon.svg'
import NiyoNotificationModal from '../../../components/modal'
import Actions from '../../../constants/actions'
import { SearchWrapper } from '../../../styles/components'
import { sortBySearch } from '../../../redux/reducers/dashboardReducer'
import { recentBooking, recentBookingStylist } from '../../../api/dashboard'
import { toast } from 'react-hot-toast'

const columns = [
  { id: 'Date', label: 'Date', minWidth: 150 },
  { id: 'Name of Client', label: 'Name of Client', minWidth: 150 },
  { id: 'Email Address', label: 'Email Address', minWidth: 150 },
  { id: 'Phone Number', label: 'Phone Number', minWidth: 150 },
  //   { id: "Preferences", label: "Preferences", minWidth: 150 },
  { id: 'Payment Status', label: 'Payment Status', minWidth: 150 },
  { id: 'Amount Paid', label: 'Amount Paid', minWidth: 150 },
  //   { id: "Actions", label: "Actions", minWidth: 150 },
]

const RecentBooking = () => {
  const { searchedBooking, bookings, stylistBookings, error } = useSelector(
    (state) => state.dashboard,
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [uniqueBookings, setUniqueBookings] = useState(null)
  const [page, setPage] = useState(0)
  const [searchBook, setSearchBook] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(6)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const userRole = localStorage.getItem('userRole')
      ? localStorage.getItem('userRole')
      : null
    if (userRole === 'hairstylist') {
      setUniqueBookings(stylistBookings)
    } else {
      setUniqueBookings(bookings)
    }
  }, [bookings, stylistBookings])

  const filteredBookings = uniqueBookings?.filter((book) => {
    return (
      book?.user?.firstName
        ?.toLowerCase()
        .indexOf(searchedBooking?.toLowerCase()) !== -1
    )
  })

  const latestData =
    filteredBookings &&
    filteredBookings
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) }
      })
      .sort((a, b) => b?.date - a?.date)

  const case1 =
    searchBook === ''
      ? latestData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : latestData

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    function fetchData() {
      dispatch(recentBooking())
      dispatch(recentBookingStylist())
      setIsLoading(false)
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    if (error === 'deleteCategory') {
      dispatch(recentBooking())
      dispatch(recentBookingStylist())
    }
  }, [error, dispatch])

  useEffect(() => {
    if (error === 'null') {
      dispatch(recentBookingStylist())
      dispatch(recentBookingStylist())
      setTimeout(() => {
        toast.success('Successfully Added RecentBooking')
      }, 1000)
    }
  }, [error, dispatch])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleSearchCat = (e) => {
    setSearchBook(e.target.value)
    dispatch(sortBySearch(e.target.value))
  }

  useEffect(() => {
    dispatch(sortBySearch(''))
  }, [dispatch])

  return (
    <CatWrapperStyle>
      <>
        <TableContainer>
          <div className="initialSort">
            <SearchWrapper>
              <div className="search-input">
                <img src={searchIcon} alt="search Icon" />
                <input
                  type="text"
                  value={searchBook}
                  onChange={handleSearchCat}
                  placeholder="Search..."
                />
              </div>
              <div className="tableHeader">
                <div className="pagination">
                  <span>Show</span>
                  <TablePagination
                    rowsPerPageOptions={[2, 4, 6]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage=""
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                  <p>entries</p>
                </div>
                <div className="grid-view active">
                  <img src={menuPink} alt="format List" className="grid-img" />
                </div>
              </div>
            </SearchWrapper>
          </div>
          {uniqueBookings?.length === 0 && (
            <div>
              <img src={peopleIcon} alt="people Icon" />
              <p>You have no bookings yet</p>
            </div>
          )}
          <CatTableStyle>
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <>
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          top: 57,
                          minWidth: column.minWidth,
                          width: column.minWidth,
                        }}
                      >
                        {isLoading ? (
                          <div className="table-header-skeleton  skeleton"></div>
                        ) : (
                          column.label
                        )}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredBookings?.length === 0 ? (
                  <div className="empty-search">
                    <p>
                      There are currently no category available for your search
                    </p>
                  </div>
                ) : (
                  case1?.map((row) => {
                    return (
                      <>
                        {isLoading ? (
                          <TableRow hover>
                            <TableCell>
                              <div className="table-header-skeleton  skeleton"></div>
                            </TableCell>
                            <TableCell>
                              <div className="table-header-skeleton  skeleton"></div>
                            </TableCell>
                            <TableCell>
                              <div className="table-header-skeleton  skeleton"></div>
                            </TableCell>
                            <TableCell className="menu-dot">
                              <NiyoNotificationModal
                                Content={Actions}
                                id={row?.id}
                                url={'category'}
                              >
                                <div className="table-header-skeleton  skeleton"></div>
                              </NiyoNotificationModal>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow hover>
                            <TableCell>{row?.createdAt.slice(0, 10)}</TableCell>
                            <TableCell>
                              {row?.user?.firstName} {row?.user?.lastName}
                            </TableCell>
                            <TableCell>{row?.user?.email}</TableCell>
                            <TableCell>
                              {row?.user?.phoneNumber?.number}
                            </TableCell>
                            <TableCell>{row?.status}</TableCell>
                            <TableCell>{row?.totalAmount}</TableCell>
                          </TableRow>
                        )}
                      </>
                    )
                  })
                )}
              </TableBody>
            </Table>
          </CatTableStyle>
        </TableContainer>

        <div className="footer">
          {isLoading ? (
            <div className="pagination-skeleton skeleton"></div>
          ) : (
            <TablePagination
              rowsPerPageOptions={[2, 4, 6]}
              component="div"
              count={latestData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </div>
      </>
    </CatWrapperStyle>
  )
}
export default RecentBooking
