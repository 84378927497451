import React, { useState, useEffect } from 'react'
import { ReferralProgramStyle } from './Style'
import { TableContainer, TablePagination } from '@mui/material'
import { CatWrapperStyle } from '../category/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { CatTableStyle } from '../category/styles/index'
import { userReferred } from '../../api/referall'
import { useDispatch, useSelector } from 'react-redux'
import searchIcon from '../../assets/icon/search.svg'
import menuPink from '../../assets/icon/menuPinkIcon.svg'
import { SearchWrapper } from '../../styles/components'
import { sortBySearch } from '../../redux/reducers/referralReducer'
import EmptyRef from './components/emptyRef'

const columns = [
  { id: 'Date', label: 'Date', minWidth: 100 },
  { id: 'Name of User', label: 'Name of User', minWidth: 150 },
  { id: 'Email Address', label: 'Email Address', minWidth: 150 },
]

const UserReferral = () => {
  const { searchedReferall, usersRefer } = useSelector(
    (state) => state.referral,
  )
  const dispatch = useDispatch()

  const [page, setPage] = useState(0)
  const [searchRef, setSearchRef] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(6)

  useEffect(() => {
    function fetchData() {
      dispatch(userReferred())
    }
    fetchData()
  }, [dispatch])

  const filteredReferred = usersRefer?.filter((ref) => {
    return (
      ref?.user?.firstName
        ?.toLowerCase()
        .indexOf(searchedReferall?.toLowerCase()) !== -1
    )
  })

  const latestData =
    filteredReferred &&
    filteredReferred
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) }
      })
      .sort((a, b) => b?.date - a?.date)

      const case1 =
      searchRef === ''
        ? latestData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : latestData
  
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleSearchRef = (e) => {
    setSearchRef(e.target.value)
    dispatch(sortBySearch(e.target.value))
  }

  if (usersRefer?.length < 1) return <EmptyRef />

  return (
    <ReferralProgramStyle>
      <CatWrapperStyle rounded="8px" referral="32px 48px 47px 36px">
        <TableContainer>
          <div className="initialSort">
            <SearchWrapper>
              <div className="search-input">
                <img src={searchIcon} alt="search Icon" />
                <input
                  type="text"
                  value={searchRef}
                  onChange={handleSearchRef}
                  placeholder="Search..."
                />
              </div>
              <div className="tableHeader">
                <div className="pagination">
                  <span>Show</span>
                  <TablePagination
                    rowsPerPageOptions={[2, 4, 6, 8, 10]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage=""
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />

                  <p>entries</p>
                </div>
                <div className="grid-view active">
                  <img src={menuPink} alt="format List" className="grid-img" />
                </div>
              </div>
            </SearchWrapper>
          </div>
          <CatTableStyle border="transparent">
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        top: 57,
                        minWidth: column.minWidth,
                        width: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredReferred?.length === 0 ? (
                  <div>There are currently no user reffered</div>
                ) : (
                  case1?.map((row) => {
                      return (
                        <TableRow hover>
                          <TableCell>{row?.createdAt.slice(0, 10)}</TableCell>
                          <TableCell>
                            {row?.user?.firstName} {row?.user?.lastName}
                          </TableCell>
                          <TableCell>{row?.user?.email}</TableCell>
                        </TableRow>
                      )
                    })
                )}
              </TableBody>
            </Table>
          </CatTableStyle>
        </TableContainer>

        {/* <CustomModal
          centered
          closable={false}
          open={modalStatus}
          onCancel={handleCancel}
          footer={null}
          width={563}
          sx={{ padding: 0 }}
        >
          <CategoryModal>
            <div>
              <h3>Edit Hair Category</h3>
            </div>
            <div className="category-form">
              <div>
                <label>Hair Category*</label>
                <InputField
                  value={name}
                  onChange={handleCategory}
                  type="text"
                  placeholder="Wigs"
                />
              </div>
              <div>
                <label>Description (optional)</label>
                <TextAreaField
                  name=""
                  value={description}
                  onChange={handleDescription}
                  placeholder="Write a description"
                  rows="2"
                ></TextAreaField>
              </div>
              <div className="btn">
                <Button
                  padding={"8px 40px"}
                  rounded={"5px"}
                  bg="black"
                  onClick={() => handleSubmit()}
                  className={`${
                    name === "" || description === "" ? "disabled" : ""
                  }`}
                >
                  {loading ? (
                    // <img src={Spin} alt="loader" width="30%" height="30%" />
                    <Spin />
                  ) : (
                    "Publish"
                  )}
                </Button>
              </div>
            </div>
            <div className="cancel" onClick={handleCancel}>
              <img src={closeIcon} alt="closeIcon" />
            </div>
          </CategoryModal>
        </CustomModal> */}
      </CatWrapperStyle>
      <div className="footer">
        <TablePagination
          rowsPerPageOptions={[2, 4, 6]}
          component="div"
          count={latestData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </ReferralProgramStyle>
  )
}

export default UserReferral
