import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'


export const bookings = createAsyncThunk(
  'bookings/fetchBookings',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/booking`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)

export const users = createAsyncThunk(
  'bookings/fetchUsers',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/admin/hairstylists`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const createCategory = createAsyncThunk(
  'category/createcategory',
  async ( { name, description } , { rejectWithValue }) => {
    try {
      const { data:{ data } } = await client.post(
          `${BASE_URL}/api/v1/hairstyles/hair/categories`,
          { name, description }
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const getBookingTransaction = createAsyncThunk(
  'booking/getBookingTransaction',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/booking/${id}/transaction`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const getBooking = createAsyncThunk(
  'booking/getBooking',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/booking/${id}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)

export const getBookingStylist = createAsyncThunk(
  'booking/getBookingStylist',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/booking/assigned/hairstylist`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const updateCategory = createAsyncThunk(
  'category/updateCategory',
  async ( { id, name, description } , { rejectWithValue }) => {
    try {
        const { data:{ data } } = await client.put(
          `${BASE_URL}/api/v1/hairstyles/hair/categories/${id}`,
          { name, description }
        )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updatePaymentStatus = createAsyncThunk(
  'category/updateCategory',
  async ( { hairId, bookingId } , { rejectWithValue }) => {
    try {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/booking/${hairId}/update-reserve/${bookingId}/status`
        )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteCategory = createAsyncThunk(
  'category/deleteCategory',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.delete(
        `${BASE_URL}/api/v1/hairstyles/hair/categories/${id}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const assignBooking = createAsyncThunk(
  'booking/assignBooking',
  async ( { id, userId } , { rejectWithValue }) => {
    try {
        const { data:{ data } } = await client.put(
          `${BASE_URL}/api/v1/booking/${id}/assign/${userId}`
        )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
