import React, { useState, useEffect } from "react";
import { LoginStyle } from "./Style";
import { userLogin } from "../../../api/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import {
  Button,
  Flex,
  FormStyle,
  HeadingStyle,
  InputStyle,
} from "../../../styles/components";
import { toast } from "react-hot-toast";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const { loading, userInfo, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      if (userInfo) {
        navigate("/dashboard");
      }
    }, 2000);
  }, [navigate, userInfo]);

  useEffect(() => {
    if (error !== null && error !== "null") {
      toast.error(error);
    }
  }, [error]);

  const handleEmailInput = (e) => setEmail(e.target.value);

  const handlePwdInput = (e) => setPassword(e.target.value);

  useEffect(() => {
    if (error === "null") {
      toast.success("Log in Succesful.... Redirecting...");
    }
  }, [error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email && !password) {
      // toast.error("email and password is required");
    }
    return await dispatch(userLogin({ email, password }));
  };

  return (
    <LoginStyle>
      <div>
        <HeadingStyle>
          <h4>Admin Portal</h4>
          <p>Log in as a new admin on Niyo Hair and Beauty</p>
        </HeadingStyle>

        <FormStyle>
          <InputStyle>
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleEmailInput}
              placeholder="enter email address"
              required
            />
          </InputStyle>

          <InputStyle>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={handlePwdInput}
              placeholder="Password"
              required
            />
          
          </InputStyle>

          <Button
            onClick={handleSubmit}
            className={`${loading ? "disabled" : ""}`}
          >
            {loading ? (
              <Spin />
            ) : (
              "Log In"
            )}
          </Button>
        </FormStyle>
      </div>
    </LoginStyle>
  );
};

export default Login;
