import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'


export const calendar = createAsyncThunk(
  'calendar/fetchCalendar',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/calendar-daily-schedule`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)

export const singleCalendar = createAsyncThunk(
  'calendar/fetchSingleCalendar',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/calendar-daily-schedule/${id}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const updateCalendar = createAsyncThunk(
  'calendar/updateCalendar',
  async ( { id, openTime, closeTime, blocked } , { rejectWithValue }) => {
    try {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/calendar-daily-schedule/${id}`,
          { blocked, openTime, closeTime }
        )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteCalendar = createAsyncThunk(
  'calendar/deleteCalendar',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.delete(
        `${BASE_URL}/api/v1/hairstyles/hair/categories/${id}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


